import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Slide, Dialog, DialogTitle, DialogContent, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { modalSearch } from '../../../Redux/Actions/_actionProducts';

/*Components */
import Search from '../../Home/Search';

/*Constants */
import { styles } from '../constants.js';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class ModalSearch extends Component {

    handleClose = () => {
        this.props.modalSearch(false);
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Dialog
                    open={this.props.searchModal}
                    TransitionComponent={Transition}
                    keepMounted
                    maxWidth="md"
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title" className={classes.modalSearch} >
                        <Grid container>
                            <Grid item xs={11} sm={11} md={11}>
                                <Typography className={classes.modalColor}>Búsqueda</Typography>
                            </Grid>
                            <Grid item xs={1} sm={1} md={1}>
                                <HighlightOffIcon className={classes.iconClose} onClick={this.handleClose} />
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container className={classes.containerSearch}>
                            <Search />
                        </Grid>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

const SearchModal = withStyles(styles, { withTheme: true })(ModalSearch)
function mapStateToProps(state) {
    return {
        searchModal: state.products.searchModal
    }
}

export default connect(
    mapStateToProps, {
    modalSearch
})(SearchModal);

