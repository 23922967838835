import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Paper, MenuItem } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { withStyles } from "@material-ui/core";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import silloncito from "../../Home/img/silloncito.png";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';

/*Components */
import Search from "../../Home/Search";
import Product from "../Product";

/*Actions */
import { modalSearch } from "../../../Redux/Actions/_actionProducts";
import { getSearchByCategories } from "../../../Redux/Actions/services/getSearchByCategories";
import { getHomeCategories } from "../../../Redux/Actions/services/getHomeCategories";
import { setLoader } from "../../../Redux/Actions/_actionLoader";
import { menuOptionSelected } from "../../../Redux/Actions/_actionProducts";
import { setSearchCategories } from "../../../Redux/Actions/_actionHome";
import { styles } from "../constants.js";

class SearchProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      producto: [],
      page: 1,
      size: 10,
      valueSelect: "",
    };
    this.pagination = this.pagination.bind(this);
  }

  componentDidMount() {
    window.scroll({
      top: 65,
      left: 0,
      behavior: 'smooth'
    });
  }

  pagination(event, value) {
    this.setState({
      activeStep: value - 1,
      page: value,
    });
  }

  searchCategorie = (IdCategoria) => {
    localStorage.setItem("searchBar", IdCategoria);
    this.props.setSearchCategories();
    this.props.menuOptionSelected(IdCategoria);
    this.props.setLoader(true);
    this.props.getSearchByCategories(IdCategoria);
  };

  onChangeValues = (event) => {
    this.setState({
      valueSelect: event.target.value
    })
  }
  render() {
    const { classes } = this.props;

    return (
      <Grid container className="d-block w-100 fondo">
        <Grid item xs={12}>
          {/* BARRA DE BÚSQUEDA*/}
          <Grid container className={classes.paperSearch}>
            <Grid item xs={1} />
            <Grid item xs={10} className={classes.search}>
              <Search />
            </Grid>
            <Grid item xs={1} />
          </Grid>
          {/* FIN BARRA DE BÚSQUEDA*/}

          {/* SIDEBAR + PRODUCTOS */}
          <Grid container>
            {/* SIDEBAR */}
            <Grid item xs={2}>
              <Paper elevation={2} className={classes.paperMenu}>
                <Grid container>
                  <Grid item xs={12} className={classes.contenedorCategorias}>
                    {this.props.listaCategorias && (
                      <>
                        {this.props.listaCategorias.map((atr) => (
                          <>
                            <MenuItem
                              onClick={() => this.searchCategorie(atr.IdCategoria)}
                              style={{ paddingBottom: atr.children ? 0 : 5 }}
                            >
                              <ListItemText primary={atr.Descripcion} />
                            </MenuItem>
                            {atr.children && atr.children.map((elm) => (
                              <MenuItem className={classes.menu}
                                onClick={() => this.searchCategorie(elm.IdCategoria)}
                              >
                                <ListItemIcon>
                                  <SubdirectoryArrowRightIcon />
                                </ListItemIcon>
                                <ListItemText primary={elm.Descripcion} />
                              </MenuItem>
                            ))}
                          </>
                        ))}
                        <MenuItem onClick={() => this.searchCategorie()}>
                          <ListItemText primary={"Todas"} />
                        </MenuItem>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <img
                      alt="mueble"
                      src={silloncito}
                      width="160"
                      height="240"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            {/* FIN SIDEBAR */}


            {/* PRODUCTOS */}
            <Grid item xs={10}>
              <Grid container style={{ paddingLeft: 20 }}>
                <Grid item xs={12}>
                  {this.props.breadcrumb.length > 0 ?
                    <>
                      {this.props.breadcrumb.map((atr, i) => (
                        <>
                          {this.props.breadcrumb.length - 1 !== i ?
                            <Link color="secondary" variant="caption" onClick={() => this.searchCategorie(atr.idCategoria)}>{atr.descripcion}</Link>
                            :
                            <Typography color='primary' variant="caption">{atr.descripcion}</Typography>
                          }
                          {this.props.breadcrumb.length - 1 !== i && <NavigateNextIcon style={{ fontSize: '0.9rem' }} />}
                        </>
                      ))}
                    </>
                    :
                    <Typography color='primary' variant="caption">Todas</Typography>
                  }
                </Grid>
              </Grid>

              <Grid container className={classes.heightProduct}>
                {this.props.productsData.products.length > 0 && (
                  <>
                    {this.props.productsData.products[this.state.activeStep].map((prod) => (
                      <Grid item xs={12} sm={6} md={3} className={classes.imgHeight}>
                        <Product producto={prod} />
                      </Grid>
                    ))}
                  </>
                )}
              </Grid>
              <Grid container justify="center">
                <Pagination
                  count={this.props.productsData.products.length}
                  onChange={this.pagination}
                  shape="rounded"
                  color="primary"
                  page={this.state.page}
                  defaultPage={4}
                  siblingCount={0} //espacios de la barra de paginacion//
                  className={classes.pagination}
                />
              </Grid>
            </Grid>
            {/* FIN PRODUCTOS */}
          </Grid>
          {/* FIN SIDEBAR + PRODUCTOS */}
        </Grid>
      </Grid>
    );
  }
}
const products = withStyles(styles, { withTheme: true })(SearchProducts);
function mapStateToProps(state) {
  return {
    productDetails: state.products.productDetails,
    menuOptionProducts: state.products.menuOptionProducts,
    payload: state.homeProps.payload,
    productsData: state.homeProps.productsData,
    getHomeData: state.homeProps.getHomeData,
    listaCategorias: state.homeProps.listaCategorias,
    breadcrumb: state.breadCrumbsReducer.breadcrumb
  };
}

export default connect(mapStateToProps, {
  modalSearch,
  getSearchByCategories,
  getHomeCategories,
  setLoader,
  menuOptionSelected,
  setSearchCategories,
})(products);
