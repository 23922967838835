import { properties } from "../../Constants";

export const styles = (theme) => ({
    '& .MuiButtonBase-root': {
        padding: '0px !important'
    },
    namesHeader: {
        [theme.breakpoints.up('sm')]: {
            marginLeft: 5,
            textTransform: 'capitalize'
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    title: {
        flexGrow: 1,
    },
    button: {
        float: 'right',
        display: 'inline-flex',
        margin: '6px 6px 0px 0px',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '& i:active': {
            background: properties.darkgray,
            borderRadius: '100%'
        },
        '& i:hover': {
            background: properties.gray,
            borderRadius: '100%'
        }
    },
});
