import { SET_HEADER_PROPS } from '../constants';

export const headerProps = (state = {}, action) => {
  switch (action.type) {
    case SET_HEADER_PROPS:
      return {
        ...state,
        iconsColor: action.iconsColor,
        headerColor: action.headerColor,
      };

    default:
      return state;
  }
};