import axios from 'axios';
import { setLoader } from '../_actionLoader';
import { setAlert } from '../_actionAlerts';
import { getCarrito } from './getCarrito';
import { DencryptData } from '../../../Tools/CryptoLocalStorage';
import { properties, ENCRYP_TYPE, LOGIN_DATA_LS } from '../../../Constants';
import { setLoginRenew } from '../_actionLoginRenew';



export const deleteCarrito = (idArticulo, idCarritoCompra) => {
    return dispatch => {

        dispatch(setLoader(true));
        const loginDataStorage = DencryptData(ENCRYP_TYPE.JSON, LOGIN_DATA_LS);
        const { token } = loginDataStorage

        let _headers = {
            IdUsuario: loginDataStorage.usuario.idUsuario,
            Authorization: 'Bearer ' + token,
            IdArticulo: idArticulo,
            IdCarrito: idCarritoCompra,
        }

        axios({
            method: "DELETE",
            url: properties.endpoints.getCarrito,
            headers: _headers
        }).then((response) => {
            const { status, data } = response;
            if (status && status === 200) {
                dispatch(setAlert(true, data.func_msg, properties.severities.success, properties.props.success));
                dispatch(setLoader(false));
                dispatch(getCarrito());
            }

        }).catch((error) => {


            
            var response = { ...error.response };
            dispatch(setLoader(false));
            dispatch(setLoginRenew(response));







            // var response = { ...error.response };
            // dispatch(setLoader(false))
            // dispatch(setAlert(true, response.data.func_msg, properties.severities.warning));
        });
    }
}