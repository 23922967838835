import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, withStyles, Button } from "@material-ui/core";
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';

//Componentes
import { styles } from "./constants.js";

/*Actions */
import { setLoader } from '../../../Redux/Actions/_actionLoader';

/*Services */
import { getQuestionsAndAnswers } from '../../../Redux/Actions/services/getQuestionsAndAnswers';

/*Icons*/
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';


class MeGusta extends Component {
    constructor(props) {
        super(props);
        this.state = {
            animation: true,
            animation1: true,
            activeStep: 0,
            product: [],
            width: '120'
        };
    }

    componentDidMount() {
        this.componentImage();
    }

    componentImage = () => {
        const rows = this.props.meGustaList;
        let arreglo = [...rows];

        var prueba = this.windowsResponsive(arreglo);
        this.setState({
            product: prueba
        })

    }

    windowsResponsive = (data) => {
        var product = [];
        if (window.innerWidth > 959) {
            while (data.length > 0) {
                product.push(data.splice(0, 6))
            }
        } else if (window.innerWidth < 959 && window.innerWidth > 500) {
            while (data.length > 0) {
                product.push(data.splice(0, 2))
            }
        } else if (window.innerWidth <= 500) {
            while (data.length > 0) {
                this.setState({width:'100'})
                product.push(data.splice(0, 2))
            }
        }
        return product;
    }

    handleNext = () => {
        this.setState({
            animation: false,
            anima: true,
            direction: 'left',
        })

        setTimeout(() => {
            this.setState({
                animation: true,
                anima: false,
                activeStep: this.state.activeStep + 1
            })
        }, 300);
    };

    handleBack = () => {
        this.setState({
            animation: false,
            anima: true,
            direction: 'right',
        })


        setTimeout(() => {
            this.setState({
                animation: true,
                anima: false,
                activeStep: this.state.activeStep - 1
            })
        }, 300);
    };

    handleImage = (IdAticulo) => {
        this.props.setLoader(true);
        this.props.getQuestionsAndAnswers(IdAticulo);
    };

    render() {
        const { classes } = this.props;
        const { product } = this.state;

        return (
            <>
                <Paper square elevation={1}>
                    <Grid container
                        alignItems="center"
                        className={classes.contenedor}>
                        <Grid item xs={1}>
                            <Button disabled={this.state.activeStep === 0}>
                                <ArrowBackIcon className={classes.arrowLeft} onClick={this.handleBack.bind(this)} />
                            </Button>
                        </Grid>
                        <Grid item xs={10} className={classes.contenedorImagenes} >
                            <Grow in={this.state.animation}>
                                <Grid container>
                                    {product.length && product[this.state.activeStep].map((row, i) => (
                                        <Grid md={2} key={i}>
                                            <Button onClick={() => { this.handleImage(row.idArticulo) }}>
                                                <img src={row.url} alt="" width={this.state.width} />
                                            </Button>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grow>
                        </Grid>
                        <Grid item xs={1}>
                            <Button className={classes.float} disabled={this.state.activeStep === (product.length - 1)}>
                                <ArrowForwardIcon className={classes.arrowRight} onClick={this.handleNext.bind(this)} />
                            </Button>
                        </Grid>
                    </Grid>
                </Paper >
            </>
        );
    }
}

const meGusta = withStyles(styles, { withTheme: true })(MeGusta);

function mapStateToProps(state) {
    return {
        meGustaList: state.purchase.meGustaList
    };
}

export default connect(mapStateToProps, {
    getQuestionsAndAnswers,
    setLoader
})(meGusta);