import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { openBarraSearch, setData } from "../../../../Redux/Actions/_actionHome";
import { getCategoriasPadreHijo, getProducts } from '../../../../Redux/Actions/services/getSearchByCategories';
import { useStyles } from "./constants";
import { menuOptionSelected } from "../../../../Redux/Actions/_actionProducts";

export default function Categorias() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const getHomeData = useSelector((state) => state.homeProps.getHomeData);

    useEffect(() => {
        dispatch(menuOptionSelected(undefined));
    }, []);

    const handleSearchCategories = (idCategoria) => {
        dispatch(setData([]));
        dispatch(menuOptionSelected(idCategoria));
        dispatch(openBarraSearch(true));
        dispatch(getProducts(idCategoria));
    }

    const seeMore = () => {
        dispatch(menuOptionSelected(undefined)); //Vaciamos la variable donde vamos a guardar las categorías de breadcrumbs
        dispatch(getCategoriasPadreHijo());
    }

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <Typography className={classes.titulo}>Categorías</Typography>
            </Grid>
            {getHomeData.categorias.length > 0 &&
                getHomeData.categorias.slice(0, 6).map(element => (
                    <Grid item xs={4} className={classes.categoriacontenedor}>
                        <Card onClick={() => { handleSearchCategories(element.idCategoria) }}>
                            <CardContent className={classes.cardcontent}>
                                <span className={classes.iconos}>
                                    <i class={`icon-guss_cat_${element.icono}`}></i>
                                </span>
                                <Typography className={classes.nombre}>{element.descripcion} </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))
            }
            <Grid item xs={12}>
                <Typography className={classes.seeMore}
                    onClick={seeMore}>
                    Ver más
                </Typography>
            </Grid>
        </Grid>
    );
}