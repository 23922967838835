import React, { Component } from "react";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Grid, TextField, Typography, withStyles } from "@material-ui/core";
import formulario from "../../../Components/Home/img/formulario.png";

// /ACTIONS/
import { putChangePassword } from "../../../Redux/Actions/services/putChangePassword";
import { setLoader } from "../../../Redux/Actions/_actionLoader";
import { modalChangePassword } from "../../../Redux/Actions/_actionGussta";

//TOOLS//
import { DencryptData } from '../../../Tools/CryptoLocalStorage'
import { validateValues } from '../../../Tools'

// /CONSTANTS/
import { LOGIN_DATA_LS, ENCRYP_TYPE, TOKEN } from '../../../Constants'
import { properties } from "../../../Constants";
import { styles } from "./constants";


const labels = JSON.parse(localStorage.getItem("labels"));

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      bool: false,
      email: "",
      buttonDisabled: true,
      mesageFormat: labels.PASSWORD_MESSAGE,
      currentPassword: '',
      newPassword: '',
    };
  }

  // /FUNCTIONS/

  putChangePass = () => {
    let loginData = DencryptData(ENCRYP_TYPE.JSON, LOGIN_DATA_LS);
    let token = DencryptData(ENCRYP_TYPE.STRING, TOKEN);
    let headers = {
      IdUsuario: loginData.usuario.idUsuario,
      actualPassword: this.state.currentPassword,
      nuevaPassword: this.state.newPassword,
      Authorization: 'Bearer ' + token
    }
    this.props.setLoader(true)
    this.props.putChangePassword(headers)
  }

  onChangeValues = (event) => {
    let bool = validateValues(event.target.value, event.target.id)
    this.setState({ [event.target.name]: event.target.value, [event.target.name + 'Bool']: !bool },()=>{
      if(this.state.currentPassword !== '' && this.state.newPassword !== '' && !this.state.newPasswordBool){
        this.setState({buttonDisabled: false})
      }else{
        this.setState({buttonDisabled: true})
      }
    });

  };

  handleClose = () => {
    this.props.modalChangePassword(false)
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Dialog
          open={true}
          aria-labelledby="responsive-dialog-title"
          maxWidth="xs"
          className={classes.root}
        >
          <div
            style={{
              background: "linear-gradient(to top, #013348 0%, #0585ba 100%)",
              overflow: "hidden",
            }}
          >
            <DialogTitle id="customized-dialog-title">
              <Grid container style={{ color: `${properties.black}` }}>
                <Grid xs={11}>
                  <Typography variant="h5">
                    {" "}
                    {labels.CHANGE_PASSWORD_TITLE}{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Button onClick={this.handleClose}>
                    <i
                      style={{ color: `${properties.black}` }}
                      class="icon-guss_close"
                    />
                  </Button>
                </Grid>
              </Grid>
            </DialogTitle>

            <DialogContent style={{ overflow: "hidden" }}>
              <Grid container>
                <Grid item xs={10}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <TextField
                        label={
                          <span style={{ color: "white" }}>
                            {" "}
                            {labels.CURRENT_PASSWORD}
                          </span>
                        }
                        id='password'
                        type="password"
                        name="currentPassword"
                        value={this.state.currentPassword}
                        onChange={(event) => {
                          this.onChangeValues(event);
                        }}
                        autoFocus
                        className={classes.textColor}
                        inputProps={{
                          maxLength: 25,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        helperText={this.state.newPasswordBool ? this.state.mesageFormat : ""}
                        error={this.state.newPasswordBool}
                        id='password'
                        label={
                          <span style={{ color: "white" }}>
                            {" "}
                            {labels.NEW_PASSWORD}
                          </span>
                        }
                        type="password"
                        name="newPassword"
                        value={this.state.newPassword}
                        onChange={(event) => {
                          this.onChangeValues(event);
                        }}
                        className={classes.textColor}
                        inputProps={{
                          maxLength: 25,
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="medium"
                        className={classes.button}
                        disabled={this.state.buttonDisabled}
                        onClick={() => this.putChangePass()}
                      >
                        {labels.CONTINUE}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <img
                    src={formulario}
                    alt=""
                    width="80"
                    style={{ marginLeft: 10 }}
                  />
                </Grid>

                <Grid item xs={12} className={classes.Logo}>
                  <i
                    class="icon-guss_logo"
                    alt=""
                    style={{
                      color: `${properties.white}`,
                      fontSize: 20,
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
          </div>
        </Dialog>
      </>
    );
  }
}

const changePassword = withStyles(styles, { withTheme: true })(ChangePassword);

function mapStateToProps(state) {
  return {
  };
}

export default connect(mapStateToProps, {
  putChangePassword,
  setLoader,
  modalChangePassword,
})(changePassword);
