import axios from 'axios';
import { properties } from '../../../Constants';
import { setLoader } from '../../Actions/_actionLoader';
import { setLoginRenew } from '../_actionLoginRenew';
import { setAlert} from '../_actionAlerts';

export const postContact = (emailPrincipal, nombreUsuario, comment) => {

    return (dispatch) => {

        axios({
            method: "POST",
            url: properties.endpoints.contact,
            headers: {
                EmailPrincipal: emailPrincipal,
                nombreUsuario: nombreUsuario,
            },
            data: {
                comentario: comment
            }
        }).then((response) => {
            dispatch(setLoader(false))
            dispatch(setAlert(true,  response.data.func_msg, properties.severities.success ))

            // dispatch(searchCategories(true, response.data))
            // dispatch(setHome(false));
            // dispatch(setHeaderProps('#24303e', 'white'));
            // dispatch(openProduct(true));

        }).catch(error => {

 
            var funcMessage = JSON.parse(localStorage.getItem("funcMessage"));

            var response = { ...error.response };
            dispatch(setLoader(false));
            dispatch(setLoginRenew(response));
            dispatch(setAlert(true, funcMessage.MSG_001, properties.severities.warning, properties.props.error));


           

            // var response = { ...error.response };
            // dispatch(setLoader(false))
            // try {
            //     dispatch(setAlert(true, response.data.tecn_msg, properties.severities.warning)); 
            // } catch (error) {
            //     dispatch(setAlert(true, funcMessage.MSG_001, properties.severities.warning));
            // }
           
        })
    }
}