import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import ClearIcon from "@material-ui/icons/Clear";
import AGOTADOS from "./img/agotados.png";
import Alert from "@material-ui/lab/Alert";

/*Actions */
import { openPurchase, solicitud, setCotizar } from "../../../Redux/Actions/_actionShoppingCar";
import { getEstados } from "../../../Redux/Actions/services/getEstados";
import { getEstadosBuyProcess } from "../../../Redux/Actions/services/getEstadosBuyProcess";
import { getShipping } from "../../../Redux/Actions/services/getShipping";
import { getBanks } from "../../../Redux/Actions/services/getBanks";
import { getPayMethod } from "../../../Redux/Actions/services/getPayMethod";
import { getAccounts } from "../../../Redux/Actions/services/getAccounts";
import { getDocumentsId } from "../../../Redux/Actions/services/getDocumentsId";
import { postInventarioSolicitud } from "../../../Redux/Actions/services/postInventarioSolicitud";

import { styles } from "../constants";
import { properties } from "../../../Constants";

class RequestOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  closeModal = () => {
    this.props.solicitud(false);
  };

  purchase = () => {
    this.props.solicitud(false);


    this.select();
    this.props.openPurchase(true, this.props.itemsCompraAgotados, this.props.sumatoriaCompraAgotados);
  };

  select = () => {
    this.props.getEstados();
    this.props.getEstadosBuyProcess();
    this.props.getShipping();
    this.props.getBanks();
    this.props.getPayMethod();
    this.props.getDocumentsId();
    this.props.getAccounts();
  };

  solicitar = () => {
    this.select();
    var listaArticulos = [];
    var artLib = [];
    this.props.itemsCar &&
      this.props.itemsCar.forEach((data) => {
        listaArticulos.push({
          v_IdArticulo: data.idArticulo,
          v_IdColor: data.colorTrama,
          v_Cantidad: data.cantidad,
          v_PrecioUnitario: data.precio || data.precioUnitario
        });
        if (data.reserva === "S") {
          artLib.push({
            IdArticulo: data.idArticulo,
            IdColor: data.colorTrama,
            Cantidad: data.cantidad,
          })
        }
      });
    var solicitud = {
      solicitud: properties.solicitud,
      listArticulos: listaArticulos,
      artLib: artLib
    };
    this.props.setCotizar(true, solicitud);
    this.props.solicitud(false);
  };

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={true}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        PaperProps={{
          style: {
            padding: 0,
          },
        }}
      >
        <DialogTitle id="scroll-dialog-title">
          <Grid container>
            <Grid xs={11} md={11} container justify="center">
              <Typography variant="h5" className={classes.titulo}>Productos agotados</Typography>
            </Grid>
            <Grid xs={1} md={1} container justify="flex-end">
              <Typography onClick={this.closeModal.bind(this)}>
                <strong>
                  <ClearIcon></ClearIcon>
                </strong>
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent className={classes.content}>
          <Grid container>
            <Grid item xs={12}>
              <Alert severity="warning">
                Algunos productos se encuentran agotados, puede SOLICITAR todos
                los productos o COMPRAR los disponibles
              </Alert>
            </Grid>

            <Grid item xs={12} md={8}>
              <List>
                {this.props.itemsCar &&
                  this.props.itemsCar.map((data) => (
                    <>
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          <img style={{ width: 80 }} src={data.url || data.urlArticulo} alt='img' />
                        </ListItemAvatar>
                        <ListItemText
                          style={{ margin: 10 }}
                          primary={data.descripcionCorta}
                          secondary={
                            <>
                              <React.Fragment>
                                {data.reserva === "N" ? (
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    style={{ color: "red" }}
                                  >
                                    AGOTADO
                                  </Typography>
                                ) : (
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    style={{ color: "green" }}
                                  >
                                    DISPONIBLE
                                  </Typography>
                                )}
                              </React.Fragment>
                              <br></br>

                              <React.Fragment>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  color="textPrimary"
                                >
                                  Solicitado:
                                </Typography>
                                {data.cantidad}
                              </React.Fragment>
                            </>
                          }
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </>
                  ))}
              </List>
            </Grid>
            <Grid item xs={12} md={4} className={classes.imagen}>
              <img style={{ width: "100%", height: 400 }} src={AGOTADOS} alt='img' />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={this.solicitar.bind(this)}>
            Solicitar
          </Button>
          <Button
            style={{ borderRadius: 0 }}
            variant="contained"
            color="primary"
            autoFocus
            onClick={this.purchase.bind(this)}
            disabled={this.props.boolPurchase}
          >
            Comprar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const requestOrder = withStyles(styles, { withTheme: true })(RequestOrder);

function mapStateToProps(state) {
  return {
    dataReserva: state.purchase.dataReserva,
    itemsCompraAgotados: state.purchase.itemsCompraAgotados,
    sumatoriaCompraAgotados: state.purchase.sumatoriaCompraAgotados,
    itemsCar: state.purchase.itemsCar,
    documentsIdList: state.purchase.documentsIdList,
    boolPurchase: state.purchase.boolPurchase,
  };
}

export default connect(mapStateToProps, {
  solicitud,
  openPurchase,
  getEstados,
  getEstadosBuyProcess,
  getShipping,
  getBanks,
  getDocumentsId,
  getPayMethod,
  getAccounts,
  postInventarioSolicitud,
  setCotizar,
})(requestOrder);
