import React, { Component } from "react";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Grid, TextField, Typography, withStyles } from "@material-ui/core";
import formulario from "../../../Home/img/formulario.png";
import ReCAPTCHA from "react-google-recaptcha";

// /ACTIONS/
import { postRecovery } from "../../../../Redux/Actions/services/postRecovery";
import { setUnlocking } from "../../../../Redux/Actions/_actionLogin";
import { postUnlocking } from "../../../../Redux/Actions/services/postUnlocking";

//TOOLS//
import { EncryptData } from "../../../../Tools/CryptoLocalStorage";

// /CONSTANTS/
import { properties } from "../../../../Constants";
import { styles } from "./constants";
import { RECOVERY_DATA, ENCRYP_TYPE } from "../../../../Constants";
const labels = JSON.parse(localStorage.getItem("labels"));

class unlocking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      bool: false,
      email: "",
      buttonDisabled: true,
      formatEmail: labels.EMAIL_FORMAT,
      captcha: undefined
    }
    this.captcha = React.createRef();
  }

  // /FUNCTIONS/

  /**
   * Genera cadenas/caracteres aleatorios.
   * @param {*} event
   */

  generateRandomString = (length) => {
    var result = "";

    result = Math.random().toString(36).substr(2, 10);

    EncryptData(result, ENCRYP_TYPE.STRING, RECOVERY_DATA);

    //  localStorage.setItem("codeConfirmation", result);
  };

  onChangeValues = (event) => {
    var name = event.target.name;
    var value = event.target.value;

    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validations();
      }
    );
  };

  handleClose = () => {
    this.props.setUnlocking(false);
  };

  handleServicesPost = () => {
    /* Validación Captcha */
    if (!this.captcha.current.getValue()) {
      this.setState({ captcha: false })
      return;
    }

    this.generateRandomString();
    this.props.postUnlocking(this.state.email);
    localStorage.setItem("emailUser", this.state.email);

  };

  validations = () => {
    var pattern = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,9}$/i;

    if (pattern.test(this.state.email)) {
      this.setState({
        bool: false,
        buttonDisabled: false,
      });
    } else {
      this.setState({
        bool: true,
        buttonDisabled: true,
      });
    }
  };

  onChange = () => {
    if (this.captcha.current.getValue()) {
      this.setState({ captcha: true })
    }
  }


  render() {
    const { classes } = this.props;
    return (
      <>
        <Dialog
          open={this.state.open}
          aria-labelledby="responsive-dialog-title"
          maxWidth="xs"
          className={classes.root}
        >
          <div
            style={{
              background: "linear-gradient(to top, #013348 0%, #0585ba 100%)",
              overflow: "hidden",
            }}
          >
            <DialogTitle id="customized-dialog-title">
              <Grid container style={{ color: `${properties.black}` }}>
                <Grid xs={11}>
                  <Typography variant="h5">
                    {" "}
                    {labels.RECOVERY_TITLE}{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Button onClick={this.handleClose}>
                    <i
                      style={{ color: `${properties.black}` }}
                      class="icon-guss_close"
                    />
                  </Button>
                </Grid>
              </Grid>
            </DialogTitle>

            <DialogContent style={{ overflow: "hidden" }}>
              <Grid container>
                <Grid item xs={10}>
                  <Grid container>
                    <Grid item xs={12}>
                      <TextField
                        error={this.state.bool}
                        helperText={
                          this.state.bool ? this.state.formatEmail : ""
                        }
                        label={
                          <span style={{ color: "white" }}>
                            {" "}
                            {labels.USER_LB}
                          </span>
                        }
                        type="email"
                        name="email"
                        value={this.state.email}
                        onChange={(event) => {
                          this.onChangeValues(event);
                        }}
                        autoFocus
                        className={classes.textColor}
                        inputProps={{
                          maxLength: 40,
                        }}
                      />
                      <ReCAPTCHA
                        ref={this.captcha}
                        sitekey={properties.RECAPTCHA_SITE_KEY}
                        onChange={this.onChange}
                      />
                      {this.state.captcha === false &&
                        <Typography variant="subtitle2" className={classes.captcha}>{"Debe validar que no es un robot"}</Typography>
                      }
                    </Grid>


                    <Grid item xs={12}>
                      <br />
                      <Typography
                        style={{ textAlign: "justify", color: "white" }}
                      >
                        Enviaremos un e-mail con la información necesaria para
                        recuperación de contraseña.
                      </Typography>
                      <br />
                    </Grid>

                    <Grid item xs={12} style={{textAlign: 'center'}}>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="medium"
                        className={classes.button}
                        disabled={this.state.buttonDisabled}
                        onClick={this.handleServicesPost}
                      >
                        {labels.CONTINUE}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <img
                    src={formulario}
                    alt=""
                    width="80"
                    style={{ marginLeft: 10 }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} className={classes.Logo}>
                  <i
                    class="icon-guss_logo"
                    alt=""
                    style={{
                      color: `${properties.white}`,
                      fontSize: 20,
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
          </div>
        </Dialog>
      </>
    );
  }
}

const Unlocking = withStyles(styles, { withTheme: true })(unlocking);

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {
  postRecovery,
  setUnlocking,
  postUnlocking,
})(Unlocking);
