import axios from 'axios';
import { setLoader } from '../_actionLoader';
import { setCityListBuyProcess } from '../_actionShoppingCar';
import { DencryptData } from '../../../Tools/CryptoLocalStorage';
import { properties, ENCRYP_TYPE, LOGIN_DATA_LS } from '../../../Constants';
import { setLoginRenew } from '../_actionLoginRenew';
export const getCityBuyProcess = (idState) => {
    return dispatch => {

        dispatch(setLoader(true));
        const loginDataStorage = DencryptData(ENCRYP_TYPE.JSON, LOGIN_DATA_LS);
        const { token } = loginDataStorage

        let _headers = {
            idPais: properties.idPais,
            idEstado: idState,
            Authorization: 'Bearer ' + token
        }
        axios({
            method: "GET",
            url: properties.endpoints.getCity,
            headers: _headers

        }).then((response) => {
            const { status, data } = response;
            if (status && status === 200) {
                dispatch(setLoader(false));
                dispatch(setCityListBuyProcess(data.Ciudades));
            }

        }).catch((error) => {

            var response = { ...error.response };
            dispatch(setLoader(false));
            dispatch(setLoginRenew(response));
        });
    }
}