import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardActionArea, Divider, CardMedia, Typography, CardActions, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

/*Components */
import { toMoneyFormat } from '../../../Tools';

/*Actions */
import { getQuestionsAndAnswers } from '../../../Redux/Actions/services/getQuestionsAndAnswers';
import { setLoader } from '../../../Redux/Actions/_actionLoader'

/*Constants */
import { properties } from '../../../Constants';
import { styles } from '../constants.js';

import '../index.css'

class Product extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden: true
        }
        this.detailProduct = this.detailProduct.bind(this);
    }


    handleImageLoaded() {
        this.setState({ hidden: false });
    }

    handleImageErrored() {
        this.setState({ hidden: false });
    }

    detailProduct = (product) => {
        this.props.setLoader(true);
        this.props.getQuestionsAndAnswers(product);
    }

    render() {
        const { producto, classes, } = this.props;
        var color = '';
        var top = 0;
        var rayado = '';
        var colorConversion = properties.black;
        if (producto.tipoPromo !== null) {
            color = properties.red;
            top = 0;
            rayado = 'line-through'
        } else {
            color = properties.secondary
            top = 0;
            rayado = 'none'
        }

        var calculoConversion = this.props.moneda !== null ? (producto.valorPromo !== null ? parseFloat(producto.valorPromo) * this.props.moneda.tasa : parseFloat(producto.precio) * this.props.moneda.tasa) : parseFloat(producto.precio);
        var conversion = toMoneyFormat(calculoConversion.toFixed(2));
        return (
            <Grid container xs={12} md={12}>
                <Card className={classes.card} style={this.props.moneda ? {height: '370px'} : {height: '350px'}}>
                    <CardActionArea className={classes.CardActionArea}
                        onClick={this.detailProduct.bind(this, producto.idArticulo)}>
                        <CardMedia style={{ height: 10, }} />
                        {this.state.hidden && <Skeleton variant="rect" width={270} height={220} style={{ marginTop: -10 }} />}
                        <img id={"img"} alt="img" src={producto.url} width="250" className={classes.imgCard}
                            onLoad={this.handleImageLoaded.bind(this)}
                            onError={this.handleImageErrored.bind(this)}
                            style={{ visibility: this.state.hidden ? "hidden" : null }}
                        />

                    </CardActionArea>
                    <Divider />
                    <CardActions className={classes.colorCard} style={this.props.moneda ? {height: '150px'} : {height: '130px'}}>
                        <Grid container>
                            <Grid xs={12} md={12} sm={12} className={classes.contenedorTitulo}>
                                {this.state.hidden ?
                                    <Skeleton variant="text" />
                                    :
                                    <Typography color='primary' className={classes.title} >{producto.descripcionCorta}</Typography>
                                }
                            </Grid>

                            <Grid xs={12} md={12} sm={12} className={classes.contenedorPrecio}>
                                <Grid xs={12} md={6} sm={6} />
                                <Grid xs={12} md={6} sm={6}>
                                    {this.state.hidden ?
                                        <Skeleton variant="text" />
                                        :
                                        <>
                                            <div>
                                                {producto.valorPromo !== null ?
                                                    <>
                                                        <Typography variant="h7" style={{ marginTop: top, float: 'right', fontWeight: 'bold', color: color }} >$ {toMoneyFormat(producto.valorPromo.toFixed(2))}</Typography><br />
                                                        <Typography color='secondary' style={{ fontSize: 12, float: 'right', textDecoration: rayado }} >$ {toMoneyFormat(producto.precio.toFixed(2))}</Typography>
                                                    </>
                                                    :
                                                    <Typography variant="h7" style={{ marginTop: top, float: 'right', fontWeight: 'bold', color: color }} >$ {toMoneyFormat(producto.precio.toFixed(2))}</Typography>
                                                }
                                                {this.props.moneda &&
                                                    <>
                                                        <br />
                                                        <Typography variant="h7" style={{ float: 'right', fontWeight: 'bold', color: colorConversion }} >{this.props.moneda.siglas} {conversion}</Typography>
                                                    </>
                                                }
                                            </div>
                                        </>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            </Grid >
        );
    }
}

const productData = withStyles(styles, { withTheme: true })(Product)
function mapStateToProps(state) {
    return {
        moneda: state.homeProps.moneda
    };
}

export default connect(
    mapStateToProps, {
    setLoader,
    getQuestionsAndAnswers
})(productData);