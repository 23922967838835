import { properties } from '../../Constants';
export const styles = (theme) => ({
    googleMap: {
        border: 0,
        width: "100%",
        height: "50vh",
        [theme.breakpoints.down('sm')]: {
            height: "30vh",
        },
    },
    blueBox: {
        backgroundColor: properties.gray,
        marginTop: -120,
        paddingBottom: 50,
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
        },
        '& > div > div > h5': {
            background: "linear-gradient(to left, #013348 0%, #0585ba 100%)",
            height: 80,
            color: "white",
            padding: 20
        },
    },
    formulario: {
        padding: '50px 60px'
    },
    grayBox: {
        backgroundColor: properties.grayLight,
        marginLeft: -50,
        borderRadius: 0,
        [theme.breakpoints.down('sm')]: {
            marginTop: 20,
            marginLeft: 0,
        },
    },
    margenIcons: {
        padding: '40px 30px'
    },
    captcha: {
        color: "#f44336",
        margin: 0,
        fontSize: "0.75rem",
        marginTop: "3px",
        textAlign: "left",
        fontFamily: "Roboto, Helvetica, Arial",
        fontWeight: "400",
        lineHeight: "1.66",
        letterSpacing: "0.03333em"
    }
})

export const stylesFooter = (theme) => ({
    root: {
        margin: '100px 0px'
    },
    alignCenter: {
        textAlign: 'center',
        marginTop: 10
    },
    logo: {
        width: "50%",
        [theme.breakpoints.down('sm')]: {
            width: "20%",
        },
    },
})