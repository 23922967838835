import { properties } from "../../../Constants";

export const styles = (theme) => ({
  contenedor: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(5),
      paddingRight: 0
    },
  },
  tipografia: {
    color: properties.darkGray
  },
  table: {
    overflowY: "auto",
    height: "40vh !important",
    [theme.breakpoints.down('sm')]: {
      height: "71vh !important",
    },
    "&::-webkit-scrollbar": {
      padding: "0.4em",
      margin: "0.4em",
      width: "0.4em",
      background: "#dfdee3",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: properties.primary,
      background: properties.primary,
    },
  },
  tableFoot: {
    height: "10vh !important",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    color: properties.darkGray
  },
  input: {
    width: '65px',
    borderRadius: '0px',
    color: properties.darkGray,
    "& > div > input": {
      textAlign: 'center',
    }
  },
  iconTrash: {
    width: '15px'
  },
  iconArrow: {
    color: properties.primary,
    fontSize: '20px',
    fontWeight: 'lighter',
  },
  button: {
    fontSize: '11px',
    borderRadius: '30px',
    height: '20px',
    boxShadow: 'none',
  },
  link: {
    textAlign: "center",
    fontSize: "small",
    "& > a": {
      textDecoration: "underline"
    }
  }
});
