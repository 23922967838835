import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Button, withStyles, Badge } from '@material-ui/core';
import Grow from '@material-ui/core/Grow';
import Search from '../Search';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import IconButton from '@material-ui/core/IconButton';

//Actions
import { detailProduct } from '../../../Redux/Actions/_actionProducts';
import { openProduct } from '../../../Redux/Actions/_actionProducts';
import { getSearchByCategories } from '../../../Redux/Actions/services/getSearchByCategories';
import { setHome } from '../../../Redux/Actions/_actionHome';
import { setLoader } from '../../../Redux/Actions/_actionLoader';
import { menuOptionSelected } from '../../../Redux/Actions/_actionProducts';
import { getQuestionsAndAnswers } from '../../../Redux/Actions/services/getQuestionsAndAnswers';

//Constantes
import { styles } from "./constants.js";


var list2 = [];

class Categories extends Component {

    constructor(props) {
        super(props);
        this.state = {
            animation: true,
            activeStep: 0,
            gridSizeCategories: 0
        }
    }

    componentDidMount = () => {
        let arreglo = this.props.getHomeData.categorias
        if (this.props.getHomeData.promo !== null && this.props.getHomeData.promo !== undefined) {
            if (this.props.getHomeData.promo.imagen !== undefined || this.props.getHomeData.promo.imagen !== '') {
                arreglo.unshift({ promo: true, urlImagen: this.props.getHomeData.promo.imagen, article: this.props.getHomeData.promo.urlTop })
            }
        }
        this.setState({ categoriesApi: arreglo })
        
        try {
            let arreglo2 = [...arreglo]
            if (window.innerWidth > 959) {
                while (arreglo2.length > 0) {
                    list2.push(arreglo2.splice(0, 3))
                }
                this.setState({ categoriesApi: list2, gridSizeCategories: 4 })
            } else if (window.innerWidth < 959 && window.innerWidth > 500) {
                while (arreglo2.length > 0) {
                    list2.push(arreglo2.splice(0, 2))
                }
                this.setState({ categoriesApi: list2, gridSizeCategories: 6 })
            } else if (window.innerWidth <= 500) {
                while (arreglo2.length > 0) {
                    list2.push(arreglo2.splice(0, 1))
                }
                this.setState({ categoriesApi: list2, gridSizeCategories: 12 })
            }
        } catch (error) {
            console.log(error)
        }
    }

    handleNext = () => {
        this.setState({
            animation: false,
            anima: true,
            direction: 'left',
        })

        setTimeout(() => {
            this.setState({
                animation: true,
                anima: false,
                activeStep: this.state.activeStep + 1,
            })
        }, 300);
    };

    searchCategorie = (value) => {

        this.props.setLoader(true);
        localStorage.setItem('searchBar', value);
        this.props.menuOptionSelected(value);
        this.props.getSearchByCategories(value);
    }

    searchArticleOffert = (value) => {
        this.props.setLoader(true);
        this.props.getQuestionsAndAnswers(value);
    }

    handleBack = () => {
        this.setState({
            animation: false,
            anima: true,
            direction: 'right',
        })

        setTimeout(() => {
            this.setState({
                animation: true,
                anima: false,
                activeStep: this.state.activeStep - 1
            })
        }, 300);
    };

    handleOffert = () => {
        this.props.detailProduct(true)
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid container className={classes.contenedor}>
                <Grid item xs={1} md={1} className={classes.arrowBackIcon}>
                    <IconButton aria-label="Anterior" disabled={this.state.activeStep === 0} onClick={this.handleBack}>
                        <ArrowBackIcon />
                    </IconButton>
                </Grid>

                <Grid item xs={10} md={10} className={classes.containerCategories}>
                    <Grid container direction="column">
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={1}>
                                </Grid>
                                <Grid item xs={10} className={classes.Search}>
                                    <Search />
                                </Grid>
                                <Grid item xs={1}>
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grow in={this.state.animation}>
                                <Grid container style={{ paddingLeft: 20, paddingRight: 20 }} spacing={0}>
                                    {list2.length > 0 &&
                                        <>
                                            {list2[this.state.activeStep].map((cat, i) => (
                                                <>
                                                    {cat.promo ?
                                                        <>
                                                            <Grid item xs={this.state.gridSizeCategories} spacing={0} style={{ height: 200 }}>
                                                                <Button onClick={() => this.searchArticleOffert(cat.article)}>
                                                                    <img src={cat.urlImagen} alt='img' className={classes.imageStyle} />
                                                                    <div style={{ marginLeft: -80, marginTop: 220 }}>
                                                                        <Badge color="primary" overlap="circle" badgeContent=" " variant="dot">
                                                                            <i style={{ color: "white", fontSize: 35, zIndex: 10 }} class="icon-guss_bell"></i>
                                                                        </Badge>
                                                                    </div>
                                                                </Button>
                                                            </Grid>
                                                        </>
                                                        :
                                                        <>
                                                            <Grid item xs={this.state.gridSizeCategories} spacing={0} style={{ height: 200 }}>
                                                                <Button onClick={() => this.searchCategorie(cat.idCategoria)}>
                                                                    <img src={cat.urlImagen} alt='img' className={classes.imageStyle} />
                                                                </Button>
                                                            </Grid>
                                                        </>
                                                    }
                                                </>
                                            ))}
                                        </>
                                    }

                                </Grid>
                            </Grow>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={1} md={1} className={classes.arrowForwardIcon}>
                    <IconButton aria-label="Siguiente" disabled={this.state.activeStep === (list2.length - 1)} onClick={this.handleNext}>
                        <ArrowForwardIcon />
                    </IconButton>
                </Grid>
            </Grid>
        );
    }
}

const categories = withStyles(styles, { withTheme: true })(Categories);


function mapStateToProps(state) {
    return {
        getHomeData: state.homeProps.getHomeData,
    };
}

export default connect(
    mapStateToProps, {
    detailProduct,
    openProduct,
    getSearchByCategories,
    setHome,
    setLoader,
    menuOptionSelected,
    getQuestionsAndAnswers,
})(categories);