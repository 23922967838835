import { OPEN_PRODUCTS, MODAL_SEARCH, DETAIL_PRODUCT, MENU_OPTION_SELECTED, IMG_DETAIL_PRODUCT, ACTIVE_ARRAY } from '../constants';
import { toMoneyFormat } from '../../Tools';

export const openProduct = (payload) => {
    return {
        type: OPEN_PRODUCTS,
        payload: payload
    }
}

export const modalSearch = (payload) => {
    return {
        type: MODAL_SEARCH,
        payload: payload
    }
}

export const menuOptionSelected = (option) => {
    return {
        type: MENU_OPTION_SELECTED,
        option: option
    }
}

export const detailProduct = (payload) => {

    if (payload.Precio) {
        payload.Precio.forEach(product => {
            product.precio = toMoneyFormat(product.precio.toFixed(2));
            product.precioEspecial = toMoneyFormat(product.precioEspecial.toFixed(2));
        })
    }
    return {
        type: DETAIL_PRODUCT,
        payload: payload
    }
}

export const imagenSelected = (data) => {
    return {
        type: IMG_DETAIL_PRODUCT,
        data: data
    }
}
export const activeStep = (index) => {
    return {
        type: ACTIVE_ARRAY,
        index: index
    }
}