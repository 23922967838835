import { setLoader } from '../../Actions/_actionLoader';
import { catchHomeApi, setHome } from '../_actionHome';
import { moneda } from '../_actionHome';
import { properties } from '../../../Constants';

export const getHomeCategories = (headers) => {
    return (dispatch) => {
        dispatch(setLoader(true));
        fetch(properties.endpoints.home, {
            method: "GET",
            headers: headers,
        }).then((res) => res.json()
        ).then((data) => {
            dispatch(moneda(data.monedaActiva));
            dispatch(catchHomeApi(data));
            dispatch(setHome(true));
            setTimeout(() => {
                dispatch(setLoader(false));
            }, 700);
        }).catch((error) => {
            // dispatch(setAlertError(true, error));
        });
    }
}