import axios from 'axios';
import { setLoginRenew } from '../_actionLoginRenew';
import { setLoader } from '../_actionLoader';
import { properties } from '../../../Constants';
import {
    setOrderSearch,
    setPersonRequest,
    setRequestAddress,
    setOrdersArticle,
} from '../_actionGussta';
import { modalPedidosDetails } from '../_actionGussta';

export const getOrderById = (headers, idEstatus) => {

    return (dispatch) => {

        dispatch(setLoader(true));

        axios({
            method: "GET",
            url: properties.endpoints.getPedido,
            headers: headers,

        }).then((response) => {

            try {
                dispatch(setLoader(false));
                dispatch(modalPedidosDetails(true, idEstatus));
                dispatch(setOrderSearch(response.data.Pedido.pedido));
                dispatch(setPersonRequest(response.data.Pedido.persona));
                dispatch(setRequestAddress(response.data.Pedido.direccion));
                dispatch(setOrdersArticle(response.data.Pedido.articulo));
                // dispatch(setOrdersPayment(response.data.Pedido.pago));
            } catch (error) {
                console.log(error)
            }

        }).catch((error) => {

            var response = { ...error.response };
            dispatch(setLoader(false));
            dispatch(setLoginRenew(response));

        })

    }
}
