import axios from 'axios';
import { setLoader } from '../_actionLoader';
import { setMunicipalitiesListBuyProcess } from '../_actionShoppingCar';
import { detailProduct } from '../_actionProducts';
import { DencryptData } from '../../../Tools/CryptoLocalStorage';
import { properties, ENCRYP_TYPE, LOGIN_DATA_LS } from '../../../Constants';
import { setLoginRenew } from '../_actionLoginRenew';

export const getMunicipalitiesBuyProcess = (idState, idCity) => {
    return dispatch => {

        dispatch(setLoader(true));
        const loginDataStorage = DencryptData(ENCRYP_TYPE.JSON, LOGIN_DATA_LS);
        const { token } = loginDataStorage

        let _headers = {
            idEstado: idState,
            idCiudad: idCity,
            Authorization: 'Bearer ' + token
        }
        axios({
            method: "GET",
            url: properties.endpoints.getMunicipalities,
            headers: _headers
        }).then((response) => {
            const { status, data } = response;
            if (status && status === 200) {
                dispatch(setLoader(false));
                dispatch(setMunicipalitiesListBuyProcess(data.Municipios));
            }
        }).catch((error) => {
            var response = { ...error.response };
            dispatch(setLoader(false));
            dispatch(detailProduct(false))
            dispatch(setLoginRenew(response));
        });
    }
}