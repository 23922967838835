import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";

// Components
import CarouselData from "./CarouselData";
import Categories from "./Categories";
import Footer from "../Footer";
import Products from "../Products";
import ModalSearch from "../Products/ModalSearch";
import Offert from "../Offert";
import Login from "../Home/Login";
import ProductDetail from "../Products/ProductDetail";
import ContactUs from "../Footer/ContactUs";
import US from "../Footer/US";
import Unlocking from "./Login/Unlocking";
import Recovery from "./Login/Recovery";
import ModalAddCarrito from "../Products/ModalAddCarrito";
import ShoppingCar from "../ShoppingCar";
import RequestOrder from "../ShoppingCar/RequestOrder";
import RenewLogin from "./Login/RenewLogin";
import PDF from "../ShoppingCar/PDF";
import Gussta from "../Gussta";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Carusel from "./Mobile/Carusel";
import Categorias from "./Mobile/Categories";
import LastProducts from "./Mobile/LastProducts";
import SearchMobile from "./Mobile/SearchMobile";
import { properties } from "../../Constants";

// Actions
import { setHeaderProps } from "../../Redux/Actions/_actionHeader";
import { getHomeCategories } from "../../Redux/Actions/services/getHomeCategories";
import { gustaOpen } from "../../Redux/Actions/_actionGussta";
import { setLoader } from "../../Redux/Actions/_actionLoader";
import { getSearchByCategories } from "../../Redux/Actions/services/getSearchByCategories";
import { menuOptionSelected, openProduct } from "../../Redux/Actions/_actionProducts";
import { getQuestionsAndAnswers } from "../../Redux/Actions/services/getQuestionsAndAnswers";
import { setAlert } from "../../Redux/Actions/_actionAlerts";
import { checkDevice } from "../../Redux/Actions/_actionHome";
import { setContactUs } from "../../Redux/Actions/_actionFooter";
import { openUs } from "../../Redux/Actions/_actionFooter";
import { openShoppingCar } from "../../Redux/Actions/_actionShoppingCar";
import SeeCategories from "./Mobile/Categories/SeeCategories";
import ButtonWhatsapp from "./ButtonWhatsapp";

function Transition(props) {
  return <Slide {...props} direction="right" />;
}

export default function Home(opcion) {
  const dispatch = useDispatch();

  // Declaración de estados
  const productOpen = useSelector((state) => state.products.productOpen);
  const productsData = useSelector((state) => state.homeProps.productsData);
  const searchModal = useSelector((state) => state.products.searchModal);
  const productDetails = useSelector((state) => state.products.productDetails);
  const getContactUs = useSelector((state) => state.footerProps.getContactUs);
  const getHome = useSelector((state) => state.homeProps.getHome);
  const loader = useSelector((state) => state.loaderReducer.loader);
  const alert = useSelector((state) => state.alertReducer.alert);
  const usPayload = useSelector((state) => state.footerProps.usPayload);
  const keyRecovery = useSelector((state) => state.loginReducer.keyRecovery);
  const userUnlocking = useSelector((state) => state.loginReducer.userUnlocking);
  const openModalAddCar = useSelector((state) => state.purchase.openModalAddCar);
  const openShopCar = useSelector((state) => state.purchase.openShoppingCar);
  const openSolicitud = useSelector((state) => state.purchase.openSolicitud);
  const modalLoginRenew = useSelector((state) => state.loginRenewReducer.modalLoginRenew);
  const openPdf = useSelector((state) => state.purchase.openPdf);
  const openGusta = useSelector((state) => state.gusstaReducer.openGusta);
  const isMobile = useSelector((state) => state.homeProps.isMobile);
  const openBuscador = useSelector((state) => state.homeProps.openBarra);
  const verMasCategorias = useSelector((state) => state.homeProps.seeMore);
  const [setOpen] = useState(false);

  useEffect(() => {
    dispatch(openProduct(false));
    dispatch(gustaOpen(false));
    dispatch(setContactUs(false));
    dispatch(openUs(false));
    dispatch(setHeaderProps("#ddd7d775", "black"));
    dispatch(openShoppingCar(false));
    dispatch(getHomeCategories({ idEmpresa: properties.companyID, idCanal: properties.idCanal }));

    var url = window.location.href;
    var splitUrl = url.split("share/");
  
    if (splitUrl.length === 2) {
      var data = splitUrl[1].split("?");
      var idCategoria = data[0];
      var idArticulo = data[1];

      dispatch(setLoader(true));
      localStorage.setItem("searchBar", idCategoria);
      dispatch(menuOptionSelected(idCategoria));
      dispatch(getSearchByCategories(idCategoria));
      dispatch(getQuestionsAndAnswers(idArticulo));
    }
  }, []);

  const checkForDevice = () => {
    let windowWidth = window.innerWidth;
    if (windowWidth < 780)
      return dispatch(checkDevice(true));
    else
      return dispatch(checkDevice(false));
  };

  useEffect(() => {
    const handlePageResized = () => {
      checkForDevice();
    };

    window.addEventListener('resize', handlePageResized);
    window.addEventListener('orientationchange', handlePageResized);
    window.addEventListener('load', handlePageResized);
    window.addEventListener('reload', handlePageResized);

    return () => {
      window.removeEventListener('resize', handlePageResized);
      window.removeEventListener('orientationchange', handlePageResized);
      window.removeEventListener('load', handlePageResized);
      window.removeEventListener('reload', handlePageResized);
    };
  }, []);

  const handleClose = (event) => {
    dispatch(setAlert(false));
    setOpen(false);
  };

  return (
    <>
      {isMobile ?
        getHome &&
        <>
          <Carusel />
          <Categorias />
          <LastProducts />
        </>
        :
        getHome &&
        <>
          <CarouselData />
          <Categories />
          <Offert />
        </>
      }
      {productOpen && productsData && <Products />}
      {getContactUs && <ContactUs />}
      {usPayload && <US />}
      {searchModal && <ModalSearch />}
      {productDetails && <ProductDetail />}
      {openModalAddCar && <ModalAddCarrito />}
      {userUnlocking && <Unlocking />}
      {keyRecovery && <Recovery />}
      {openShopCar && <ShoppingCar />}
      {!openShopCar && <ButtonWhatsapp />}
      {openSolicitud && <RequestOrder />}
      {modalLoginRenew && <RenewLogin />}
      {openPdf && <PDF />}
      {openGusta && <Gussta />}
      {openBuscador && <SearchMobile />}
      {verMasCategorias && <SeeCategories />}
      <Login />
      {getHome || productOpen ? <Footer /> : (getContactUs || usPayload || openGusta) ? <Footer /> : <></>}
      {loader && (
        <Backdrop open={loader} style={{ zIndex: 9999999, color: properties.primary }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {alert && alert.payload && (
        <Dialog
          open={alert.payload}
          TransitionComponent={Transition}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">

          <DialogTitle id="alert-dialog-slide-title">
            {alert.props}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {alert.data}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose} color="primary">Aceptar</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}