import { makeStyles } from "@material-ui/core";
import { properties } from "../../../../../Constants";

export const useStyles = makeStyles((theme) => ({
    searchField: {
        width: '100%',
        '& .MuiFormControl-root ':{
            width: '100%', 
            background: properties.white,
            '& > div > input':{
                paddingLeft: theme.spacing(1)
            }
        }
    }
}));