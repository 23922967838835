import axios from 'axios';
import { setLoader } from '../_actionLoader';
import { openShoppingCar } from '../_actionShoppingCar';
import { setCarrito } from '../_actionShoppingCar';
import { setMeGusta } from '../_actionShoppingCar';
import { setGuardados } from '../_actionShoppingCar';
import { sumatoriaTotalCarrito } from '../_actionShoppingCar';
import { setHome } from '../_actionHome';
import { getPreferencesGussta } from "../services/getPreferencesGussta";
import { openProduct } from '../_actionProducts';
import { DencryptData } from '../../../Tools/CryptoLocalStorage';
import { properties, ENCRYP_TYPE, LOGIN_DATA_LS } from '../../../Constants';
import { setLoginRenew } from '../_actionLoginRenew';

export const getCarrito = (type) => {
    return dispatch => {

        dispatch(setLoader(true));
        const loginDataStorage = DencryptData(ENCRYP_TYPE.JSON, LOGIN_DATA_LS);
        const { token } = loginDataStorage

        let _headers = {
            IdUsuario: loginDataStorage.usuario.idUsuario,
            Authorization: 'Bearer ' + token
        }
        axios({
            method: "GET",
            url: properties.endpoints.getCarrito,
            headers: _headers,
        }).then((response) => {
            const { status, data } = response;
            if (status && status === 200) {
                if (!type) {
                    dispatch(openShoppingCar(true));
                    dispatch(setMeGusta(data.articulosMeGusta));
                    dispatch(setGuardados(data.articulosGuardados));
                    dispatch(sumatoriaTotalCarrito(data.articulosCarrito));
                    dispatch(setHome(false));
                    dispatch(openProduct(false));
                }
                dispatch(setCarrito(data.articulosCarrito));
                dispatch(getPreferencesGussta());
            }
        }).catch((error) => {
            var response = { ...error.response };
            dispatch(setLoader(false));
            dispatch(setLoginRenew(response));
        });
    }
}