import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid, TextField, MenuItem, } from '@material-ui/core';

/*Constants */
import { styles } from "../constants.js";
import factura from '../img/order.png'
import { properties } from '../../../../Constants';
import { validateValues } from '../../../../Tools/index';

/*Actions */
import { stepPurchase } from '../../../../Redux/Actions/_actionShoppingCar';
import { getCityBuyProcess } from '../../../../Redux/Actions/services/getCityBuyProcess';
import { getMunicipalitiesBuyProcess } from '../../../../Redux/Actions/services/getMunicipalitiesBuyProcess';
import { getParishesBuyProcess } from '../../../../Redux/Actions/services/getParishesBuyProcess';

/*Components */
import Buttons from '../Buttons';

const labels = JSON.parse(localStorage.getItem("labels"));

const constTextField = [
    "envio",
    "state",
    "city2",
    "municipio2",
    "parroquia2",
    "empresa",
    "Address",
]

const boolTextField = [
    "Boolenvio",
    "BoolState",
    "Boolcity",
    "Boolmunicipio",
    "BoolParroquia",
    "BoolEmpresa",
    "BoolAddress",
];

export class Order extends Component {
    constructor(props) {
        super(props);
        this.state = {
            envio: 1,
            city2: '',
            state: '',
            municipio2: '',
            parroquia2: '',
            empresa: '',
            Address: '',
            Boolenvio: false,
            BoolState: false,
            Boolcity: false,
            Boolmunicipio: false,
            BoolParroquia: false,
            BoolEmpresa: false,
            BoolAddress: false,
            shippingBool: false,
            required: labels.REQUIRED,
        }

    }

    componentDidMount = () => {
        if (JSON.parse(localStorage.getItem('Order')) !== null) {
            let Order = JSON.parse(localStorage.getItem('Order'))
            constTextField.forEach((element, i) => {

                this.setState({ [constTextField[i]]: Order[element] })

                if (constTextField[i] === 'envio') {
                    if (Order[element] === 1) this.setState({ shippingBool: false });
                    if (Order[element] === 2) {
                        this.setState({ shippingBool: true });
                        for (var j = 1; j < constTextField.length; j++) {
                            this.setState({ [constTextField[j]]: '', [boolTextField[j]]: false })
                        }
                    }
                }
            });
        }
    }

    componentWillUnmount = () => {
        let Order = {}

        constTextField.forEach((element, i) => {
            Order[constTextField[i]] = this.state[constTextField[i]]
        });
        localStorage.setItem('Order', JSON.stringify(Order))
    }


    handleNext = () => {
        /**Bucle para pintar alerta en inputs vacío**/
        for (var i = 0; i < constTextField.length; i++) {
            var textField = constTextField[i]
            this.validationForm(this.state[textField], "", boolTextField[i], true);
        }

        /**Bucle para validar cuántos inputs están llenos**/
        var cont = 0;
        for (var u = 0; u < constTextField.length; u++) {
            var textField1 = constTextField[u]
            if (this.state[textField1] !== '') {
                var bool = true;
                if (bool === true) {
                    cont = cont + 1;
                }
            }
        }

        if (this.state.shippingBool || cont === constTextField.length) {
            this.props.stepPurchase(2);
        }
    }

    handleBack = () => {
        this.props.stepPurchase(0);
    }

    handleChange = (event) => {
        var value = event.target.value;
        var name = event.target.name;
        let type = event.target.id;

        let bool = validateValues(value, type)
        if (bool) {
            this.setState({ [name]: value, ['Bool' + name]: !bool });
        }
    };

    handleChangeCombos = (event) => {
        var value = event.target.value;
        var name = event.target.name;
        this.setState({ [name]: value });

        for (var i = 0; i < constTextField.length; i++) {
            this.validationForm(name, constTextField[i], boolTextField[i], false);
        }

        if (name === 'envio') {
            if (value === 1) this.setState({ shippingBool: false })
            if (value === 2) {
                this.setState({ shippingBool: true })
                for (var j = 1; j < constTextField.length; j++) {
                    this.setState({ [constTextField[j]]: '', [boolTextField[j]]: false })
                }
            }
        }

        if (name === 'state') {
            this.props.getCityBuyProcess(value)
        }

        if (name === 'city2') {
            this.props.getMunicipalitiesBuyProcess(this.state.state, value)
        }

        if (name === 'municipio2') {
            this.props.getParishesBuyProcess(this.state.state, this.state.city2, value)
        }
    };

    /**-Validación de inputs esta función se llama al momento de darle click a "continuar" y haya algún campo vacío (se pinta error)
    * -En caso de que sea tipeado algo, se borrará el error pintado**/
    validationForm = (state, value, boolean, valueState,) => {
        if (state === value) {
            this.setState({
                [boolean]: valueState,
            });
        }
    }

    render() {
        const {
            envio,
            city2,
            state,
            municipio2,
            parroquia2,
            empresa,
            Address,
            required,
            Boolenvio,
            BoolState,
            Boolcity,
            Boolmunicipio,
            BoolParroquia,
            BoolEmpresa,
            BoolAddress } = this.state;
        const { classes } = this.props;
        return (
            <Grid container  >
                <Grid container xs={12} sm={12} md={12} style={{
                    minHeight: 490,
                }}>
                    <Grid xs={12} sm={1} md={1} />
                    <Grid xs={12} sm={5} md={5} container spacing={3}  >
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField
                                select
                                fullWidth
                                error={Boolenvio}
                                helperText={
                                    Boolenvio
                                        ? required
                                        : ""
                                }
                                style={{ marginTop: "2vh" }}
                                label={labels.PURCHASE}
                                value={envio}
                                name='envio'
                                onChange={this.handleChangeCombos}
                            >
                                {properties.shippingOption.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid xs={12} sm={6} md={6} />

                        <Grid item xs={12} md={6} sm={6} >
                            <TextField
                                select
                                fullWidth
                                error={BoolState}
                                helperText={
                                    BoolState
                                        ? required
                                        : ""
                                }
                                label={labels.STATE}
                                value={state}
                                name='state'
                                disabled={this.state.shippingBool}
                                onChange={this.handleChangeCombos}
                            >
                                {this.props.statesListBuyProcess && this.props.statesListBuyProcess.map((option) => (
                                    <MenuItem key={option.idEstado} value={option.idEstado}>
                                        {option.estado1}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} >
                            <TextField
                                select
                                fullWidth
                                error={Boolcity}
                                helperText={
                                    Boolcity
                                        ? required
                                        : ""
                                }
                                label={labels.CITY}
                                value={city2}
                                name='city2'
                                disabled={this.state.shippingBool}
                                onChange={this.handleChangeCombos}
                            >
                                {this.props.cityListBuyProcess && this.props.cityListBuyProcess.map((option) => (
                                    <MenuItem key={option.idCiudad} value={option.idCiudad}>
                                        {option.ciudad1}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} >
                            <TextField
                                select
                                fullWidth
                                error={Boolmunicipio}
                                helperText={
                                    Boolmunicipio
                                        ? required
                                        : ""
                                }
                                label={labels.MUNICIPIO}
                                value={municipio2}
                                name='municipio2'
                                disabled={this.state.shippingBool}
                                onChange={this.handleChangeCombos}
                            >
                                {this.props.municipalitiesListBuyProcess && this.props.municipalitiesListBuyProcess.map((option) => (
                                    <MenuItem key={option.idMunicipio} value={option.idMunicipio}>
                                        {option.municipio}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} >
                            <TextField
                                select
                                fullWidth
                                error={BoolParroquia}
                                helperText={
                                    BoolParroquia
                                        ? required
                                        : ""
                                }
                                label={labels.PARROQUIA}
                                value={parroquia2}
                                name='parroquia2'
                                disabled={this.state.shippingBool}
                                onChange={this.handleChangeCombos}
                            >
                                {this.props.parishesListBuyProcess && this.props.parishesListBuyProcess.map((option) => (
                                    <MenuItem key={option.idParroquia} value={option.idParroquia}>
                                        {option.parroquia}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={12} sm={12} >
                            <TextField
                                select
                                fullWidth
                                error={BoolEmpresa}
                                helperText={
                                    BoolEmpresa
                                        ? required
                                        : ""
                                }
                                label={labels.EMPRESA_ENVIO}
                                name="empresa"
                                value={empresa}
                                disabled={this.state.shippingBool}
                                onChange={this.handleChangeCombos}
                            >
                                {this.props.shippingList && this.props.shippingList.map((option) => (
                                    <MenuItem key={option.idEmpresasEnvio} value={option.idEmpresasEnvio}>
                                        {option.nombre}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>

                    <Grid xs={12} md={1} sm={1} />
                    <Grid xs={12} sm={12} md={5} />
                    <Grid xs={5} sm={5} md={5} style={{
                        position: "absolute",
                        marginLeft: '55%',
                        marginTop: 10,
                    }} className={classes.hiddenMobile}>
                        <img
                            src={factura}
                            alt=""
                            style={{
                                width: "90%",
                                objectFit: "contain",
                            }}
                        />
                    </Grid>

                    <Grid xs={12} md={1} sm={1} />
                    <Grid xs={12} md={8} sm={8}
                        style={{ paddingRight: 15, marginTop: 20 }}>
                        <TextField
                            fullWidth
                            error={BoolAddress}
                            helperText={
                                BoolAddress
                                    ? required
                                    : ""
                            }
                            label={labels.ADDRESS}
                            name="Address"
                            id='alphanumeric'
                            value={Address}
                            disabled={this.state.shippingBool}
                            onChange={this.handleChange}
                            inputProps={{
                                maxLength: 60,
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid xs={12} md={12} sm={12} container className={classes.next}>
                    <Buttons
                        handleBack={this.handleBack}
                        handleNext={this.handleNext}
                        stepPurchase={2}
                        variant="outlined"
                        color="primary"
                    />
                </Grid>
            </Grid >
        )
    }
}

const order = withStyles(styles, { withTheme: true })(Order)

const mapStateToProps = (state) => ({
    statesListBuyProcess: state.purchase.statesListBuyProcess,
    cityListBuyProcess: state.purchase.cityListBuyProcess,
    municipalitiesListBuyProcess: state.purchase.municipalitiesListBuyProcess,
    parishesListBuyProcess: state.purchase.parishesListBuyProcess,
    shippingList: state.purchase.shippingList,
})

const mapDispatchToProps = {
    stepPurchase,
    getCityBuyProcess,
    getMunicipalitiesBuyProcess,
    getParishesBuyProcess,
}

export default connect(mapStateToProps, mapDispatchToProps)(order)
