import axios from 'axios';
import { setLoader } from '../_actionLoader';
import { setAlert } from '../_actionAlerts';
import { openLogin } from '../_actionLogin';
import { properties } from '../../../Constants';
import { loginSuccessful } from '../_actionLogin';
import { EncryptData } from '../../../Tools/CryptoLocalStorage';
import { LOGIN_DATA_LS, ENCRYP_TYPE, TOKEN } from '../../../Constants';
import { getCarrito } from './getCarrito';
var funcMessage = JSON.parse(localStorage.getItem("funcMessage"));


export const postLogin = (headers) => {

    return dispatch => {
        axios({
            method: "POST",
            url: properties.endpoints.login,
            headers: headers,
        }).then((response) => {
            const { status, data } = response;
            if (status && status === 200) {
                dispatch(loginSuccessful(true, data))
                dispatch(setLoader(false))
                dispatch(openLogin(false))
                EncryptData(data, ENCRYP_TYPE.JSON, LOGIN_DATA_LS)
                EncryptData(data.token, ENCRYP_TYPE.STRING, TOKEN)
                localStorage.setItem('loginBool', true)                
                dispatch(getCarrito(true));
            }
        }).catch((error) => {
            var response = { ...error.response };
            dispatch(setLoader(false))
            try {
                dispatch(setAlert(true, response.data.func_msg, properties.severities.warning, properties.props.error));

            } catch (error) {
                dispatch(setAlert(true, funcMessage.MSG_001, properties.severities.warning, properties.props.error));
            }
        });
    }
}