import axios from 'axios';
import { setLoader } from '../_actionLoader';
import { preferencesGussta } from '../_actionGussta';
import { DencryptData } from '../../../Tools/CryptoLocalStorage';
import { properties, ENCRYP_TYPE, LOGIN_DATA_LS } from '../../../Constants';
import { setLoginRenew } from '../_actionLoginRenew';

export const getPreferencesGussta = () => {

    return dispatch => {
        dispatch(setLoader(true));
        const loginDataStorage = DencryptData(ENCRYP_TYPE.JSON, LOGIN_DATA_LS);

        let _headers = {
            Authorization: 'Bearer ' + loginDataStorage.token,
            idUsuario: loginDataStorage.usuario.idUsuario
        }
        axios({
            method: "GET",
            url: properties.endpoints.getPreferencesGussta,
            headers: _headers,
        }).then((response) => {
            dispatch(setLoader(false))
            const { status, data } = response;
            if (status && status === 200) 
                dispatch(preferencesGussta(data.Preferencias));
        }).catch((error) => {
            var response = { ...error.response };
            dispatch(setLoader(false));
            dispatch(setLoginRenew(response));
        });
    }
}