import { SET_CONTACT, OPEN_US} from '../constants';

export const footerProps = (state = {}, action) => {

    switch (action.type) {
        case SET_CONTACT:
            return {
                ...state,
                getContactUs: action.payload,
            };
       
        case OPEN_US:
            return{ ...state,
               usPayload: action.payload
            }

        default:
            return state;
    }

};