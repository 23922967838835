import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Avatar, Typography, Grid } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useStyles } from "./constants";

import BarraBusqueda from "../../../Home/Mobile/SearchMobile/BarraBusqueda";
import { openBarraSearch } from '../../../../Redux/Actions/_actionHome';
import { menuOptionSelected } from "../../../../Redux/Actions/_actionProducts";
import { setLoader } from "../../../../Redux/Actions/_actionLoader";
import { getQuestionsAndAnswers } from "../../../../Redux/Actions/services/getQuestionsAndAnswers";

export default function SearchMobile() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const open = useSelector((state) => state.homeProps.openBarra);
    const loader = useSelector((state) => state.homeProps.load);
    const data = useSelector((state) => state.homeProps.data);

    const handleClose = () => {
        dispatch(menuOptionSelected(undefined));
        dispatch(openBarraSearch(false));
    };

    const handleSearch = (idArticulo) => {
        dispatch(setLoader(true));
        dispatch(getQuestionsAndAnswers(idArticulo));
    }

    return (
        <Dialog
            className={classes.root}
            fullScreen
            open={open}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close">
                        <ArrowBackIcon />
                    </IconButton>
                    {open && <BarraBusqueda />}
                </Toolbar>
            </AppBar>
            {loader ?
                <>
                    <CircularProgress color="secondary" className={classes.loader} />
                </>
                :
                <>
                    {data && data.length > 0 ?
                        <List>
                            {data.map(element => (
                                <>
                                    <ListItem alignItems="flex-start" onClick={() => { handleSearch(element.idArticulo) }}>
                                        <ListItemAvatar style={{ padding: '0px 5px' }}>
                                            <Avatar
                                                style={{ width: '60px', height: '60px' }}
                                                variant="square"
                                                alt="d"
                                                src={element.url} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            style={{ margin: 10 }}
                                        // primary={element.descripcionCorta}
                                        // secondary={
                                        //     <>
                                        //         {element.valorPromo < 1 ?
                                        //             <Typography variant="body1" className={classes.precio}>
                                        //                 {element.precio}$
                                        //             </Typography>
                                        //             :
                                        //             <>
                                        //                 <Typography variant="body2" className={classes.precioOferta}>
                                        //                     {element.precio}$
                                        //                 </Typography>
                                        //                 <Typography variant="body1" className={classes.precio}>
                                        //                     {element.valorPromo}$
                                        //                 </Typography>
                                        //             </>
                                        //         }
                                        //     </>
                                        // }
                                        >
                                            <Grid container>
                                                <Grid item xs={10} style={{paddingRight: 10}}>
                                                    <Typography variant="body1">
                                                        {element.descripcionCorta}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2} className={classes.flotante}>
                                                    {element.valorPromo < 1 ?
                                                        <Typography variant="body1" className={classes.precio}>
                                                            {element.precio}$
                                                        </Typography>
                                                        :
                                                        <>
                                                            <Typography variant="body1" className={classes.precio}>
                                                                {element.valorPromo}$
                                                            </Typography>
                                                            <Typography variant="body2" className={classes.precioOferta}>
                                                                {element.precio}$
                                                            </Typography>
                                                        </>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                    </ListItem>
                                    <Divider />
                                </>
                            ))}
                        </List>
                        :
                        <Typography className={classes.noHayBusqueda} component="span" variant="body2" color="textPrimary">
                            No hay búsquedas relacionadas...
                        </Typography>
                    }
                </>
            }
        </Dialog>
    );
}