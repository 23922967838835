import { combineReducers } from 'redux';
import { RESET_ALL } from '../constants';
import { products } from './_reducerProducts';
import { headerProps } from './_reducerHeader';
import { homeProps } from './_reducerHome';
import { loginReducer } from './_reducerLogin';
import { footerProps } from './_reducerFooter';
import { loaderReducer } from './_reducerLoader';
import { alertReducer } from './_reducerAlert';
import { gusstaReducer } from './_reducerGussta';
import { purchase } from './_reducerShoppingCar';
import { loginRenewReducer } from './_reducerLoginRenew';
import { breadCrumbsReducer } from './_reducerBreadcrumbs';


const appReducer = combineReducers({
    products,
    headerProps,
    homeProps,
    loginReducer,
    footerProps,
    loaderReducer,
    alertReducer,
    gusstaReducer,
    purchase,
    loginRenewReducer,
    breadCrumbsReducer
});

const resetState = combineReducers({
    products,
    headerProps,
    homeProps,
    loginReducer,
    footerProps,
    loaderReducer,
    alertReducer,
    gusstaReducer,
    purchase,
    loginRenewReducer,
    breadCrumbsReducer
});

const rootReducer = (state, action) => {
    if (action.type === RESET_ALL) {
        // state = undefined
        return resetState(state, action)
    } else {
        return appReducer(state, action)
    }
}

export default rootReducer;