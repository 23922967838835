import { BREADCRUMBS} from "../constants";

export const breadCrumbsReducer = (state = {}, action) =>{
    switch(action.type) {
        case BREADCRUMBS:
            return{ ...state,
                breadcrumb: action.data
            };
    default:
        return state;
    }
}