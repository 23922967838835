import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

/*Constants */
import { styles } from "./constants.js";

const labels = JSON.parse(localStorage.getItem("labels"));

export class Buttons extends Component {
    render() {
        const { handleBack, handleNext, step, stepPurchase, classes } = this.props;
        return (
            <Grid md={12} xs={12} sm={12} container style={{ marginTop: 20 }}>
                <Grid md={6} xs={6} sm={6} className={classes.botones}>
                    {step !== 0 &&
                        <Button
                        style={{ borderRadius: 0 }}
                            onClick={handleBack}
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            startIcon={<ChevronLeftIcon />}
                        >
                            {labels.BACK}
                        </Button>
                    }
                </Grid>
                <Grid md={6} xs={6} sm={6} justify='flex-end' container>
                    <Button   style={{ borderRadius: 0 }} variant="contained"  color="primary" className={classes.button} onClick={handleNext}
                        endIcon={<ChevronRightIcon size='small' />}>
                        {step === stepPurchase ? labels.FINISH : labels.NEXT}
                    </Button>
                </Grid>
            </Grid>
        )
    }
}


const button = withStyles(styles, { withTheme: true })(Buttons)

const mapStateToProps = (state) => ({
    step: state.purchase.step

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(button)
