import React, { Component } from 'react';
import { connect } from "react-redux";
import Pdf from "react-to-pdf";
import Button from "@material-ui/core/Button";
import { Dialog, Grid, Typography, withStyles } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ClearIcon from "@material-ui/icons/Clear";
import Box from "@material-ui/core/Box";

/*CONSTANTS*/
import logo from '../../Home/img/logoNemesis.png';
import pie from '../../Home/img/pieQuote.png';

/*ACTIONS*/
import { sumatoriaTotalCarrito } from "../../../Redux/Actions/_actionShoppingCar";
import { setPdf } from '../../../Redux/Actions/_actionShoppingCar';
import { properties } from '../../../Constants';
import { styles } from "./constants";
import { toMoneyFormat } from '../../../Tools/index';

const ref = React.createRef();
var numberBill = Math.floor(Math.random() * 10000) + 100;

class PDF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quoteData: undefined,
      fecha: "",
      date_expired: "",
    };
  }

  componentDidMount = () => {
    let time = properties.TIME_EXPIRED * 86400;
    let date = new Date();
    date.setSeconds(time);
    this.setState({
      quoteData: JSON.parse(localStorage.getItem("quoteData")),
      fecha: localStorage.getItem("Date"),
      date_expired: (date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear())
    })
  }

  handleClose = () => {
    this.props.setPdf(false)
  }

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={true}
        maxWidth={"lg"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        style={{ overflowX: 'hidden' }}
      >
        {this.state.quoteData &&
          <>
            <div ref={ref} className={classes.fondo}>
              <br />
              <Grid container spacing={0}>
                <Grid item xs={12} className={classes.iconClose}>
                  <strong className={classes.closeModal}>
                    <ClearIcon onClick={this.handleClose}  ></ClearIcon>
                  </strong>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={4} md={5}>
                  <img
                    src={logo}
                    alt=''
                    className={classes.logo}
                  />
                </Grid>
                <Grid item xs={6} md={5} style={{ textAlign: 'right' }}>
                  <Typography className={classes.bloque1}><strong>N° de factura: </strong>{numberBill}</Typography>
                  <Typography className={classes.bloque1} ><strong>Fecha: </strong>{this.state.fecha}</Typography>
                  <Typography className={classes.bloque1} ><strong>{this.props.isMobile ? 'Fecha de exp.:' : 'Fecha de expiración'} </strong>{this.state.date_expired}</Typography>
                </Grid>
                <Grid item xs={1} />


                <Grid item xs={12} md={5} />
                <Grid item xs={12} md={7}>
                  <Typography className={classes.bloque2}> Cotización </Typography>
                </Grid>

                <Grid item xs={1} />
                <Grid item xs={5}>
                  <Typography className={classes.bloque3}><strong>{this.state.quoteData.v_Nombre} {this.state.quoteData.v_Apellido}</strong></Typography>
                  <Typography className={classes.bloque3}><strong>{this.state.quoteData.v_RazonSocial} </strong></Typography>
                  <Typography className={classes.bloque3}><strong>{this.state.quoteData.v_EmailPrincipal}</strong></Typography>
                  <Typography className={classes.bloque3}> <strong>{this.state.quoteData.v_IdTipoIdExterno}</strong>-{this.state.quoteData.v_Documento}</Typography>
                  <Typography className={classes.bloque3}><strong>{this.state.quoteData.v_Direccion}</strong></Typography>
                  <Typography className={classes.bloque3}><strong>Zona Postal: </strong>{this.state.quoteData.v_ZonaPostal}</Typography>
                </Grid>
                <Grid item xs={6} />
              </Grid>
              <Grid container spacing={0} >
                <Grid item xs={1} />
                <Grid item xs={10}>
                  {/* TABLA */}
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.bloque4} align="left" ><strong>CANT.</strong></TableCell>
                          <TableCell className={classes.bloque4} align="left" > <strong>DESCRIPCIÓN</strong></TableCell>
                          <TableCell className={classes.bloque4} align="left" ><strong>PRECIO (C/U)</strong></TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody className={classes.table} >
                        {this.props.data &&
                          this.props.data.map((row) => (
                            <TableRow key={row.cantidad}    >
                              <TableCell component="th" scope="row" className={classes.celdas}>{row.cantidad}</TableCell>
                              <TableCell align="left" className={classes.celdas} >{row.descripcionCorta}</TableCell>
                              <TableCell align="left" className={classes.celdas}>{toMoneyFormat((row.precio).toFixed(2))}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={1} />
              </Grid>
              <Grid container style={{ textAlign: 'right', marginTop: 20, marginBottom: 20 }}>
                <Grid item xs={1} />
                <Grid container xs={10}>
                  <Grid item xs={6}>
                    <Typography variant='h5'>Total:</Typography>
                  </Grid>
                  <Grid item xs={6} style={{ paddingRight: 5 }}>
                    <Typography variant="h5">
                      <Box fontWeight={700}>
                        {this.props.sumatoria ? toMoneyFormat((this.props.sumatoria).toFixed(2)) : 0}$
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={1} />
              </Grid>
              <Grid item xs={12} className={classes.cajaPie} >
                <img src={pie}
                  alt=''
                  className={classes.pie} />
                <div className={classes.text}>
                  <p>
                    Nemesis 1117x c.a
                    <br />
                    Rif J- 15454665
                    <br />
                    Edificio Majestic,PB
                    <br />
                    Av. Libertador, Caracas, Dtto Capital
                  </p>
                </div>
              </Grid>
            </div>
            <Grid container style={{ margin: '20px 0' }}>
              <Grid item xs={1} />
              <Grid container xs={10}>
                <Pdf targetRef={ref} filename="ResumenPedido.pdf" >
                  {({ toPdf }) =>
                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      onClick={toPdf}
                      style={{ marginLeft: 'auto' }} >
                      Descargar PDF
                    </Button>
                  }
                </Pdf>
              </Grid>
              <Grid item xs={1} />
            </Grid>
          </>
        }
      </Dialog>
    );
  }
}

const pdf = withStyles(styles, { withTheme: true })(PDF);

function mapStateToProps(state) {
  return {
    sumatoria: state.purchase.totalSumaCarrito,
    data: state.purchase.data,
    isMobile: state.homeProps.isMobile
  };
}
export default connect(mapStateToProps, {
  setPdf,
  sumatoriaTotalCarrito,
})(pdf);







