import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Grid, makeStyles, Typography, Card, CardActionArea, CardContent } from "@material-ui/core";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Lazy } from "swiper";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'swiper/swiper.min.css'

import { properties } from "../../../../Constants";
import { loadingSearchMobile } from "../../../../Redux/Actions/_actionHome";
import { setLoader } from "../../../../Redux/Actions/_actionLoader";
import { detailProduct } from "../../../../Redux/Actions/_actionProducts";
import { getQuestionsAndAnswers } from "../../../../Redux/Actions/services/getQuestionsAndAnswers";

const useStyles = makeStyles((theme) => ({
    numeroImagenes: {
        height: '30px',
        width: '50px',
        background: 'lightgray',
        borderRadius: '60px',
        position: 'absolute',
        zIndex: 2,
        marginTop: '7px',
        marginLeft: '5px'
    },
    loader: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '20px'
    },
    productoContenedor: {
        padding: 5,
        '& .MuiPaper-root': {
            borderRadius: '5px !important',
            position: 'relative',
            height: '100%',
            '& > button': {
                height: '100%'
            }
        },
    },
    titulo: {
        padding: theme.spacing(1),
        color: properties.gray,
        fontSize: '1em',
        fontWeight: 700,
        textTransform: 'capitalize',
        textAlign: 'center'
    },
    imagen: {
        width: '100%',
        padding: theme.spacing(2),
        aspectRatio: '1/1'
    },
    contenedorSwiper: {
        '& > .swiper-wrapper > .swiper-slide': {
            display: 'inline-flex !important', 
            height: 'auto !important'
        }
    }
}));
SwiperCore.use([Navigation, Pagination, Lazy, A11y, Scrollbar]);

export default function ImagenesSliderSwiper(tipo) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [activeIndex, setActiveIndex] = useState(1);
    const imagenes = useSelector((state) => state.products.imagenesProducto);
    const activeStep = useSelector((state) => state.products.activeArray);
    const isLoader = useSelector((state) => state.homeProps.load);
    const producto = useSelector((state) => state.products.productDetails);
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const pagRef = useRef(null);
    const type = tipo.type;

    useEffect(() => {
        setTimeout(() => {
            dispatch(loadingSearchMobile(false));
        }, 500);
    }, [imagenes[activeStep]]);

    const handleSearch = (idArticulo) => {
        dispatch(setLoader(true));
        dispatch(detailProduct(false));
        dispatch(getQuestionsAndAnswers(idArticulo));
    }

    return (
        <>
            {type === 1 ?
                <Swiper
                    slidesPerView={'1.5'}
                    pagination={true}
                    modules={[Pagination]}
                    onInit={(swiper) => {
                        swiper.params.navigation.prevEl = prevRef.current;
                        swiper.params.navigation.nextEl = nextRef.current;
                        swiper.params.pagination.el = pagRef;
                        swiper.navigation.init();
                        swiper.navigation.update();
                    }}
                    className={classes.contenedorSwiper}
                >
                    {producto && producto.Articulos_Similares.map(element => (
                        <SwiperSlide>
                            <Grid className={classes.productoContenedor}>
                                <Card>
                                    <CardActionArea onClick={() => { handleSearch(element.idArticulo) }}>
                                        <LazyLoadImage
                                            className={classes.imagen}
                                            effect="blur"
                                            visibleByDefault={true}
                                            src={element.url}
                                            alt={element.descripcionCorta}
                                        />
                                        <CardContent>
                                            <Typography variant="subtitle" component="h6">
                                                {element.descripcionCorta}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                {element.precio}$
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        </SwiperSlide>
                    ))}
                </Swiper>
                :
                <>
                    <div className={classes.numeroImagenes}>
                        <Typography variant="caption">{activeIndex} | {imagenes[0].length}</Typography>
                    </div>
                    <Swiper
                        spaceBetween={5}
                        slidesPerView={1}
                        onSlideChange={(e) => { setActiveIndex(e.snapIndex + 1) }}
                        onInit={(swiper) => {
                            swiper.params.navigation.prevEl = prevRef.current;
                            swiper.params.navigation.nextEl = nextRef.current;
                            swiper.params.pagination.el = pagRef;
                            swiper.navigation.init();
                            swiper.navigation.update();
                        }}
                    >
                        {imagenes !== undefined || imagenes.length > 0 ?
                            <>
                                {!isLoader ?
                                    <>
                                        {imagenes[activeStep].map((item) => (
                                            <SwiperSlide>
                                                <img
                                                    src={item.imgURL}
                                                    alt={item.tipoImagen}
                                                    style={{ width: "100%" }}
                                                />
                                            </SwiperSlide>
                                        ))}
                                    </>
                                    :
                                    <CircularProgress style={{ marginTop: 140 }} />
                                }
                            </>
                            :
                            <div style={{ aspectRatio: '1/1', paddingTop: 200, color: 'grey' }}>
                                Error al cargar imagenes
                            </div>
                        }
                    </Swiper>
                </>
            }

        </>
    );
}
