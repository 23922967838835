import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { useStyles } from "./constants";
import { setLoader } from "../../../../Redux/Actions/_actionLoader";
import { getQuestionsAndAnswers } from "../../../../Redux/Actions/services/getQuestionsAndAnswers";

export default function LastProducts() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const getHomeData = useSelector((state) => state.homeProps.getHomeData);

    const handleSearch = (idArticulo) => {
        dispatch(setLoader(true));
        dispatch(getQuestionsAndAnswers(idArticulo));
    }

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <Typography className={classes.titulo}>Últimos Productos</Typography>
            </Grid>
            {getHomeData.ultimosProductos.length > 0 &&
                getHomeData.ultimosProductos.map(element => (
                    <Grid xs={6} className={classes.productoContenedor}>
                        <Card>
                            <CardActionArea onClick={() => {handleSearch(element.idArticulo)}}>
                                <LazyLoadImage
                                    className={classes.imagen}
                                    effect="blur"
                                    visibleByDefault={true}
                                    src={element.url}
                                    alt={element.descripcionCorta}
                                />
                                <CardContent>
                                    <Typography variant="caption" style={{ color: '#bdbbbb' }}>
                                        {element.categoria}
                                    </Typography>
                                    <Typography variant="subtitle" component="h6">
                                        {element.descripcionCorta}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        {element.precio}$
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))
            }
        </Grid>
    );
}