import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Slide, Dialog, DialogTitle, DialogContent, Grid, Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import TextField from "@material-ui/core/TextField";

/*Components */
import { DencryptData } from '../../../Tools/CryptoLocalStorage';
import { properties, ENCRYP_TYPE, LOGIN_DATA_LS } from '../../../Constants';
import { toMoneyFormat } from '../../../Tools';

/*Constants */
import { styles } from './constants.js';

/* Actions */
import { openAddCarModal } from '../../../Redux/Actions/_actionShoppingCar';
import { postCarrito } from '../../../Redux/Actions/services/postCarrito';

/*Icons*/
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class ModalAddCarrito extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            idArticulo: '',
            precio: 0,
            oferta: undefined,
            color: [{
                cHexadecimal: "",
                cUrl: "",
                idColor: "",
                selected: false
            }],
            cantidad: 0,
        }
    }

    componentDidMount() {
        this.renderComponent();
    }

    renderComponent = () => {
        const { Descripcion, Precio, Colores, Promo } = this.props.detalles;
        const array = [];
        Colores.forEach((col) => {
            array.push({
                cHexadecimal: col.cHexadecimal,
                cUrl: col.cUrl,
                idColor: col.idColor,
                selected: false
            })
        })
        Descripcion.forEach(des => {
            this.setState({
                name: des.descripcionCorta,
                idArticulo: des.idArticulo
            })
        })

        //Línea que nos permite colocar le primer color como predeterminado
        array[0].selected = true;

        this.setState({
            precio: Precio[0].precio,
            oferta: Promo.length < 1 ? 0 : Promo[0].valorPromo,
            color: array,
        })
    }

    calcularCantidad = (tipo) => {
        let valor = this.state.cantidad;
        let calculo = 0;

        switch (tipo) {
            case 1:
                calculo = parseInt(valor) - 1;

                if (valor <= 1) calculo = 0;
                break;

            default:
                calculo = parseInt(valor) + 1;
                break;
        }
        this.setState({ cantidad: calculo });
    }

    validarCampo = (event) => {
        let valor = event.target.value;
        let total = this.state.cantidad;
        let pattern = /^[0-9\b]+$/;

        if (valor === null || valor === "")
            valor = 0;

        if (valor > 0) {
            valor = valor.replace(/^(0+)/g, '');
        }

        if (pattern.test(valor)) {
            total = valor;
            this.setState({ cantidad: total });
        }
    }

    handleClose = () => {
        this.props.openAddCarModal(false);
    }

    handleAddCarrito = () => {
        const loginDataStorage = DencryptData(ENCRYP_TYPE.JSON, LOGIN_DATA_LS);
        const datosColores = [...this.state.color];
        const carritoIsOpen = this.props.openShoppingCar;
        datosColores.forEach((col) => {
            if (col.selected === true) {
                let body = {
                    "IdArticulo": this.state.idArticulo,
                    "ColorTrama": col.idColor,
                    "IsColor": this.state.color[0].cHexadecimal ? 1 : 0,
                    "IdEmpresa": properties.companyID,
                    "Cantidad": this.state.cantidad,
                    "IdUsuario": loginDataStorage.usuario.idUsuario
                }
                this.props.postCarrito(body, carritoIsOpen);
            }
        })
    }

    handleSelectColor = (i) => {
        const arrayColores = [...this.state.color]

        //Debemos reiniciar todos los colores en caso de que haya uno seleccionado
        arrayColores.forEach((col) => {
            col.selected = false;
        });
        this.setState({
            color: arrayColores,
        })

        //Ahora cambiamos el estado del color seleccionado
        arrayColores[i].selected = true;
        this.setState({
            color: arrayColores,
        })

    }

    render() {

        const { classes } = this.props;
        var calculoConversion = this.props.moneda !== null ? (this.state.oferta !== 0 ? parseFloat(this.state.oferta) * this.props.moneda.tasa : parseFloat(this.state.precio) * this.props.moneda.tasa) : parseFloat(this.state.precio);
        var conversion = toMoneyFormat(calculoConversion.toFixed(2));
        return (
            <Dialog
                open={this.props.openModalAddCar}
                fullWidth="md"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                TransitionComponent={Transition}
            >
                <DialogTitle id="alert-dialog-title">
                    <Grid container >
                        <Grid xs={11} md={11} sm={11} item>
                            <Typography variant='h4' className={classes.title}>{this.state.name}</Typography>
                        </Grid>
                        <Grid xs={1} md={1} sm={1} item>
                            <ClearIcon onClick={this.handleClose} className={classes.closeModal} ></ClearIcon>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container >
                        <Grid xs={12} md={12} sm={12} item className={classes.precio}>
                            {this.state.oferta !== 0 ?
                                <>
                                    <Typography variant="h5">$ {this.state.oferta}</Typography>
                                    {this.props.moneda &&
                                        <Typography variant="h5">Bs. {conversion}</Typography>
                                    }
                                </>
                                :
                                <>
                                    <Typography variant="h5">$ {this.state.precio}</Typography>
                                    {this.props.moneda &&
                                        <Typography variant="h5">Bs. {conversion}</Typography>
                                    }
                                </>
                            }
                        </Grid>
                    </Grid>
                    <Grid container className={classes.seleccionarColor}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Seleccionar Color</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                {this.state.color.map((col, i) => (
                                    col.cHexadecimal ?
                                        <Grid xs={2} sm={1} style={{ margin: '10px' }} onClick={() => { this.handleSelectColor(i) }}>
                                            <div className={col.selected ? classes.squareColorSelected : classes.squareColor} style={{ background: col.cHexadecimal }}></div>
                                        </Grid>
                                        :
                                        <Grid xs={2} sm={1} style={{ margin: '10px' }} onClick={() => { this.handleSelectColor(i) }}>
                                            <div className={col.selected ? classes.squareColorSelected : classes.squareColor} style={{ backgroundImage: 'url(' + col.cUrl + ')' }}></div>
                                        </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.seleccionarCantidad}>
                        <Grid item xs={12} className={classes.border}>
                            <Typography variant="subtitle1">Cantidad</Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.cantidad}>
                            <div id="iconoMas">
                                <KeyboardArrowUpIcon className={classes.iconArrow} onClick={() => this.calcularCantidad(0)} />
                            </div>
                            <TextField
                                className={classes.input}
                                name="cantidad"
                                type="text"
                                variant="outlined"
                                square={true}
                                inputProps={{ maxLength: 4, class: "colorInput" }}
                                size="small"
                                value={this.state.cantidad}
                                onChange={(event) => this.validarCampo(event)}
                            />
                            <div id="iconoMenos">
                                <KeyboardArrowDownIcon className={classes.iconArrow} onClick={() => this.calcularCantidad(1)} />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.seccionButtons}>
                        <Grid item xs={12}>
                            <Button
                                style={{ borderRadius: 0 }}
                                color="primary"
                                className={classes.buttonCancel} onClick={this.handleClose}>
                                <Typography>Cancelar</Typography>
                            </Button>
                            <Button
                                style={{ borderRadius: 0 }}
                                variant="contained"
                                color="primary"
                                disabled={this.state.cantidad === 0} className={classes.buttonAccept} onClick={this.handleAddCarrito}>
                                <Typography>Agregar</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

        );
    }
}

const modalAddCarrito = withStyles(styles, { withTheme: true })(ModalAddCarrito)
function mapStateToProps(state) {
    return {
        openModalAddCar: state.purchase.openModalAddCar,
        detalles: state.purchase.productDetails,
        openShoppingCar: state.purchase.openShoppingCar,
        moneda: state.homeProps.moneda
    }
}

export default connect(mapStateToProps, {
    openAddCarModal,
    postCarrito
})(modalAddCarrito);