import axios from 'axios';
import { setLoader } from '../_actionLoader';
import { properties } from '../../../Constants';
import { setLoginRenew } from '../_actionLoginRenew';
import { pedidoByUser } from '../_actionGussta';

export const getPedidoByUser = (header) => {
    return dispatch => {
        axios({
            method: "GET",
            url: properties.endpoints.pedidoByUser,
            headers: header
        }).then((response) => {
            const { status, data } = response;
            if (status && status === 200) {
                dispatch(setLoader(false));
                dispatch(pedidoByUser( true, data.Pedido));
            }
        }).catch((error) => {
            var response = { ...error.response };
            dispatch(setLoader(false));
            dispatch(setLoginRenew(response));
        });
    }
}