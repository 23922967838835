import { OPEN_LOGIN, BUTTOM_FORM, 
    LOGIN_SUCCESSFUL, 
    KEY_RECOVERY,
    UNLOCKING
} from "../constants";


export const loginReducer = (state = {}, action) => {
    switch (action.type) {

        case OPEN_LOGIN:
            return {
                ...state,
                loginPayload: action.payload,
            };

        case BUTTOM_FORM:
            return {
                ...state,
                buttomPayload: action.payload,
            };

        case LOGIN_SUCCESSFUL:
            return {
                ...state,
                loginSuccessPayload: action.payload,
                loginData: action.loginData,
            };

        case KEY_RECOVERY:
            return{
                ...state,
                keyRecovery: action.payload
            }; 
        case UNLOCKING:
            return{
                ...state,
                userUnlocking: action.payload,
            };
            
        default:
            return state;
    }
}