import { makeStyles } from "@material-ui/core";
import { properties } from "../../../../Constants";

export const useStyles = makeStyles((theme) => ({
    root: {
        padding: 10,
        background: '#83808024',
        paddingBottom: theme.spacing(5)
    },
    productoContenedor:{
        padding: 5,
        '& .MuiPaper-root': {
            borderRadius: '5px !important',
            position: 'relative',
            height: '100%',
            '& > button':{
                height: '100%'
            }
        },
    },
    titulo: {
        padding: theme.spacing(1),
        color: properties.darkgray,
        fontSize: '1em',
        fontWeight: 700,
        textTransform: 'capitalize',
        textAlign: 'center'
    },
    imagen:{
        width: '100%',
        padding: theme.spacing(2),
        aspectRatio: '1/1'
    }
}))