import axios from 'axios';
import { setLoader } from '../_actionLoader';
import { setStateList } from '../_actionShoppingCar';
import { DencryptData } from '../../../Tools/CryptoLocalStorage';
import { properties, ENCRYP_TYPE, LOGIN_DATA_LS } from '../../../Constants';
import { setLoginRenew } from '../_actionLoginRenew';

//const funcMessage = JSON.parse(localStorage.getItem("funcMessage"));

export const getEstados = () => {
    return dispatch => {
        
        dispatch(setLoader(true));
        const loginDataStorage = DencryptData(ENCRYP_TYPE.JSON, LOGIN_DATA_LS);
        const { token } = loginDataStorage

        let _headers = {
            idPais: properties.idPais,
            Authorization: 'Bearer ' + token
        }      
        axios({
            method: "GET",
            url: properties.endpoints.getEstados,
            headers: _headers
            
        }).then((response) => {
                const { status, data } = response;
                if (status && status === 200) {
                    dispatch(setLoader(false));
                    dispatch(setStateList(data.Estados));

                }

            }).catch((error) => {

                var response = { ...error.response };
                dispatch(setLoader(false));
                dispatch(setLoginRenew(response));
    



                // var response = { ...error.response };
                // dispatch(setLoader(false))
                // dispatch(detailProduct(false))
                // try {
                //     dispatch(setAlert(true, response.data.func_msg, properties.severities.warning));
                // } catch (error) {
                //     dispatch(setAlert(true, funcMessage.MSG_001, properties.severities.warning));
                // }


            });
    }
}