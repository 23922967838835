import axios from "axios";
import { setLoader } from "../_actionLoader";
import { setAlert } from "../_actionAlerts";
import { properties } from "../../../Constants";
import { RECOVERY_DATA, ENCRYP_TYPE } from "../../../Constants";
import { DencryptData } from "../../../Tools/CryptoLocalStorage";
import { setKeyRecovery } from "../_actionLogin";

var funcMessage = JSON.parse(localStorage.getItem("funcMessage"));

export const postRecovery = (newKey, code) => {
  return (dispatch) => {
    var codeConfirmation = DencryptData(ENCRYP_TYPE.STRING, RECOVERY_DATA);
    if (code === codeConfirmation) {
      dispatch(setLoader(true));
      var emailUser = localStorage.getItem("emailUser");

      axios({
        method: "POST",
        url: properties.endpoints.recovery,
        headers: {
          idChannel: properties.idCanal,
          idIdioma: properties.spanish,
          idEmpresa: properties.companyID,
          EmailPrincipal: emailUser,
          newKey: newKey,
        },
      })
        .then((response) => {
          dispatch(setLoader(false));
          dispatch(
            setAlert(
              true,
              response.data.func_msg,
              properties.severities.success,
              properties.props.success

            )
          );
          dispatch(setKeyRecovery(false));
        })
        .catch((error) => {
          // var response = { ...error.response };
          // dispatch(setLoader(false));
          // dispatch(setLoginRenew(response));

          var response = { ...error.response};
          dispatch(setLoader(false))
          try{
          dispatch(setAlert(true, response.data.tecn_msg, properties.severities.warning, properties.props.error));

          }catch(error){
          dispatch(setAlert(true, funcMessage.MSG_001, properties.severities.warning, properties.props.error));

          }
        });
    } else {
      dispatch(
        setAlert(true, funcMessage.MSG_002, properties.severities.warning, properties.props.error)

      );
    }
  };
};
