export const styles = (theme) => ({
    button: {
        textTransform: 'none',
    },
    botones:{
        marginLeft: -10,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
        },
    }
   
});