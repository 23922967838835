import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

/*Constants */
import { styles } from "../constants.js";
import { LOGIN_DATA_LS, ENCRYP_TYPE } from "../../../../Constants";
import { properties } from "../../../../Constants";

/*Actions */
import { stepPurchase } from "../../../../Redux/Actions/_actionShoppingCar";
import { postInventario } from "../../../../Redux/Actions/services/postInventario";

/*Components */
import Buttons from "../Buttons";

//TOOLS
import { DencryptData } from "../../../../Tools/CryptoLocalStorage";

const labels = JSON.parse(localStorage.getItem("labels"));

export class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      BillingInformation: {},
      Order: {},
      Payment: {},
    };
  }

  componentDidMount = () => {
    let BillingInformation;
    let Order;
    let Payment;
    if (JSON.parse(localStorage.getItem("BillingInformation")) !== null) {
      BillingInformation = JSON.parse(
        localStorage.getItem("BillingInformation")
      );
    }

    if (JSON.parse(localStorage.getItem("Order")) !== null) {
      Order = JSON.parse(localStorage.getItem("Order"));
      this.props.cityList.forEach((element) => {
        if (element.idEstado === Order.state) {
          this.setState({ state: element.ciudad1 });
        }
      });
      this.props.shippingList.forEach((element) => {
        if (element.idEmpresasEnvio === Order.empresa) {
          this.setState({ empresa: element.nombre });
        }
      });
    }

    if (JSON.parse(localStorage.getItem("Payment")) !== null) {
      Payment = JSON.parse(localStorage.getItem("Payment"));
    }
    this.setState({
      BillingInformation: BillingInformation,
      Order: Order,
      Payment: Payment,
    });
  };

  handleNext = () => {
    const loginData = DencryptData(ENCRYP_TYPE.JSON, LOGIN_DATA_LS);
    let array = [];
    let arrayArticulos = [];
    this.props.dataShoppingCar.forEach((value) => {
      arrayArticulos.push({
        v_IdArticulo: value.idArticulo,
        v_IdColor: value.colorTrama,
        v_Cantidad: value.cantidad,
        v_PrecioUnitario: value.precio,
      });
    });

    let envio;
    if (this.state.Order.envio === 1) {
      envio = true;
    }
    if (this.state.Order.envio === 2) {
      envio = false;
    }

    this.state.Payment.tablePaysData.forEach((element) => {
      for (var property in element.attr) {
        if (property !== "typePayment") {
          array.push({
            v_IdPagoItems: property,
            v_IdMetodoPago: element.idMetodoPago,
            v_Valor: this.state.Payment[property],
          });
        }
      }
    });

    let object = {
      p_IdEmpresa: properties.companyID,
      p_UserCompra: loginData.usuario.idUsuario,
      v_Nombre:
        this.state.BillingInformation.typeClient === 0
          ? this.state.BillingInformation.Name
          : null,
      v_Apellido:
        this.state.BillingInformation.typeClient === 0
          ? this.state.BillingInformation.Lastname
          : null,
      v_RazonSocial:
        this.state.BillingInformation.typeClient === 1
          ? loginData.usuario.razonSocial
          : null,
      v_EmailPrincipal: this.state.BillingInformation.Email,
      v_IdPais: properties.idPais,
      v_IdEstado: this.state.BillingInformation.state,
      v_IdCiudad: this.state.BillingInformation.city,
      v_Parroquia: this.state.BillingInformation.parroquia,
      v_Municipio: this.state.BillingInformation.municipio,
      v_Direccion: this.state.BillingInformation.Address,
      v_ZonaPostal: this.state.BillingInformation.postalZone,
      v_IdTipoPersona: 1,
      v_IdTipoIdExterno: this.state.BillingInformation.typeDoc,
      v_Documento: this.state.BillingInformation.NumberDoc,
      v_IdEstadoPedido: this.state.Order.state,
      v_IdCiudadPedido: this.state.Order.city2,
      v_IdMunicipioPedido: this.state.Order.municipio2,
      v_IdParroquiaPedido: this.state.Order.parroquia2,
      v_DireccionPedido: this.state.Order.Address,
      v_Envio: envio,
      listArticulos: arrayArticulos,
      listMetodoPagos: array,
      v_IdEmpresaEnvio: envio ? this.state.Order.empresa : "",
      v_telefono: this.state.BillingInformation.Phone
    };
    this.props.postInventario(object);
  };

  handleBack = () => {
    this.props.stepPurchase(2);
  };

  render() {
    const { classes } = this.props;
    var payment = labels.PAYMENT_METHOD;

    return (
      <Grid container style={{ marginTop: 20 }}>
        <Grid container justify="center" className={classes.contenedorSummary}>
          <Grid xs={12} sm={3} md={3} />
          <Grid
            xs={12}
            sm={6}
            md={6}
            container
            className={classes.Accordion}
            style={{ padding: 20 }}
          >
            <Grid xs={12} sm={12} md={12}>
              <Typography variant="body1">
                <strong>{payment.CUSTOMER}:</strong>{" "}
                {this.state.BillingInformation.Name} {this.state.BillingInformation.Lastname}
              </Typography>
            </Grid>
            <Grid xs={12} sm={12} md={12}>
              <Typography variant="body1">
                <strong>{payment.DOC_IDENT}:</strong>{" "}
                {this.state.BillingInformation.typeDoc}-
                {this.state.BillingInformation.NumberDoc}
              </Typography>
            </Grid>
            <Grid xs={12} sm={12} md={12}>
              <Typography variant="body1">
                <strong>{payment.EMAIL}:</strong>{" "}
                {this.state.BillingInformation.Email}
              </Typography>
            </Grid>
            <Grid xs={12} sm={12} md={12}>
              <Typography variant="body1">
                <strong>{payment.SEND}:</strong>{" "}
                {
                  <>
                    {this.state.Order.envio === 1 && "Si"}
                    {this.state.Order.envio === 2 && "No"}
                  </>
                }
              </Typography>
            </Grid>

            {this.state.Order.envio === 1 && (
              <Grid xs={12} sm={12} md={12}>
                <Typography variant="body1">
                  <strong>{payment.EMPRESA}:</strong> {this.state.empresa} -{" "}
                  {this.state.state}
                </Typography>
              </Grid>
            )}

            {this.state.Payment.tablePaysData &&
              this.state.Payment.tablePaysData.length > 1 ? (
              <Grid xs={12} sm={12} md={12}>
                <center>
                  <Typography variant="body1">
                    <strong>Datos del pago:</strong>
                  </Typography>
                </center>
              </Grid>
            ) : (
              <Grid xs={12} sm={12} md={12}>
                <center>
                  <Typography variant="body1">
                    <strong>Datos del pago:</strong>
                  </Typography>
                </center>
              </Grid>
            )}

            {this.state.Payment.tablePaysData &&
              this.state.Payment.tablePaysData.length > 0 && (
                <Grid container style={{ marginTop: 20 }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <TableContainer>
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell padding="none" align="center">
                                <strong>Método</strong>
                              </TableCell>
                              <TableCell padding="none" align="center">
                                <strong>Monto</strong>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.Payment.tablePaysData.map(
                              (element, i) => (
                                <TableRow key={i}>
                                  <TableCell padding="none" align="center">
                                    {element.method}
                                  </TableCell>
                                  <TableCell padding="none" align="center">
                                    {element.amount}
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Grid>
              )}
          </Grid>
          <Grid xs={12} md={3} sm={3} />
        </Grid>
        <Grid xs={12} md={12} sm={12} container>
          <Buttons
            handleBack={this.handleBack}
            handleNext={this.handleNext}
            stepPurchase={3}
          />
        </Grid>
      </Grid>
    );
  }
}

const summary = withStyles(styles, { withTheme: true })(Summary);

const mapStateToProps = (state) => ({
  cityList: state.purchase.cityList,
  shippingList: state.purchase.shippingList,
  payMethodList: state.purchase.payMethodList,
  dataShoppingCar: state.purchase.dataShoppingCar,
  dataSumatoriaPurchase: state.purchase.dataSumatoriaPurchase,
});

const mapDispatchToProps = {
  stepPurchase,
  postInventario,
};

export default connect(mapStateToProps, mapDispatchToProps)(summary);
