import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { getProducts } from '../../../../../Redux/Actions/services/getSearchByCategories';
import { useStyles } from "./constants";

export default function BarraBusqueda() {
    const classes = useStyles();
    const iconsColor = useSelector((state) => state.headerProps.iconsColor)
    const categortiaSeleccionada = useSelector((state) => state.products.menuOptionProducts);
    const [valor, setValor] = useState("");
    const dispatch = useDispatch();

    const handleSearch = (e) => {
        const { value } = e.target;
        setValor(value)
        if (value !== '')
            dispatch(getProducts(categortiaSeleccionada === undefined ? "CAT-000" : categortiaSeleccionada, value));
    }

    return (
        <Grid item xs={12} className={classes.searchField}>
            <TextField
                id="search"
                placeholder="Buscar productos..."
                value={valor}
                onChange={handleSearch}
                type="text"
                InputProps={{
                    endAdornment:
                        <InputAdornment position="end">
                            <i
                                style={{ color: iconsColor, fontSize: 14, padding: 10 }}
                                class="icon-guss_search">
                            </i>
                        </InputAdornment>,
                }}
            />
        </Grid>
    );
}