import { properties } from "../../Constants";

export const styles = (theme) => ({

    jumbotron: {
        height: "40vh",
        width: '100%',
        objectFit: 'cover',
        [theme.breakpoints.down('sm')]: {
            height: "20vh",
        },
    },
    //////////////// Estilos del card principal de información del usuario /////////////////////////////
    paperJumbo: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            width: theme.spacing(75),
            height: theme.spacing(24),
            marginTop: theme.spacing(-15),
            marginLeft: theme.spacing(7),
            background: "linear-gradient(to left, #0e3453 0%, #00aeed 100%)",
            borderRadius: 0,
            [theme.breakpoints.down('sm')]: {
                marginLeft: 20,
                marginRight: 20,
                marginTop: theme.spacing(-15),
            },
        },
    },
    personJumbo: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            color: 'white',
            fontSize: theme.spacing(18),
            margin: theme.spacing(-4, -3, 0, -3),
        },
        [theme.breakpoints.up('md')]: {
            color: 'white',
            fontSize: theme.spacing(18),
            margin: theme.spacing(-4, -3, 0, -3),
        },
        [theme.breakpoints.up('lg')]: {
            color: 'white',
            fontSize: theme.spacing(18),
            margin: theme.spacing(-4, -3, 0, -3),
        },
    },
    typographyJumbo: {
        color: 'white',
        [theme.breakpoints.up('xs')]: {
            margin: theme.spacing(2),
        },
    },
    divider: {
        background: 'white',
    },
    favoriteIcon: {
        color: '#ff0000',
        fontSize: theme.spacing(3),
        cursor: 'pointer',
        float: 'right'
    },
    saveIconCard: {
        color: '#1a3a91',
        fontSize: theme.spacing(3),
        cursor: 'pointer',
        float: 'right'
    },
    favoriteIconOutline: {
        color: '#ff0000',
        fontSize: theme.spacing(5),
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.spacing(2),
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: theme.spacing(3),
        },

        [theme.breakpoints.up('md')]: {
            fontSize: theme.spacing(4),
        },
    },
    saveIcon: {
        color: '#1a3a91',
        fontSize: theme.spacing(5),
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.spacing(2),
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: theme.spacing(3),
        },

        [theme.breakpoints.up('md')]: {
            fontSize: theme.spacing(4),
        },
    },
    shoppingCarIcon: {
        color: '#7a975d',
        fontSize: theme.spacing(5),
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.spacing(2),
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: theme.spacing(3),
        },

        [theme.breakpoints.up('md')]: {
            fontSize: theme.spacing(4),
        },
    },
    cantText: {
        fontSize: '22px',
        margin: theme.spacing(2, 0, 0, 1),
        [theme.breakpoints.down('xs')]: {
            fontSize: '18px',
            margin: theme.spacing(0, 0, 0, 1),
            marginBottom: 'auto',
            marginTop: 'auto'
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '18px',
            margin: theme.spacing(1, 0, 0, 1),
            marginBottom: 'auto',
            marginTop: 'auto'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '18px',
            margin: theme.spacing(1, 0, 0, 1),
            marginBottom: 'auto',
            marginTop: 'auto'
        },
    },
    iconsData: {
        display: 'flex',
    },
    //////////////// Estilos del card principal de información del usuario /////////////////////////////

    title: {
        margin: theme.spacing(3, 0, 3, 7),
        color: '#013348',
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(3, 0, 3, 3),
        },
    },
    menuIcon: {
        [theme.breakpoints.up('xs')]: {
            margin: theme.spacing(3, 0, 0, 0),
            color: '#013348',
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
    },
    menuIconClose: {
        [theme.breakpoints.up('xs')]: {
            margin: theme.spacing(1, 0, 0, 0),
            color: '#013348',
            position: 'absolute',
            right: '10px'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
    },

    //////////////// Estilos del sidebar y sus opciones /////////////////////////////
    sidebarHideToXS: {
        overflow: 'auto',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            background: '#c0c0c0c4',
            height: theme.spacing(100),
            boxShadow: '0px 0px 10px grey',
        },
        [theme.breakpoints.up('md')]: {
            background: '#c0c0c0c4',
            height: theme.spacing(100),
            boxShadow: '0px 0px 10px grey',

        },
        [theme.breakpoints.up('lg')]: {
            background: '#c0c0c0c4',
            height: theme.spacing(100),
            boxShadow: '0px 0px 10px grey',

        },
    },
    sidebarShowToXS: {
        overflow: 'auto',
        '@media only screen and (max-width: 600px)': {
            [theme.breakpoints.up('xs')]: {
                background: '#c0c0c0',
                height: theme.spacing(63),
                zIndex: '1',
                marginLeft: '50%',
                marginTop: theme.spacing(2),
                boxShadow: '0px 0px 10px grey',
            },
            [theme.breakpoints.up('sm')]: {
                background: '#c0c0c0c4',
                height: theme.spacing(100),
                boxShadow: '0px 0px 10px grey',
            },
            [theme.breakpoints.up('md')]: {
                background: '#c0c0c0c4',
                height: theme.spacing(100),
                boxShadow: '0px 0px 10px grey',
            },
            [theme.breakpoints.up('lg')]: {
                background: '#c0c0c0c4',
                height: theme.spacing(100),
                boxShadow: '0px 0px 10px grey',
            },
        },
        '@media only screen and (min-width: 600px)': {
            [theme.breakpoints.up('xs')]: {
                background: '#c0c0c0c4',
                height: theme.spacing(100),
                boxShadow: '0px 0px 10px grey',
            },
            [theme.breakpoints.up('sm')]: {
                background: '#c0c0c0c4',
                height: theme.spacing(100),
                boxShadow: '0px 0px 10px grey',
            },
            [theme.breakpoints.up('md')]: {
                background: '#c0c0c0c4',
                height: theme.spacing(100),
                boxShadow: '0px 0px 10px grey',
            },
            [theme.breakpoints.up('lg')]: {
                background: '#c0c0c0c4',
                height: theme.spacing(100),
                boxShadow: '0px 0px 10px grey',
            },
        },

    },
    //opciones
    sideOptions: {
        [theme.breakpoints.down('xs')]: {
            margin: '10px 0px',
            fontSize: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            margin: theme.spacing(1, 0, 1, 3),
            fontSize: '100%',
        },
        [theme.breakpoints.up('md')]: {
            margin: theme.spacing(1, 0, 1, 3),
            fontSize: '130%',
        },
        [theme.breakpoints.up('lg')]: {
            margin: theme.spacing(1, 0, 1, 3),
            fontSize: '130%',
        },
    },
    //opciones
    //////////////// Estilos del sidebar y sus opciones /////////////////////////////

    //////////////// Estilos de la sección principal (main) /////////////////////////////
    mainWithSidebar: {
        '@media only screen and (min-width: 600px)': {
            [theme.breakpoints.down('xs')]: {
                margin: theme.spacing(-100, 0, 0, 0),
                padding: theme.spacing(0, 1, 0, 0),
            },
            [theme.breakpoints.up('sm')]: {
                margin: theme.spacing(-90, 0, 0, 0),
                padding: theme.spacing(0, 1, 0, 0),
            },
        },
    },
    mainWithoutSidebar: {
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(-65, 0, 0, 0),
            padding: theme.spacing(0, 1, 0, 0),
        },
        [theme.breakpoints.up('sm')]: {
            margin: theme.spacing(-90, 0, 0, 0),
            padding: theme.spacing(0, 1, 0, 0),
        },
    },
    mainData: {
        [theme.breakpoints.down('xs')]: {
            height: theme.spacing(70),
            justifyContent: 'center',
            background: '#ffffff',
        },
        [theme.breakpoints.up('sm')]: {
            height: theme.spacing(80),
            justifyContent: 'center',
        },
        height: theme.spacing(80),
        width: 'available',
        overflow: 'auto',
        background: '#dfdee3',

        '&::-webkit-scrollbar': {
            padding: '0.4em',
            margin: '0.4em',
            width: '0.4em',
            background: '#dfdee3',

        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',

        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#aadd11',
            background: '#aadd11',

        }
    },
    //////////////// Estilos de la sección principal (main) /////////////////////////////

    //////////////// Estilos de cartas de productos /////////////////////////////
    root: {
        // flexGrow: 1,
        margin: theme.spacing(3, 2, 3, 2),
    },
    cardsMain: {
        margin: 'auto',
        maxWidth: 400,
        borderRadius: 0,
        [theme.breakpoints.up('xs')]: {
            maxWidth: 600,
        },
    },
    image: {
        padding: theme.spacing(1),
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    //////////////// Estilos de cartas de productos /////////////////////////////

    productoContenedor: {
        padding: 5,
        '& .MuiPaper-root': {
            borderRadius: '5px !important',
            position: 'relative',
            height: '100%',
            '& > button': {
                height: '100%'
            }
        },
    },
    titulo: {
        padding: theme.spacing(1),
        color: properties.darkgray,
        fontSize: '1em',
        fontWeight: 700,
        textTransform: 'capitalize',
        textAlign: 'center'
    },
    imagen: {
        width: '100%',
        padding: theme.spacing(2),
        aspectRatio: '1/1'
    }
});
