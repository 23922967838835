import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Grid, Divider, InputBase, IconButton, Select, MenuItem } from "@material-ui/core";
import { useStyles } from "./constants.js";

/*Actions */
import { getSearchByCategories } from "../../../Redux/Actions/services/getSearchByCategories";
import { setLoader } from "../../../Redux/Actions/_actionLoader";
import { menuOptionSelected } from "../../../Redux/Actions/_actionProducts";
import "../../Products/index.css";

//Properties
import { properties } from "../../../Constants";

function SearchBar() {
  const menuOptionProducts = useSelector((state) => state.products.menuOptionProducts);
  const listCat = useSelector((state) => state.homeProps.getHomeData);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [input, setInput] = useState({ name: "" });
  const [valueSelect, setValueSelect] = useState(() => {return menuOptionProducts !== undefined ? menuOptionProducts : properties.searchBarId;});

  useEffect(() => {
    localStorage.setItem("searchBar", valueSelect);
    dispatch(menuOptionSelected(valueSelect));
  }, [valueSelect, dispatch]); // ✅ Nuestro efecto usa una variable en el ámbito del componente

  const search = (event) => {
    event.preventDefault();
    dispatch(setLoader(true));
    dispatch(getSearchByCategories(valueSelect, input.name));
  };

  const handleInputChange = (event) => {
    setInput({
      ...input,
      [event.target.name]: event.target.value,
    });
  };

  function onChangeValues(event) {
    setValueSelect(event.target.value);
  }

  return (
    <Grid container className={classes.marginTop}>
      <Grid item xs={12} className={classes.hiddenMobile}>
        <Paper component="form" className={classes.root} elevation={0}>
          <Select
            className={classes.select}
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={ menuOptionProducts === undefined ? valueSelect : menuOptionProducts }
            label="Age"
            variant="standard"
            color="primary"
            size="small"
            name="select"
            onChange={(event) => onChangeValues(event)}
          >
            <MenuItem value={"CAT-000"}>
              {"Todas"}
            </MenuItem>
            {listCat &&
              listCat.categorias &&
              listCat.categorias.map((element) => (
                <MenuItem value={element.idCategoria}>
                  {element.descripcion}
                </MenuItem>
              ))}
          </Select>

          <Divider className={classes.divider} orientation="vertical" />

          <InputBase
            className={classes.input}
            placeholder="Búsqueda"
            inputProps={{ "aria-label": "search google maps", maxLength: 30 }}
            name="name"
            onChange={handleInputChange}
          />

          <IconButton
            color="secondary"
            type="submit"
            className={classes.iconButton}
            aria-label="search"
            onClick={search}
          >
            <i style={{ fontSize: 20 }} class="icon-guss_search"></i>
          </IconButton>
          {/* </form> */}
        </Paper>
      </Grid>
      <Grid item xs={12} sm={12} md={12} className={classes.hiddenDesktop}>
        <Grid container>
          <Grid xs={12} sm={6} md={6}>
            <Select
              className={classes.selectMobile}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={
                menuOptionProducts === undefined
                  ? valueSelect
                  : menuOptionProducts
              }
              label="Age"
              variant="standard"
              color="primary"
              size="small"
              name="select"
              onChange={(event) => onChangeValues(event)}
            >
              <MenuItem value={"CAT-000"}>
                {"Todas"}
              </MenuItem>
              {listCat &&
                listCat.categorias &&
                listCat.categorias.map((element) => (
                  <MenuItem value={element.idCategoria}>
                    {element.descripcion}
                  </MenuItem>
                ))}
            </Select>
          </Grid>

          <Grid xs={12} sm={6} md={6}>
            <Paper
              component="form"
              className={classes.rootMobile}
              elevation={0}
              variant="outlined"
            >
              <InputBase
                className={classes.input}
                placeholder="Búsqueda"
                inputProps={{ "aria-label": "search google maps" }}
                color="secondary"
                variant="outlined"
                name="name"
                onChange={handleInputChange}
              />
              <IconButton
                color="secondary"
                type="submit"
                className={classes.iconButton}
                onClick={search}
                aria-label="search"
              >
                <i style={{ fontSize: 20 }} class="icon-guss_search"></i>
              </IconButton>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default SearchBar;
