import axios from "axios";
import { properties, ENCRYP_TYPE, LOGIN_DATA_LS } from "../../../Constants";
import { setAlert } from "../_actionAlerts";
import { setLoader } from "../_actionLoader";
import { DencryptData } from "../../../Tools/CryptoLocalStorage";
import { solicitud, setCotizar } from '../_actionShoppingCar';
import { setLoginRenew } from "../_actionLoginRenew";
import { setPdf } from "../_actionShoppingCar";

export const postInventarioCotizaciones = (body) => {

    return dispatch =>{

       
        dispatch(setLoader(true));
        const loginDataStorage = DencryptData(ENCRYP_TYPE.JSON, LOGIN_DATA_LS);
        const { token } = loginDataStorage;
        const _headers = {
            Authorization: 'Bearer ' + token
        }
        axios({
            method: "POST",
            url: properties.endpoints.postInventarioCotizaciones,
            headers: _headers,
            data: body,
            array: [],
        }).then((response) => {

            dispatch(setLoader(false))
            dispatch(solicitud(false));
            dispatch(setCotizar(false));
            dispatch(setAlert(true, response.data.func_msg, properties.severities.success, properties.props.success));

            localStorage.setItem("quoteData",JSON.stringify(body));
            let date = new Date();
            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();
            localStorage.setItem("Date", `${day}/${month}/${year}` );
            dispatch(setCotizar(false));
            
            if(body.p_IdTipoPedido === properties.solicitud){
                dispatch(setPdf(false));
                
            }else{
                
                dispatch(setPdf(true));
            }



        }).catch((error) => {

            var response = { ...error.response };
            dispatch(setLoader(false));
            dispatch(setLoginRenew(response));

            // var response = { ...error.response };
            // dispatch(setLoader(false))
            // try {
            //     dispatch(setAlert(true, response.data.tecn_msg, properties.severities.warning));
            // } catch (error) {
            //     dispatch(setAlert(true, funcMessage.MSG_001, properties.severities.warning))
            // }
        })
    }
}