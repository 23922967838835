
import { createTheme } from '@material-ui/core/styles'
import { DencryptData, EncryptData } from '../Tools/CryptoLocalStorage';


//LocalStorage encripts
export const SECRET_KEY = 'PrAEQUOUm9E3_CRiKMg';
export const PR_SECRET_KEY = 'PrAEQUOUm9E3';
export const ENCRYP_TYPE = {
  STRING: 'String',
  JSON: 'JSON',
  SECRET_KEY: 'KMGEncoded'
}
export const BASE = 'AEQUOUm9E3';
export const PROPERTIES_LS = 'Pr'.concat(BASE);
export const CREDIT_CARD_LS = 'cC'.concat(BASE);
export const LOGIN_DATA_LS = 'LD'.concat(BASE);
export const PREFERENCES_LIST = 'Pl'.concat(BASE);
export const TOKEN = 'Tk'.concat(BASE);
export const RECOVERY_DATA = 'Re'.concat(BASE);
export const QUOTE_DATA = 'Qd'.concat(BASE);
export const CATEGORY_LS = 'CA'.concat(BASE);
export const ART_LS = 'aR'.concat(BASE);



/* constantes de propiedades */
export let properties = {};
try {
  if (localStorage.getItem("properties")) {
    properties = JSON.parse(localStorage.getItem("properties"));
    EncryptData(properties, ENCRYP_TYPE.JSON, PROPERTIES_LS)
    localStorage.removeItem("properties");
  } else {
    properties = DencryptData(ENCRYP_TYPE.JSON, PROPERTIES_LS);
  }
} catch (error) { }

export const funcMessage = JSON.parse(localStorage.getItem("funcMessage"));

export const labels = JSON.parse(localStorage.getItem("labels"));


/* Digitel Feature constants */
export const THEME = createTheme({
  palette: {
    primary: {
      main: properties.primary,
    },
    secondary: {
      main: properties.secondary,
    }
  },

});

// export const product = [
//   {
//     img: MUEBLE,
//     title: 'Silla tentación',
//     subtitle: 'Silla tentación',
//     price: '5.450.000,00',
//     subprice: '5.350.000,00'
//   },
//   {
//     img: SILLON,
//     title: 'Mueble',
//     subtitle: 'Silla tentación',
//     price: '5.450.000,00',
//     subprice: null
//   },

// ]



