import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SearchIcon from '@material-ui/icons/Search';

import { openBarraSearch, seeMoreCategories } from "../../../../../Redux/Actions/_actionHome";
import { getCategoriasPadreHijo, getProducts } from "../../../../../Redux/Actions/services/getSearchByCategories";
import { setLoader } from "../../../../../Redux/Actions/_actionLoader";
import { useStyles } from "./constants";

export default function SeeCategories() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [categoriaPadre, setCategoriaPadre] = useState(undefined);
    const [categoriaPadreNombre, setCategoriaPadreNombre] = useState('');
    const [listaAux, setListaAux] = useState("");
    const lista = useSelector((state) => state.homeProps.listaCategorias);
    const verMas = useSelector((state) => state.homeProps.seeMore);

    useEffect(() => {
        setListaAux([]);
        dispatch(setLoader(true));
        setTimeout(() => {
            setListaAux([...lista]);
            dispatch(setLoader(false));
        }, 500);
    }, [lista]);

    const handleClose = () => {
        dispatch(seeMoreCategories(false));
    };

    const handleSearch = (idCategoria, nombreCategoria) => {
        setCategoriaPadre(idCategoria);
        dispatch(getCategoriasPadreHijo(idCategoria))
        setCategoriaPadreNombre(nombreCategoria)
    }

    const handleTodas = () => {
        dispatch(seeMoreCategories(false));
        dispatch(openBarraSearch(true));
        dispatch(getProducts(categoriaPadre));
    }

    return (
        <Dialog open={verMas} fullScreen scroll={'paper'}>
            <DialogTitle className={classes.titulo}>
                <IconButton
                    edge="start"
                    onClick={handleClose}>
                    <ArrowBackIcon />
                </IconButton>
                Categorías
            </DialogTitle>
            <DialogContent dividers={true}>
                <DialogContentText style={{ padding: 0 }}>
                    {listaAux.length > 0 ?
                        <>
                            {listaAux.map(element => (
                                <List>
                                    <ListItem>
                                        <ListItemText
                                            primary={element.Descripcion}
                                        />
                                        {element.children ?
                                            <ListItemSecondaryAction onClick={() => { handleSearch(element.IdCategoria, element.Descripcion) }}>
                                                <NavigateNextIcon edge="end">
                                                    <NavigateNextIcon />
                                                </NavigateNextIcon>
                                            </ListItemSecondaryAction>
                                            :
                                            <ListItemSecondaryAction onClick={handleTodas}>
                                                <SearchIcon edge="end">
                                                    <SearchIcon />
                                                </SearchIcon>
                                            </ListItemSecondaryAction>
                                        }
                                    </ListItem>
                                </List>
                            ))}
                            <List >
                                <ListItem>
                                    <ListItemText
                                        primary={"Buscar " + categoriaPadreNombre}
                                    />
                                    <ListItemSecondaryAction onClick={handleTodas}>
                                        <SearchIcon edge="end">
                                            <SearchIcon />
                                        </SearchIcon>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>
                        </>
                        :
                        <></>
                    }
                </DialogContentText>
            </DialogContent>
        </Dialog >
    );
}