import { SET_ALERT} from "../constants";


export const alertReducer = (state = {}, action) =>{
    switch(action.type) {
        case SET_ALERT:
            return{ ...state,
                alert: action
            };
    default:
        return state;
    }
}