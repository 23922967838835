import axios from 'axios';
import { setAlert } from '../_actionAlerts';
import { setLoader } from '../_actionLoader';
import { properties } from '../../../Constants';
import { EncryptData } from '../../../Tools/CryptoLocalStorage';
import { ENCRYP_TYPE, TOKEN, LOGIN_DATA_LS } from '../../../Constants';
import { loginSuccessful } from '../_actionLogin';
import { openLogin } from '../_actionLogin';
import { loginRenewModal } from '../_actionLoginRenew';
import { getCarrito } from './getCarrito';


var funcMessage = JSON.parse(localStorage.getItem("funcMessage"));


export const postLoginRenew = (headers) => {

    return  dispatch => {
        dispatch(setLoader(true))
        axios({
            method:"POST",
            url: properties.endpoints.loginRenew,
            headers: headers,
        }).then((response) => {

            const { status, data } = response;

            if (status && status === 200) {

                dispatch(loginSuccessful(true, data))
                dispatch(setLoader(false))
                dispatch(openLogin(false))
                EncryptData(data.token, ENCRYP_TYPE.STRING, TOKEN)
                EncryptData(data, ENCRYP_TYPE.JSON, LOGIN_DATA_LS)
                dispatch(loginRenewModal(false))
                dispatch(setAlert(true, response.data.func_msg, properties.severities.success,  properties.props.success));

                dispatch(getCarrito(true));
            }

        }).catch((error) =>{

            var response = { ...error.response }
            dispatch(setLoader(false))
            try {
                dispatch(setAlert(true, response.data.tecn_msg, properties.severities.error, properties.props.error))

            } catch (error) {
                dispatch(setAlert(true, funcMessage.MSG_001, properties.severities.warning, properties.props.error));

            }


        })
    }
}