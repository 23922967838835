import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid, TextField, MenuItem, } from '@material-ui/core';

/*Constants */
import { styles } from "../constants.js";
import factura from '../img/billing.png'
import { validateValues } from '../../../../Tools/index';

/*Actions */
import { stepPurchase } from '../../../../Redux/Actions/_actionShoppingCar';
import { getCity } from '../../../../Redux/Actions/services/getCity';
import { getMunicipalities } from '../../../../Redux/Actions/services/getMunicipalities';
import { getParishes } from '../../../../Redux/Actions/services/getParishes';

/*Components */
import Buttons from '../Buttons';

const labels = JSON.parse(localStorage.getItem("labels"));


const constTextField = [
    "typeClient",
    "typeDoc",
    "NumberDoc",
    "Name",
    "Lastname",
    "BusinessName",
    "Phone",
    "Email",
    "Address",
    "state",
    "city",
    "municipio",
    "parroquia",
    "PostalZone",
]

const boolTextField = [
    "BoolTypeClient",
    "BoolTypeDoc",
    "BoolNumberDoc",
    "BoolName",
    "BoolLastname",
    "BoolBusinessName",
    "BoolPhone",
    "BoolEmail",
    "BoolAddress",
    "BoolState",
    "Boolcity",
    "Boolmunicipio",
    "BoolParroquia",
    "BoolPostalZone",
];

const typePerson = [
    {
        id: 0,
        label: 'Natural'
    },
    {
        id: 1,
        label: 'Jurídico'
    }
]

export class BillingInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            typeClient: '',
            typeDoc: '',
            NumberDoc: '',
            Name: '',
            Lastname: '',
            BusinessName: '',
            Phone: '',
            Email: '',
            Address: '',
            state: '',
            city: '',
            municipio: '',
            parroquia: '',
            PostalZone: '',
            BoolTypeClient: false,
            BoolTypeDoc: false,
            BoolNumberDoc: false,
            BoolName: false,
            BoolLastname: false,
            BoolBusinessName: false,
            BoolPhone: false,
            BoolEmail: false,
            BoolAddress: false,
            BoolState: false,
            Boolcity: false,
            Boolmunicipio: false,
            BoolParroquia: false,
            BoolPostalZone: false,
            required: labels.REQUIRED,
            emailFormat: labels.EMAIL_FORMAT,
            typeClienteOption: true,
            tipoDocArray: []
        }
    }

    componentDidMount = () => {
        if (JSON.parse(localStorage.getItem('BillingInformation')) !== null) {
            let BillingInformation = JSON.parse(localStorage.getItem('BillingInformation'))
            constTextField.forEach((element, i) => {
                this.setState({ [constTextField[i]]: BillingInformation[element] })
            });
            if (BillingInformation.typeClient === 0) {
                this.setState({ typeClienteOption: true })
            }
            if (BillingInformation.typeClient === 1) {
                this.setState({ typeClienteOption: false })
            }
        };
    }

    componentWillUnmount = () => {
        let BillingInformation = {}

        constTextField.forEach((element, i) => {
            BillingInformation[constTextField[i]] = this.state[constTextField[i]]
        });
        localStorage.setItem('BillingInformation', JSON.stringify(BillingInformation))
    }

    handleNext = () => {
        /**Bucle para pintar alerta en inputs vacío**/
        for (var i = 0; i < constTextField.length; i++) {
            var textField = constTextField[i]
            this.validationForm(this.state[textField], "", boolTextField[i], true);
        }

        /**Bucle para validar cuántos inputs están llenos**/
        var cont = 0;
        for (var u = 0; u < constTextField.length; u++) {
            var textField1 = constTextField[u]
            if (this.state[textField1] !== '' && !this.state[boolTextField[u]]) {
                var bool = true;
                if (bool === true) {
                    cont = cont + 1;
                }
            }
        }

        if (this.state.typeClienteOption === false)
            cont = cont + 2;
        if (this.state.typeClienteOption === true)
            cont = cont + 1;
        if (cont === boolTextField.length)
            this.props.stepPurchase(1);
    }

    handleChange = (event) => {
        var value = event.target.value;
        var name = event.target.name;
        let type = event.target.id;

        let bool = validateValues(value, type)
        if (bool) {
            this.setState({ [name]: value, ['Bool' + name]: !bool });
        }
    };

    handleChangeEmail = (event) => {
        var value = event.target.value;
        var name = event.target.name;
        let type = event.target.id;

        let bool = validateValues(value, type)
        this.setState({ [name]: value, ['Bool' + name]: !bool });
    };

    handleChangeCombos = (event) => {
        var value = event.target.value;
        var name = event.target.name;
        var docArray = [...this.props.documentsIdList];

        this.setState({ [name]: value });
        constTextField.forEach((valor, i) => {
            this.validationForm(name, constTextField[i], boolTextField[i], false);
        });

        if (name === 'state') {
            this.props.getCity(value)
        }

        if (name === 'city') {
            this.props.getMunicipalities(this.state.state, value)
        }

        if (name === 'municipio') {
            this.setState({ parroquia: '' })
            this.props.getParishes(this.state.state, this.state.city, value)
        }

        if (name === 'typeClient') {
            if (value === 0) {
                var y = docArray.filter((item2) => item2.idTipoDocumento === 'V' || item2.idTipoDocumento === 'E');
                this.setState({
                    typeClienteOption: true,
                    BusinessName: '',
                    tipoDocArray: y
                })
            } else {
                var x = docArray.filter((item) => item.idTipoDocumento !== 'V' && item.idTipoDocumento !== 'E');
                this.setState({
                    typeClienteOption: false,
                    Name: '', Lastname: '',
                    tipoDocArray: x
                })
            }
        }
    };

    /**-Validación de inputs esta función se llama al momento de darle click a "continuar" y haya algún campo vacío (se pinta error)
    * -En caso de que sea tipeado algo, se borrará el error pintado**/
    validationForm = (state, value, boolean, valueState) => {
        if (state === value) {
            this.setState({
                [boolean]: valueState,
            });
        }
    }

    render() {
        const { typeClient, typeDoc, NumberDoc, Name, Email, state, city, municipio, parroquia, Phone, PostalZone, Address, Lastname, BusinessName,
            required, emailFormat, BoolNumberDoc, BoolPostalZone, BoolTypeDoc, BoolName, BoolEmail, BoolTypeClient, BoolAddress, BoolState, Boolcity, Boolmunicipio, BoolParroquia, BoolPhone, BoolLastname, typeClienteOption, BoolBusinessName,
        } = this.state;
        const { classes } = this.props;
        return (
            <Grid container >
                <Grid xs={12} md={12} sm={12} container className={classes.height}>
                    <Grid xs={12} md={1} sm={1} />
                    <Grid xs={12} md={5} sm={5} container
                        style={{ paddingRight: 15, paddingLeft: 15 }}>
                        <Grid container
                            spacing={3}  >
                            <Grid item xs={12} md={12} sm={12} >
                                <TextField
                                    select
                                    fullWidth
                                    error={BoolTypeClient}
                                    helperText={
                                        BoolTypeClient
                                            ? required
                                            : ""
                                    }
                                    style={{ marginTop: "5vh" }}
                                    label={labels.CUSTOMER_TYPE}
                                    value={typeClient}
                                    name='typeClient'
                                    onChange={this.handleChangeCombos}
                                >
                                    {typePerson.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={3} md={2} sm={2}>
                                <TextField
                                    margin='normal'
                                    select
                                    fullWidth
                                    error={BoolTypeDoc}
                                    helperText={
                                        BoolTypeDoc
                                            ? required
                                            : ""
                                    }
                                    value={typeDoc}
                                    name='typeDoc'
                                    onChange={this.handleChangeCombos}
                                >
                                    {this.state.tipoDocArray && this.state.tipoDocArray.map((option) => (
                                        <MenuItem key={option.idTipoDocumento} value={option.idTipoDocumento}>
                                            {option.idTipoDocumento}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={9} md={10} sm={10}>
                                <TextField
                                    fullWidth
                                    error={BoolNumberDoc}
                                    helperText={
                                        BoolNumberDoc
                                            ? required
                                            : ""
                                    }
                                    label={this.props.isMobile ? 'DNI' : labels.DOC_TYPE}
                                    name="NumberDoc"
                                    id='numeric'
                                    value={NumberDoc}
                                    onChange={this.handleChange}
                                    inputProps={{
                                        maxLength: 9,
                                    }}
                                />
                            </Grid>
                            {typeClienteOption ?
                                <>
                                    <Grid item xs={12} md={12} sm={12}>
                                        <TextField
                                            fullWidth
                                            error={BoolName}
                                            helperText={
                                                BoolName
                                                    ? required
                                                    : ""
                                            }
                                            label={labels.NAME_LB}
                                            name="Name"

                                            id="textWithSpace"
                                            value={Name}
                                            onChange={this.handleChange}
                                            inputProps={{
                                                maxLength: 30,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} sm={12}>
                                        <TextField
                                            fullWidth
                                            error={BoolLastname}
                                            helperText={
                                                BoolLastname
                                                    ? required
                                                    : ""
                                            }
                                            label={labels.LASTNAME_LB}
                                            name="Lastname"
                                            id="textWithSpace"
                                            value={Lastname}
                                            onChange={this.handleChange}
                                            inputProps={{
                                                maxLength: 30,
                                            }}
                                        />
                                    </Grid>
                                </>
                                :
                                <Grid item xs={12} md={12} sm={12}>
                                    <TextField
                                        fullWidth
                                        error={BoolBusinessName}
                                        helperText={
                                            BoolBusinessName
                                                ? required
                                                : ""
                                        }
                                        label={labels.BUSINESSNAME_LB}
                                        name="BusinessName"
                                        id="alphanumeric"
                                        value={BusinessName}
                                        onChange={this.handleChange}
                                        inputProps={{
                                            maxLength: 30,
                                        }}
                                    />
                                </Grid>
                            }
                            <Grid item xs={12} md={12} sm={12}>
                                <TextField
                                    fullWidth
                                    error={BoolEmail}
                                    helperText={
                                        BoolEmail
                                            ? "Campo requerido o " + emailFormat
                                            : ""
                                    }
                                    label={labels.USER_LB}
                                    name="Email"
                                    id='email'
                                    value={Email}
                                    onChange={this.handleChangeEmail}
                                    inputProps={{
                                        maxLength: 40,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} sm={6} >
                                <TextField
                                    select
                                    fullWidth
                                    error={BoolState}
                                    helperText={
                                        BoolState
                                            ? required
                                            : ""
                                    }
                                    label={labels.STATE}
                                    value={state}
                                    name='state'
                                    onChange={this.handleChangeCombos}
                                >
                                    {this.props.statesList && this.props.statesList.map((option) => (
                                        <MenuItem key={option.idEstado} value={option.idEstado}>
                                            {option.estado1}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={6} sm={6} >
                                <TextField
                                    select
                                    fullWidth
                                    error={Boolcity}
                                    helperText={
                                        Boolcity
                                            ? required
                                            : ""
                                    }
                                    label={labels.CITY}
                                    value={city}
                                    name='city'
                                    onChange={this.handleChangeCombos}
                                >
                                    {this.props.cityList && this.props.cityList.map((option) => (
                                        <MenuItem key={option.idCiudad} value={option.idCiudad}>
                                            {option.ciudad1}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={6} sm={6} >
                                <TextField
                                    select
                                    fullWidth
                                    error={Boolmunicipio}
                                    helperText={
                                        Boolmunicipio
                                            ? required
                                            : ""
                                    }
                                    label={labels.MUNICIPIO}
                                    value={municipio}
                                    name='municipio'
                                    onChange={this.handleChangeCombos}
                                >
                                    {this.props.municipalitiesList && this.props.municipalitiesList.map((option) => (
                                        <MenuItem key={option.idMunicipio} value={option.idMunicipio}>
                                            {option.municipio}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={6} sm={6} >
                                <TextField
                                    select
                                    fullWidth
                                    error={BoolParroquia}
                                    helperText={
                                        BoolParroquia
                                            ? required
                                            : ""
                                    }
                                    label={labels.PARROQUIA}
                                    value={parroquia}
                                    name='parroquia'
                                    onChange={this.handleChangeCombos}
                                >
                                    {this.props.parishesList && this.props.parishesList.map((option) => (
                                        <MenuItem key={option.idParroquia} value={option.idParroquia}>
                                            {option.parroquia}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={6} sm={6}>
                                <TextField
                                    fullWidth
                                    error={BoolPhone}
                                    helperText={
                                        BoolPhone
                                            ? required
                                            : ""
                                    }
                                    label={labels.PHONE}
                                    name="Phone"
                                    id='numeric'
                                    value={Phone}
                                    onChange={this.handleChange}
                                    inputProps={{
                                        maxLength: 11,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} sm={6}>
                                <TextField
                                    fullWidth
                                    error={BoolPostalZone}
                                    helperText={
                                        BoolPostalZone
                                            ? required
                                            : ""
                                    }
                                    label={labels.POSTALZONE}
                                    name="PostalZone"
                                    id='numeric'
                                    value={PostalZone}
                                    onChange={this.handleChange}
                                    inputProps={{
                                        maxLength: 11,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid xs={12} md={1} sm={1} />
                    <Grid xs={12} md={5} sm={12} />
                    <Grid xs={12} md={5} sm={5} style={{
                        position: "absolute",
                        marginLeft: '53%',
                        marginTop: 20,
                    }} className={classes.hiddenMobile}>
                        <img src={factura} alt='img' style={{
                            width: "90%",
                            objectFit: "contain"
                        }} />
                    </Grid>
                    <Grid xs={12} md={1} sm={1} />
                    <Grid xs={12} md={8} sm={8}
                        style={{ paddingRight: 15, marginTop: 20 }}>
                        <TextField
                            fullWidth
                            error={BoolAddress}
                            helperText={
                                BoolAddress
                                    ? required
                                    : ""
                            }
                            label={labels.ADDRESS}
                            name="Address"
                            id='alphanumeric'
                            value={Address}
                            onChange={this.handleChange}
                            inputProps={{
                                maxLength: 80,
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid xs={12} md={12} sm={12} container className={classes.next}>
                    <Buttons
                        handleNext={this.handleNext}
                        stepPurchase={1}
                    />
                </Grid>
            </Grid>
        )
    }
}


const billing = withStyles(styles, { withTheme: true })(BillingInformation)

const mapStateToProps = (state) => ({
    documentsIdList: state.purchase.documentsIdList,
    statesList: state.purchase.statesList,
    cityList: state.purchase.cityList,
    municipalitiesList: state.purchase.municipalitiesList,
    parishesList: state.purchase.parishesList,
    purchaseOpen: state.purchase.purchaseOpen,
    isMobile: state.homeProps.isMobile
})

const mapDispatchToProps = {
    stepPurchase,
    getCity,
    getMunicipalities,
    getParishes,
}

export default connect(mapStateToProps, mapDispatchToProps)(billing)
