import { useSelector } from "react-redux";
import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import "bootstrap/dist/css/bootstrap.min.css"
import { useStyles } from "./constants";

export default function Carusel() {
    const getHomeData = useSelector((state) => state.homeProps.getHomeData);
    const classes = useStyles();

    return (
        <Carousel className={classes.root}
            nextIcon="" prevIcon=""
            indicators={true}
            controls={false}>
            {getHomeData.carousel.length > 0 ?
                getHomeData.carousel.map(element => (
                    <Carousel.Item>
                        <LazyLoadImage
                            className={classes.imagen}
                            alt="First slide"
                            effect="blur"
                            visibleByDefault={true}
                            src={'https://firebasestorage.googleapis.com/v0/b/guss-muebles-web.appspot.com/o/carrusel%201.jpg?alt=media&token=604643e2-cd0b-45d9-bff4-f77cecd386e1'}
                        />
                    </Carousel.Item>
                ))
                :
                <>
                    <LazyLoadImage
                        className={classes.imagen}
                        alt="First slide"
                        effect="blur"
                        visibleByDefault={true}
                        src={'https://firebasestorage.googleapis.com/v0/b/guss-muebles-web.appspot.com/o/carrusel%201.jpg?alt=media&token=604643e2-cd0b-45d9-bff4-f77cecd386e1'}
                    />
                </>
            }
        </Carousel >
    );
}