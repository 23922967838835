import { properties } from "../../../Constants";

export const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    closeModal: {
        cursor: 'pointer'
    },
    title: {
        color: properties.secondary,
        fontWeight: 'bold'
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        padding: '0px 10px'
    },
    button: {
        textTransform: 'none',
    },

    dialog: {
        overflowY: "auto",
        // height: "40vh !important",
        textAlign: "right",
        "&::-webkit-scrollbar": {
            padding: "0.4em",
            margin: "0.4em",
            width: "0.4em",
            background: "#dfdee3",
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#aadd11",
            background: "#aadd11",
        },
    },
    hiddenMobile: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },

    height: {
        minHeight: 600,
        [theme.breakpoints.down('xs')]: {
            minHeight: 450,
        },
    },
    next: {
        padding: '0px 20px',
        [theme.breakpoints.down('sm')]: {
            paddingRight: 0
        },
    },
    Accordion: {
        backgroundColor: properties.grayLight,
        borderRadius: 20
    },


    rootStepper: {
        backgroundColor: '#e8f4d9',
        zIndex: 1,
        color: '#fff',
        width: 80,
        height: 80,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    activeStepper: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(140,198,63) 0%, rgb(140,198,63) 50%, rgb(140,198,63) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completedStepper: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(232,244,217) 0%, rgb(232,244,217) 50%, rgb(232,244,217) 100%)',
    },
    paddingContainer: {
        padding: '0px 20px'
    },
    alignItemsCenter: {
        marginTop: 'auto',
        marginBottom: 'auto',
        padding: 5
    },
    contenedorSummary: {
        marginRight: 20,
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
        },
    }
});
