import React, { Component } from 'react';

class Profile extends Component {
    render() {
        return (
            <div>
               Perfil 
            </div>
        );
    }
}

export default Profile;