import {
    OPEN_PRODUCTS, MODAL_SEARCH, DETAIL_PRODUCT, MENU_OPTION_SELECTED, PREFERENCES,
    QUESTIONSANDANSWERS, IMG_DETAIL_PRODUCT, ACTIVE_ARRAY
} from '../constants';

export const products = (state = {}, action) => {
    switch (action.type) {
        case OPEN_PRODUCTS:
            return { ...state, productOpen: action.payload }
        case MODAL_SEARCH:
            return { ...state, searchModal: action.payload }
        case DETAIL_PRODUCT:
            return { ...state, productDetails: action.payload }
        case MENU_OPTION_SELECTED:
            return { ...state, menuOptionProducts: action.option }
        case PREFERENCES:
            return { ...state, preferencesUpdate: action.payload }
        case QUESTIONSANDANSWERS:
            return { ...state, questionsPayload: action.payload }
        case IMG_DETAIL_PRODUCT:
            return { ...state, imagenesProducto: action.data }
        case ACTIVE_ARRAY:
            return { ...state, activeArray: action.index }
        default:
            return state;
    }
}