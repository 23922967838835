import { makeStyles } from "@material-ui/core";
import { properties } from "../../../../Constants";

export const useStyles = makeStyles((theme) => ({
    root: {
        padding: 10,
        background: '#83808024'
    },
    categoriacontenedor: {
        padding: 5,
        '& .MuiPaper-root': {
            aspectRatio: '1/1',
            background: 'linear-gradient(to top, rgb(1, 51, 72) 0%, rgb(5, 133, 186) 100%)',
            borderRadius: '5px !important',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& .MuiCardContent-root > span': {
                fontSize: 50,
                color: properties.white,
                display: 'table',
                margin: 'auto',
                '& :before':{
                    display: 'contents'
                }
            }
        }
    },
    cardcontent: {
        padding: 0,
        "&:last-child": {
            paddingBottom: 0
        }
    },
    nombre: {
        textAlign: 'center',
        padding: 5,
        color: properties.white
    },
    titulo: {
        padding: theme.spacing(1),
        color: properties.darkgray,
        fontSize: '1em',
        fontWeight: 700,
        textTransform: 'capitalize',
        textAlign: 'center'
    },
    seeMore: {
        color: properties.secondary,
        float: 'right',
        padding: 5,
        fontSize: 10,
        textTransform: 'uppercase'
    }
}))