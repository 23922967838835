/*Función para limpiar el storage*/
export const clearStorageData = () => {
    localStorage.removeItem("properties");
}

/*función para validar si los valores ingresados en un campos cumplen con el formato asociado */
export const validateValues = (value, type) => {
    let formats = {
        numeric: /^[0-9\b]+$/ /* Sólo números */,
        period: /^([0-9]{1,16})[.]?([0-9]{1,2})*$/ /*Numeros, comas y puntos */,
        text: /^[a-zñáéíóúA-ZÑÁÉÍÓÚ\b]+$/ /* Sólo letras */,
        textWithSpace: /^[\sa-zñA-ZÑ\b]+$/ /* Letras y espacio*/,
        /*email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/  Email ,*/
        alphanumeric: /^[\sa-zñáéíóúA-ZÑÁÉÍÓÚ0-9]+([\s]+[a-zñáéíóúA-ZÑÁÉÍÓÚ0-9]+)*$/ /* Letras y números */,
        password: /^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\d])(?=.*[-+_!@#$%^&*.,?]).*$/, /* mayuscula, minuscula, números y caracter especial con un largo total de 8 */
    }

    let format = formats[type];
    if (type === 'email')
        return value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) == null ? false : true;
    else
        return value === '' || format.test(value) ? true : false;
}

/*Función para dar formato de moneda (Debe llevar el valor com 2 decimales)*/
export const toMoneyFormat = (value) => {

    var credit = value.split(".");
    var creditData = credit[0];
    var length = creditData.length;
    var aux = length / 3;
    aux = Math.ceil(aux);

    var creditArray = [1000];
    var max = length;

    for (let i = 0; i < aux; i++) {
        creditArray[i] = creditData.substring((max - 3), max)
        max = max - 3;
    }

    var j = aux;

    var format = "";
    if (creditArray[j - 1] === "-") {
        format = creditArray[j - 1] + creditArray[j - 2];
        j = j - 1;
        aux = aux - 1;
    } else {
        format = creditArray[j - 1];
    }
    j = j - 2;

    for (let i = 0; i < (aux - 1); i++) {
        format = format + "." + creditArray[j];
        j = j - 1;

    }

    format = format + "," + credit[1];

    return format;
}

export const closeSesion = () => {
    localStorage.clear();
    window.location.href = '/nemesis'
}

export const dateFormat = (date) => {
    var newDate = new Date(date);
    var year = newDate.getFullYear();
    var month = newDate.getMonth() + 1;
    var day = newDate.getDate();
    var dateFormat = day + "/" + (month < 10 ? "0" + month : month) + "/" + year;

    return dateFormat;
}

export const PriceFormat = (value) => {
    return value.replace(/\D/g, "").replace(/([0-9])([0-9]{2})$/, '$1.$2');;
}