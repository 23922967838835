import {
    OPEN_PUCHASE,
    STEP_PURCHASE,
    GET_STATES_LIST,
    GET_CITY_LIST,
    GET_MUNICIPALITIES_LIST,
    GET_PARISHES_LIST,
    GET_SHIPPING_LIST,
    GET_BANKS_LIST,
    GET_PAYMETHOD_LIST,
    GET_DOCUMENTSID_LIST,
    GET_CITY_LIST_BUYPROCESS,
    GET_STATES_LIST_BUYPROCESS,
    GET_MUNICIPALITIES_LIST_BUYPROCESS,
    GET_PARISHES_LIST_BUYPROCESS,
    OPEN_ADD_CAR_MODAL,
    GET_ACCOUNT_LIST,
    SET_CARRITO,
    SET_COTIZAR,
    OPEN_SHOPPINGCAR,
    SET_MEGUSTA,
    SET_GUARDADOS,
    RESERVA,
    SOLICITUD,
    SUMATORIA,
    SET_PDF
} from "../constants";

export const purchase = (state = {}, action) => {
    switch (action.type) {
        case OPEN_PUCHASE:
            return {
                ...state,
                purchaseOpen: action.payload,
                dataShoppingCar: action.dataShoppingCar,
                dataSumatoriaPurchase: action.dataSumatoriaPurchase,
            };
        case STEP_PURCHASE:
            return { ...state, step: action.payload };
        case GET_STATES_LIST:
            return { ...state, statesList: action.data };
        case GET_CITY_LIST:
            return { ...state, cityList: action.data };
        case GET_MUNICIPALITIES_LIST:
            return { ...state, municipalitiesList: action.data };
        case GET_PARISHES_LIST:
            return { ...state, parishesList: action.data };
        case GET_STATES_LIST_BUYPROCESS:
            return { ...state, statesListBuyProcess: action.data };
        case GET_CITY_LIST_BUYPROCESS:
            return { ...state, cityListBuyProcess: action.data };
        case GET_MUNICIPALITIES_LIST_BUYPROCESS:
            return { ...state, municipalitiesListBuyProcess: action.data };
        case GET_PARISHES_LIST_BUYPROCESS:
            return { ...state, parishesListBuyProcess: action.data };
        case GET_SHIPPING_LIST:
            return { ...state, shippingList: action.data };
        case GET_BANKS_LIST:
            return { ...state, banksList: action.data };
        case GET_PAYMETHOD_LIST:
            return { ...state, payMethodList: action.data };
        case GET_DOCUMENTSID_LIST:
            return { ...state, documentsIdList: action.data };
        case OPEN_ADD_CAR_MODAL:
            return {
                ...state,
                openModalAddCar: action.payload,
                productDetails: action.data,
            };
        case SET_COTIZAR:
            return {
                ...state,
                quotationModal: action.payload,
                listaArticulos: action.data,
            };
        case OPEN_SHOPPINGCAR:
            return { ...state, openShoppingCar: action.payload, data: action.data };
        case GET_ACCOUNT_LIST:
            return { ...state, accountList: action.data };
        case SET_CARRITO:
            return { ...state, data: action.data };
        case SET_MEGUSTA:
            return { ...state, meGustaList: action.data };
        case SET_GUARDADOS:
            return { ...state, guardadosList: action.data };
        case RESERVA:
            return {
                ...state,
                dataReserva: action.payload,
                itemsCar: action.data,
                boolPurchase: action.bool,
            };
        case SOLICITUD:
            return { ...state, 
                openSolicitud: action.payload,
                itemsCompraAgotados: action.itemsCompraAgotados,
                sumatoriaCompraAgotados: action.sumatoriaCompraAgotados,
             };
        case SUMATORIA:
            return { ...state, 
                totalSumaCarrito: action.total, 
            };
        case SET_PDF:
            return { ...state, openPdf: action.payload };

        default:
            return state;
    }
};
