// import { Email } from "@material-ui/icons";
import axios from "axios";
import { properties } from "../../../Constants";
import { setAlert } from "../_actionAlerts";
import { setKeyRecovery, setUnlocking } from "../_actionLogin";
import { setLoader } from "../../Actions/_actionLoader";
import { RECOVERY_DATA, ENCRYP_TYPE } from "../../../Constants";
import { setLoginRenew } from "../_actionLoginRenew";
import { DencryptData } from "../../../Tools/CryptoLocalStorage";

//var funcMessage = JSON.parse(localStorage.getItem("funcMessage"));

export const postUnlocking = (EmailPrincipal) => {
  return (dispatch) => {
    dispatch(setLoader(true));
    var code = DencryptData(ENCRYP_TYPE.STRING, RECOVERY_DATA);

    axios({
      method: "POST",
      url: properties.endpoints.unlocking,
      headers: {
        idChannel: properties.idCanal,
        idIdioma: properties.spanish,
        idEmpresa: properties.companyID,
        EmailPrincipal: EmailPrincipal,
        codeConfirmation: code,
      },
    })
      .then((response) => {
        dispatch(setLoader(false));
        dispatch(setUnlocking(false));
        dispatch(setKeyRecovery(true));
        dispatch(
          setAlert(true, response.data.func_msg, properties.severities.success,  properties.props.success)
        );
      })
      .catch((error) => {
        var response = { ...error.response };
        dispatch(setLoader(false));
        dispatch(setLoginRenew(response));

        // var response = { ...error.response};
        // try{
        //     dispatch(setAlert(true, response.data.tecn_msg, properties.severities.warning));

        // }catch(error){
        //     dispatch(setAlert(true, funcMessage.MSG_001, properties.severities.warning))

        // }
      });
  };
};
