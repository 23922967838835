import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Grid,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import formulario from "../../img/formulario.png";

/*ACTIONS*/
import { setKeyRecovery } from "../../../../Redux/Actions/_actionLogin";
import { postRecovery } from "../../../../Redux/Actions/services/postRecovery";
import { setUnlocking } from "../../../../Redux/Actions/_actionLogin";
import { setAlert } from "../../../../Redux/Actions/_actionAlerts";
import { buttomForm } from "../../../../Redux/Actions/_actionLogin";

/*CONSTANTS*/
import { styles } from "./constants";
import { properties } from "../../../../Constants";
const labels = JSON.parse(localStorage.getItem("labels"));


class recovery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      newPassword: "",
      confirm: "",
      boolPassword: false,
      boolConfirm: false,
      buttonDisabled: true,
      passwordMessage: labels.PASSWORD_MESSAGE,
      passwordConfirmation: labels.PASSWORD_CONFIRMATION,

    };
  }

  /*FUNCTIONS*/

  handleClose = () => {
    this.props.setKeyRecovery(false);
  };

  onChangeValues = (event) => {
    var name = event.target.name;
    var value = event.target.value;
    this.setState({
      [name]: value,
    });

    this.validations(name, value);
  };

  validations = (name, value) => {
    switch (name) {
      case "newPassword":
        var patternPassword =
          /^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\d])(?=.*[-+_!@#$%^&*.,?]).*$/;

        if (patternPassword.test(this.state.newPassword)) {
          this.setState({
            boolPassword: false,
          });
        } else {
          this.setState({
            boolPassword: true,
            buttonDisabled: true,
          });
        }
        break;

      case "confirm":
        this.passwordConfirmation(value);

        break;

      default:
        break;
    }
  };


  passwordConfirmation = (confirm) => {
    if (this.state.newPassword === confirm) {
      this.setState({
         boolConfirm: false,
         buttonDisabled: false,
        });
    } else {
      this.setState({ 
        boolConfirm: true,
        buttonDisabled: true,

      });
    }

  };


  integrationRecovery = () => {

    this.props.postRecovery(this.state.newPassword, this.state.code);
    this.setState({ newPassword: "",  confirm: "",  code: "" });
  
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Dialog
          open={this.state.open}
          aria-labelledby="responsive-dialog-title"
          maxWidth={"xs"}
        >
          <div
            style={{
              background: "linear-gradient(to top, #013348 0%, #0585ba 100%)",
              overflow: "hidden",
            }}
          >
            <DialogTitle id="customized-dialog-title">
              <Grid container style={{ color: `${properties.black}` }}>
                <Grid xs={11}>
                  <Typography variant="h6">
                    {" "}
                    {labels.PASSWORD_RECOVERY}{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Button onClick={this.handleClose}>
                    <i
                      style={{ color: `${properties.black}` }}
                      class="icon-guss_close"
                    />
                  </Button>
                </Grid>
              </Grid>
            </DialogTitle>

            <DialogContent style={{ overflow: "hidden" }}>
              <Grid container>
                <Grid item xs={10}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        error={this.state.boolPassword}
                        helperText={
                          this.state.boolPassword
                            ? this.state.passwordMessage
                            : ""
                        }
                        label={
                          <span style={{ color: "white" }}>
                            {labels.NEW_PASSWORD}
                          </span>
                        }
                        name="newPassword"
                        value={this.state.newPassword}
                        className={classes.textColor}
                        onChange={this.onChangeValues}
                        type="password"
                        inputProps={{
                          maxLength: 20,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        error={this.state.boolConfirm}
                        helperText={
                          this.state.boolConfirm ? this.state.passwordConfirmation : ""  }
                        label={ <span style={{ color: "white" }}>{labels.CONFIRMPASSWORD_LB}</span> }
                        name="confirm"
                        value={this.state.confirm}
                        onChange={this.onChangeValues}
                        autoFocus
                        className={classes.textColor}
                        type="password"
                        inputProps={{
                          maxLength: 20,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label={labels.SECURITY_CODE}
                        name="code"
                        value={this.state.code}
                        onChange={this.onChangeValues}
                        autoFocus
                        className={classes.textColor}
                        type="text"
                        inputProps={{
                          maxLength: 12,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="medium"
                        className={classes.button}
                        disabled={this.state.buttonDisabled}
                        onClick={this.integrationRecovery}
                      >
                        {labels.CONTINUE}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={2}>
                  <img
                    src={formulario}
                    alt=""
                    width="80"
                    style={{ marginLeft: 10 }}
                  />
                </Grid>

                <Grid item xs={12} className={classes.Logo}>
                  <i
                    class="icon-guss_logo"
                    alt=""
                    style={{
                      color: `${properties.white}`,
                      fontSize: 20,
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
          </div>
        </Dialog>
      </div>
    );
  }
}

const Recovery = withStyles(styles, { withTheme: true })(recovery);

function mapStateToProps(state) {
  return {
    alert: state.alertReducer.alert,
    buttomPayload: state.loginReducer.buttomPayload,
  };
}

export default connect(mapStateToProps, {
  setKeyRecovery,
  postRecovery,
  setUnlocking,
  setAlert,
  buttomForm,
})(Recovery);
