import { LOGIN_RENEW } from '../constants';
import { setAlert } from './_actionAlerts';
import { properties } from '../../Constants';



export const loginRenewModal = (payload) => {
    return {
        type: LOGIN_RENEW,
        payload: payload,
    };
};

export const setLoginRenew = (response) => {
    return (dispatch) => {

        var funcMessage = JSON.parse(localStorage.getItem("funcMessage"));
        const { status } = response;
        if (status && status === 401) {
            dispatch(loginRenewModal(true))
        } else {
            try {
                dispatch(setAlert(true, response.data.tecn_msg, properties.severities.warning, properties.props.error));
            } catch (error) {
                dispatch(setAlert(true, funcMessage.MSG_001, properties.severities.warning, properties.props.error));
            }
        }
    }
}


