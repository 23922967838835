import { SET_ALERT } from '../constants';
//type : 0= Mensajes funcionales éxito, 1= mensajes funcionales de error
let funcMessage = JSON.parse(localStorage.getItem("funcMessage"));
export const setAlert = (payload, message, severity, props) => {
    return {
        type: SET_ALERT,
        payload: payload,
        data: message,
        severity: severity,
        props: props,
    }
}

export const setAlertError = (error, payload) => {
    var message = "";

    try {
        message = funcMessage[error.funcMessage];
    } catch (error) {
        message = funcMessage.MSG_001;
    }

    return {
        type: SET_ALERT,
        payload: payload,
        data: message
    }
}