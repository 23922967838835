export const styles = (theme) => ({
    contenedor: {
        marginTop: -115,
        [theme.breakpoints.down('xs')]: {
            marginTop: -240,
        },
    },
    arrowBackIcon: {
        marginTop: 'auto',
        marginBottom: 'auto',
        zIndex: '3 !important',
        '& > .MuiIconButton-root:hover': {
            borderRadius: '5px !important',
            zIndex: '3 !important',
        },
        '& > button': {
            padding: 0,
            height: '450px',
            float: 'right',
        }
    },
    arrowForwardIcon: {
        marginTop: 'auto',
        marginBottom: 'auto',
        zIndex: '3 !important',
        '& > .MuiIconButton-root:hover': {
            borderRadius: '5px !important',
            zIndex: '2 !important',
        },
        '& > button': {
            padding: 0,
            height: '450px'
        }
    },
    Search: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: 30, paddingRight: 30
        },
    },
    containerCategories: {
        height: '450px',
        zIndex: 4,
        [theme.breakpoints.up('md')]: {
            backgroundColor: "#ddd7d775",
        },
        [theme.breakpoints.up('sm')]: {
            backgroundColor: "#ddd7d775",
            marginLeft: -30
        },
        [theme.breakpoints.up('xs')]: {
            backgroundColor: "#ddd7d775",
            marginLeft: -5,
        },
    },
    imageStyle: {
        height: '350px',
        width: '320px',
        padding: 15,
        [theme.breakpoints.down('xs')]: {
            height: '300px',
            width: '270px',
            padding: 15,
        },
    },
});
