import {
  SET_OFFERT,
  SET_HOME,
  DATA_APIHOME,
  DATA_APISEARCH,
  MONEDA,
  CHECK_DEVICE,
  OPEN_SEARCH,
  LOAD,
  SEARCH_MOBILE,
  SEE_MORE_CATEGORIES,
  GET_CATEGORIES_LIST
} from '../constants';

export const homeProps = (state = {}, action) => {
  switch (action.type) {
    case SET_OFFERT:
      return {
        ...state,
        getOffert: action.payload,
      };
    case SET_HOME:
      return {
        ...state,
        getHome: action.payload,
      };
    case DATA_APIHOME:
      return {
        ...state,
        getHomeData: action.getHomeData,
      };
    case DATA_APISEARCH:
      return {
        ...state,
        payload: action.payload,
        productsData: action.data,
      }
    case MONEDA:
      return {
        ...state,
        moneda: action.moneda,
      };
    case CHECK_DEVICE:
      return {
        ...state,
        isMobile: action.payload,
      };
    case OPEN_SEARCH:
      return {
        ...state,
        openBarra: action.payload,
      };
    case LOAD:
      return {
        ...state,
        load: action.payload,
      };
    case SEARCH_MOBILE:
      return {
        ...state,
        data: action.data,
      };
    case SEE_MORE_CATEGORIES:
      return {
        ...state,
        seeMore: action.payload,
      };
    case GET_CATEGORIES_LIST:
      return {
        ...state,
        listaCategorias: action.data,
      };
    default:
      return state;
  }
};