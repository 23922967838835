import axios from 'axios';
import { setLoader } from '../_actionLoader';
import { setAlert } from '../_actionAlerts';
import { properties } from '../../../Constants';
import { modalChangePassword } from "../_actionGussta";

export const putChangePassword = (headers) => {
    return dispatch => {
        axios({
            method: "PUT",
            url: properties.endpoints.changePassword,
            headers: headers,
        }).then((response) => {
            const { status, data } = response;
            if (status && status === 200) {
                dispatch(setLoader(false))
                dispatch(setAlert(true, data.func_msg, properties.severities.success, properties.props.success));
                dispatch(modalChangePassword(false))
            }
        }).catch((error) => {
            var response = { ...error.response };
            console.log(response)
            dispatch(setLoader(false));
            dispatch(setAlert(true, response.data.func_msg, properties.severities.success, properties.props.error));
        });
    }
}