// import { PermScanWifiTwoTone } from '@material-ui/icons';
import axios from 'axios';
import { properties } from '../../../Constants';
import { setLoader } from '../_actionLoader'
import { setAlert } from '../_actionAlerts';
import { postLogin } from './postLogin';

var funcMessage = JSON.parse(localStorage.getItem("funcMessage"));


export const postUser = (body) => {

    return (dispatch) => {

        dispatch(setLoader(true))
        axios({
            method: "POST",
            url: properties.endpoints.user,
            headers: {
                idChannel: properties.idCanal
            },
            data: body,
        }).then((response) => {

            dispatch(setLoader(false))
                dispatch(setAlert(true, response.data.func_msg, properties.severities.success,  properties.props.success))


            if (response.status === 200) {
                let headers = {
                    idCanal: properties.idCanal,
                    idEmpresa: properties.companyID,
                    correo: body.emailPrincipal,
                    password: body.password,
                };

                dispatch(postLogin(headers))
            }

        }).catch((error) => {
            console.log(error)
            var response = { ...error.response };
            dispatch(setLoader(false))
            try {
                dispatch(setAlert(true, response.data.func_msg, properties.severities.warning, properties.props.error));

            } catch (error) {
                dispatch(setAlert(true, funcMessage.MSG_001, properties.severities.warning, properties.props.error));

            }

        })
    }

}

