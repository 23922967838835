import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid, TextField, MenuItem, Accordion, AccordionSummary, AccordionDetails, Typography, Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ClearIcon from '@material-ui/icons/Clear';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

/*Actions */
import { stepPurchase } from '../../../../Redux/Actions/_actionShoppingCar';

/*Components */
import Buttons from '../Buttons';

//Constants
import { properties } from '../../../../Constants';
import { styles } from "../constants.js";
import factura from '../img/payment.png'
import { validateValues, toMoneyFormat, PriceFormat } from '../../../../Tools/index';

const labels = JSON.parse(localStorage.getItem("labels"));

export class Payment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            typePayment: '',
            pagoItems: null,
            required: labels.REQUIRED,
            emailMessageError: labels.EMAIL_FORMAT,
            constTextField: [],
            boolTextField: [],
            tablePaysData: [],
            errorAmountAdded: false,
            errorLowerAmount: false,
        }
    }

    componentDidMount = () => {
        if (JSON.parse(localStorage.getItem('Payment')) !== null) {
            let Payment = JSON.parse(localStorage.getItem('Payment'))
            this.setState({ typePayment: Payment.typePayment, tablePaysData: Payment.tablePaysData })

            let constTextField = []
            let boolTextField = []
            this.props.payMethodList.forEach((element, i) => {
                if (element.idMetodoPago === Payment.typePayment) {
                    element.pagoItems.forEach((value, index) => {
                        constTextField[index] = value.idPagoItems;
                        boolTextField[index] = `Bool${value.idPagoItems}`;
                        this.setState({
                            [value.idPagoItems]: '',
                            [`Bool${value.idPagoItems}`]: false
                        })
                    });
                    this.setState({ pagoItems: element.pagoItems, constTextField: constTextField, boolTextField: boolTextField })
                }
            });

            constTextField.forEach((element, i) => {
                this.setState({ [constTextField[i]]: Payment[element] })
            });
        }
    }

    componentWillUnmount = () => {
        let Payment = {}
        Payment.typePayment = this.state.typePayment
        Payment.tablePaysData = this.state.tablePaysData
        this.state.constTextField.forEach((element, i) => {
            Payment[this.state.constTextField[i]] = this.state[this.state.constTextField[i]]
        });
        localStorage.setItem('Payment', JSON.stringify(Payment))
    }

    handleContinue = () => {
        this.validationForm(this.state.typePayment, "", 'BooltypePayment', true);
        /**Bucle para pintar alerta en inputs vacío**/
        for (let i = 0; i < this.state.constTextField.length; i++) {
            let textField = this.state.constTextField[i]
            this.validationForm(this.state[textField], "", this.state.boolTextField[i], true);
        }

        /**Bucle para validar cuántos inputs están llenos**/
        let cont = 0;
        for (var u = 0; u < this.state.constTextField.length; u++) {
            let textField1 = this.state.constTextField[u]
            if (this.state[textField1] !== '' && this.state[textField1] !== undefined && this.state[textField1] !== null && this.state[this.state.boolTextField[u]] === false) {
                let bool = true;
                if (bool === true) {
                    cont = cont + 1;
                }
            }
        }
        if (cont === this.state.boolTextField.length && this.state.typePayment !== '') {
            return true
        }
    }

    handleBack = () => {
        this.props.stepPurchase(1);
    }

    handleChange = (event, idPagoItems) => {
        let type
        const { value, name } = event.target;

        switch (idPagoItems) {
            case 'PI-002': //Tipo de dato número (campo titular)
                type = 'textWithSpace'
                break;
            case 'PI-004': //Tipo de dato número (campo documento identificación)
                type = 'numeric'
                break;
            case 'PI-005': //Tipo de dato número (campo monto)
                type = 'period'
                break;
            case 'PI-006': //Tipo de dato número (campo referencia)
                type = 'numeric'
                break;
            case 'PI-007': //Tipo de dato email (campo correo electronico)
                type = 'email'
                let bool = validateValues(value, type)
                this.setState({ [name]: value, ['Bool' + name]: !bool });
                break;
            default:
                break;
        }

        if (type === 'period') { //Da formato al número sin escribir los caracteres punto o coma. 
            let bool2 = PriceFormat(value)
            this.setState({ [name]: bool2 });
        } else {
            let bool = validateValues(value, type)
            if (bool) {
                this.setState({ [name]: value, ['Bool' + name]: !bool });
            }
        }
    };

    handleChangeCombos = (event) => {
        this.setState({ [event.target.name]: event.target.value, errorAmountAdded: false, errorAmountMinor: false });

        for (var i = 0; i < this.state.constTextField.length; i++) {
            this.validationForm(event.target.name, this.state.constTextField[i], this.state.boolTextField[i], false);
        }
    };

    /** 
     * Controla el select "typePayment" para que a raíz de su cambio, sean declarados los estados
     *  y los booleanos que serán necesarios para la validación de los campos.
    */
    handleStatesInputs = (event) => {
        this.setState({ [event.target.name]: event.target.value });
        this.validationForm(event.target.name, "typePayment", "BooltypePayment", false);

        this.props.payMethodList.forEach((element, i) => {
            if (element.idMetodoPago === event.target.value) {

                let constTextField = []
                let boolTextField = []

                element.pagoItems.forEach((value, index) => {
                    constTextField[index] = value.idPagoItems;
                    boolTextField[index] = `Bool${value.idPagoItems}`;
                    this.setState({
                        [value.idPagoItems]: '',
                        [`Bool${value.idPagoItems}`]: false
                    })
                });

                this.setState({ pagoItems: element.pagoItems, constTextField: constTextField, boolTextField: boolTextField })
            }
        });
    }

    /**-Validación de inputs esta función se llama al momento de darle click a "continuar" y haya algún campo vacío (se pinta error)
    * -En caso de que sea tipeado algo, se borrará el error pintado**/
    validationForm = (state, value, boolean, valueState) => {
        if (state === value) {
            this.setState({
                [boolean]: valueState,
            });
        }
    }

    /**
     * Función que envía al componente padre (PurchaseProcess) el resultado de la resta del total del monto. Va descontando cada pago
     * A su vez, también va actualizando la lista de los diferentes pagos que se van registrando.
     * **/
    finishPay = () => {
        const validation = this.handleContinue()
        let amount = this.state['PI-005'].replace(",", ".")
        let sumatory = toMoneyFormat(this.props.dataSumatoriaPurchase.toFixed(2)).replace(".", "").replace(",", ".")

        if (validation) {
            if (parseFloat(amount) <= sumatory) {

                let tablePaysData = [...this.state.tablePaysData]
                this.props.payMethodList.forEach((element) => {
                    if (element.idMetodoPago === this.state.typePayment) {
                        tablePaysData.push({
                            idMetodoPago: element.idMetodoPago,
                            method: element.descripcion,
                            amount: amount,
                            attr: {}
                        })
                    }
                    tablePaysData.forEach((value, i) => {
                        if (element.idMetodoPago === value.idMetodoPago) {
                            element.pagoItems.forEach(val => {
                                value.attr[val.idPagoItems] = this.state[val.idPagoItems];
                            });
                        };
                    });
                });

                this.props.calculateTotalAmount(sumatory - amount)
                this.setState({ typePayment: '', pagoItems: null, tablePaysData: tablePaysData })
            } else {
                this.setState({ errorAmountAdded: true, errorLowerAmount: false })
            }
        }
    }

    /**
     * Función que elimina un pago registrado
     * @param {*int} amount monto del pago que se quiere eliminar
     * @param {*int} i index del arreglo de los pagos registrados
     */
    rollBackPay = (amount, i) => {
        let amountReplace = amount.replace(",", ".")
        let tablePaysData = [...this.state.tablePaysData]
        tablePaysData.splice(i, 1);
        this.setState({ tablePaysData: tablePaysData })
        this.props.calculateTotalAmount(this.props.dataSumatoriaPurchase + parseFloat(amountReplace))
    }

    handleNext = () => {
        var monto = 0;
        if (this.props.dataSumatoriaPurchase === 0) {
            this.props.stepPurchase(3);
            this.setState({
                errorLowerAmount: false
            })
        } else {
            if (this.state.tablePaysData.length > 0) {
                this.state.tablePaysData.map(data => {
                    monto += parseInt(data.amount)
                })
            } else {
                return this.setState({
                    errorLowerAmount: true
                })
            }
            if (monto < this.props.amountTotal) {
                return this.setState({
                    errorLowerAmount: true, errorAmountAdded: false,
                })
            }
        }
    }

    render() {
        const { typePayment, required, emailMessageError, BooltypePayment } = this.state;
        const { classes } = this.props;
        var payment = labels.PAYMENT_METHOD

        return (
            <Grid container style={{ marginTop: 20, height: '100%' }} >
                <Grid item md={12}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Grid container>
                                <Grid item xs={12} md={12} className={classes.paddingContainer}>
                                    <Accordion
                                        className={classes.Accordion}
                                        disableElevation
                                        square='true'>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className={classes.heading}>{labels.PAYMENT}</Typography>
                                        </AccordionSummary>
                                        {this.props.accountList && this.props.accountList.map(element => (
                                            <>
                                                <AccordionDetails>
                                                    <Grid container>
                                                        <Grid md={4} className={classes.alignItemsCenter}>
                                                            <img src={element.url}
                                                                alt='img'
                                                                style={{
                                                                    width: "100%",
                                                                    objectFit: "contain",
                                                                }} />
                                                        </Grid>
                                                        <Grid md={8}>
                                                            <Grid container>
                                                                <Grid md={12} >
                                                                    <Typography variant='body2'><b>{payment.ACCOUNT}:</b> {element.numeroCuenta}</Typography>
                                                                </Grid>
                                                                <Grid md={12} >
                                                                    <Typography variant='body2'><b>{payment.TYPE_ACCOUNT}:</b> {element.attr1}</Typography>
                                                                </Grid>
                                                                <Grid md={12} >
                                                                    <Typography variant='body2'><b>{payment.TITULAR}:</b> {element.titular}</Typography>
                                                                </Grid>
                                                                <Grid md={12} >
                                                                    <Typography variant='body2'><b>{payment.DOC_IDENT}:</b> {element.idTipoDocumento}-{element.documento}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </>
                                        ))
                                        }
                                    </Accordion>
                                </Grid>
                                {this.state.tablePaysData.length > 0 &&
                                    <Grid item xs={12} md={12} style={{ marginTop: 20 }} className={classes.paddingContainer}>
                                        <TableContainer>
                                            <Table className={classes.table} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell padding='none' align="center"><strong>Método</strong></TableCell>
                                                        <TableCell padding='none' align="center"><strong>Monto</strong></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.tablePaysData.map((element, i) => (
                                                        <TableRow key={i}>
                                                            <TableCell padding='none' align="center">{element.method}</TableCell>
                                                            <TableCell padding='none' align="center">{element.amount}</TableCell>
                                                            <TableCell padding='none' align="center"><ClearIcon onClick={() => this.rollBackPay(element.amount, i)} style={{ fontSize: 15, cursor: 'pointer' }}></ClearIcon></TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                }
                                {this.props.dataSumatoriaPurchase > 0 &&
                                    <Grid item xs={12} md={12} style={{ marginTop: "2vh" }} className={classes.paddingContainer}>
                                        <Grid container>
                                            <Grid item xs={12} md={12} sm={12}  style={{paddingRight: 10}}>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    error={BooltypePayment}
                                                    helperText={
                                                        BooltypePayment
                                                            ? required
                                                            : ""
                                                    }
                                                    label={labels.TYPE_PAYMENT}
                                                    value={typePayment}
                                                    name='typePayment'
                                                    onChange={this.handleStatesInputs}
                                                >
                                                    {this.props.payMethodList && this.props.payMethodList.map((option) => (
                                                        <MenuItem key={option.idMetodoPago} value={option.idMetodoPago}>
                                                            {option.descripcion}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                            {this.state.pagoItems !== null &&
                                                <>
                                                    {this.state.pagoItems.map((element) => (
                                                        <>
                                                            {Array.isArray(element.lista) ?
                                                                <>
                                                                    <Grid item xs={12} md={6} sm={6} style={{paddingRight: 10}}>
                                                                        <TextField
                                                                            select
                                                                            fullWidth
                                                                            error={this.state[`Bool${element.idPagoItems}`]}
                                                                            helperText={
                                                                                this.state[`Bool${element.idPagoItems}`]
                                                                                    ? required
                                                                                    : ""
                                                                            }
                                                                            label={element.descPagoItem}
                                                                            name={element.idPagoItems}
                                                                            value={this.state[element.idPagoItems]}
                                                                            onChange={this.handleChangeCombos}
                                                                        >
                                                                            {element.lista.map((option) => (
                                                                                <MenuItem key={option.id} value={option.id}>
                                                                                    {option.descripcion}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </TextField>
                                                                    </Grid>

                                                                </>
                                                                :
                                                                <>
                                                                    {element.idPagoItems === 'PI-007' ? //El campo PI-007 es el campo correo electrócino
                                                                        <Grid item xs={12} md={6} sm={6} style={{paddingRight: 10}}>
                                                                            <TextField

                                                                                fullWidth

                                                                                error={this.state[`Bool${element.idPagoItems}`]}
                                                                                helperText={
                                                                                    this.state[`Bool${element.idPagoItems}`]
                                                                                        ? emailMessageError
                                                                                        : ""
                                                                                }
                                                                                label={element.descPagoItem}
                                                                                name={element.idPagoItems}
                                                                                value={this.state[element.idPagoItems]}
                                                                                onChange={(event) => this.handleChange(event, element.idPagoItems)}
                                                                                inputProps={{
                                                                                    maxLength: 40,
                                                                                }}
                                                                            >
                                                                            </TextField>
                                                                        </Grid>
                                                                        :
                                                                        <Grid item xs={12} md={6} sm={6} style={{paddingRight: 10}}>
                                                                            <TextField

                                                                                fullWidth
                                                                                error={this.state[`Bool${element.idPagoItems}`]}
                                                                                helperText={
                                                                                    this.state[`Bool${element.idPagoItems}`]
                                                                                        ? required
                                                                                        : ""
                                                                                }
                                                                                label={element.descPagoItem}
                                                                                name={element.idPagoItems}
                                                                                value={this.state[element.idPagoItems]}
                                                                                onChange={(event) => this.handleChange(event, element.idPagoItems)}
                                                                                inputProps={{
                                                                                    maxLength: 20,
                                                                                }}
                                                                            >
                                                                            </TextField>
                                                                        </Grid>
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    ))
                                                    }
                                                </>
                                            }
                                        </Grid>
                                        {this.state.typePayment !== '' &&
                                            <div style={{ marginTop: 30 }}>
                                                {this.props.dataSumatoriaPurchase > 0 &&
                                                    <center>
                                                        <Button
                                                            variant="outlined"
                                                            style={{ backgroundColor: properties.primary, borderRadius: 0 }}
                                                            onClick={() => this.finishPay()}
                                                        >
                                                            <span style={{ color: properties.white }}>Finalizar pago</span>
                                                        </Button>
                                                    </center>
                                                }
                                                {this.state.errorAmountAdded &&
                                                    <center>
                                                        <strong style={{ color: 'red' }}><small>El monto no puede ser mayor al total</small></strong>
                                                    </center>
                                                }
                                            </div>
                                        }
                                        {this.state.errorLowerAmount &&
                                            <center>
                                                <strong style={{ color: 'red' }}><small>El monto pagado no puede ser menor al total</small></strong>
                                            </center>
                                        }
                                        {/* </Grid>
                                        </Grid> */}
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        <Grid item md={6} className={classes.hiddenMobile}>
                            <img
                                className={classes.paddingContainer}
                                src={factura}
                                alt=""
                                style={{
                                    width: "100%",
                                    objectFit: "contain",
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid className={classes.next} xs={12} md={12}>
                        <Buttons
                            handleBack={this.handleBack}
                            handleNext={this.handleNext}
                        />
                    </Grid>
                </Grid>
            </Grid >
        )
    }
}

const payment = withStyles(styles, { withTheme: true })(Payment)

const mapStateToProps = (state) => ({
    banksList: state.purchase.banksList,
    payMethodList: state.purchase.payMethodList,
    accountList: state.purchase.accountList,
})

const mapDispatchToProps = {
    stepPurchase
}

export default connect(mapStateToProps, mapDispatchToProps)(payment)