import { properties } from "../../Constants";

export const styles = (theme) => ({
  header: {
    height: "10vh",
    objectFit: "cover",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      height: "8vh !important",
    },
  },
  titulo:{
    color: properties.darkGray,
    fontSize: '30px',
    fontWeight: '500',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2 em',
    },
  },
  guardado:{
    borderLeft: '1px solid ' + properties.gray,
    padding: theme.spacing(2),
  },
  meGusta:{
    padding: theme.spacing(2)
  },
  imagen:{
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  content:{
    [theme.breakpoints.down('sm')]: {
      padding: 0
    },
  }
});