import Routes from './Routes';
import './App.css';


function App() {
  return (
    <Routes />
  
  );
}

export default App;
