import { LOGIN_RENEW } from '../constants';


export const loginRenewReducer = (state = {}, action ) => {
    switch(action.type){
        case LOGIN_RENEW:
            return{
                ...state,
                modalLoginRenew: action.payload,
            };
            default:
                return state;
    }
} 