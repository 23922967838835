import { properties } from "../../../Constants";

export const styles = (theme) => ({
    tituloClose: {
        marginTop: "auto",
        marginBottom: "auto",
    },
    closeModal: {
        cursor: 'pointer',
        fontSize: 'large',
        float: "right"
    },
    precio: {
        color: "white",
        opacity: "0.7",
        padding: "16px 24px",
        background: "linear-gradient(to left, #f8f7f2, #0d1544)"
    },
    dialogContent: {
        padding: 0
    },
    seleccionarColor: {
        padding: "32px 24px 0px 24px;",
    },
    squareColor: {
        border: "1px solid black",
        width: "50px",
        height: "50px",
        margin: "0px 10px",
        borderRadius: "50%"
    },
    squareColorSelected: {
        border: "1px solid black",
        width: "48px",
        height: "48px",
        margin: "0px 10px",
        borderRadius: "50%",
        boxShadow: "0px 0px 6px 8px rgb(0 0 0 / 20%)"
    },
    seleccionarCantidad: {
        padding: "24px 24px",
    },
    iconArrow: {
        color: properties.primary,
        fontSize: '20px',
        fontWeight: 'lighter'
    },
    input: {
        width: '65px',
        borderRadius: '0px',
        color: properties.darkGray,
        "& > div > input": {
            textAlign: 'center',
        }
    },
    cantidad:{
        textAlign: 'center'
    },
    border:{
        borderTop: "1px solid"+properties.darkGray
    },
    seccionButtons:{
        textAlign:"right"
    },
    buttonAccept:{
        backgroundColor: properties.primary, 
        borderRadius: 0, 
        margin: theme.spacing(2)
    },
    buttonCancel:{
        borderRadius: 0, 
        margin: theme.spacing(2),
    }
})