import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Typography, withStyles, CircularProgress, Backdrop } from "@material-ui/core";
import Header from "../Header";
import CarritoProductos from "./CarritoProductos";
import ArticulosGuardados from "./ArticulosGuardados";
import MeGusta from "./MeGusta";
import PurchaseProcess from "./PurchaseProcess";
import QuotationProcess from "./QuotationProcess";
import RequestOrder from "./RequestOrder";

/*ACTIONS*/
import { setCotizar } from "../../Redux/Actions/_actionShoppingCar";
import { setHeaderProps } from "../../Redux/Actions/_actionHeader";
import { getCarrito } from "../../Redux/Actions/services/getCarrito";
import { setLoader } from "../../Redux/Actions/_actionLoader";

import { properties } from "../../Constants";
import { styles } from "./constants.js";

class ShoppingCar extends Component {
  componentDidMount() {
    this.props.setHeaderProps(properties.gray, "black");
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        {/* Header */}
        <div className={classes.header}>
          <Header />
        </div>

        <Grid container spacing={0}>
          {/* Sección Carrito de Compras */}
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <Typography variant="h5" className={classes.titulo} style={{ paddingLeft: '8px' }}>
              Carrito de compras
            </Typography>
            {this.props.carritoList && <CarritoProductos />}
          </Grid>

          {/* Sección Artículos Guardados */}
          <Grid item xs={12} md={4} lg={4} xl={4} className={classes.guardado}>
            <Typography variant="h5" className={classes.titulo}>
              Artículos guardados
            </Typography>
            {this.props.guardadosList && <ArticulosGuardados />}
          </Grid>

          {/* Sección Me Gusta */}
          <Grid item md={12} lg={12} xl={12} className={classes.meGusta}>
            <Typography variant="h5" className={classes.titulo}>
              Me gusta
            </Typography>
            {this.props.meGustaList && <MeGusta />}
          </Grid>
        </Grid>

        {this.props.purchaseOpen && <PurchaseProcess />}
        {this.props.quotationModal && <QuotationProcess />}
        {this.props.openSolicitud && <RequestOrder />}
        <Backdrop
          open={this.props.loader}
          style={{ zIndex: 9999999, color: properties.primary }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }
}

const shoppingCar = withStyles(styles, { withTheme: true })(ShoppingCar);

function mapStateToProps(state) {
  return {
    purchaseOpen: state.purchase.purchaseOpen,
    quotationModal: state.purchase.quotationModal,
    carritoList: state.purchase.data,
    meGustaList: state.purchase.meGustaList,
    guardadosList: state.purchase.guardadosList,
    loader: state.loaderReducer.loader,
    openSolicitud: state.purchase.openSolicitud,
  };
}

export default connect(mapStateToProps, {
  setHeaderProps,
  setCotizar,
  getCarrito,
  setLoader,
})(shoppingCar);
