import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { styles } from './constants';

/*Components */
import SearchProducts from './SearchProducts';

class ProductsData extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <SearchProducts />
        );
    }
}

const Products = withStyles(styles, { withTheme: true })(ProductsData)

export default Products;