import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { makeStyles, Avatar } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ClearIcon from "@material-ui/icons/Clear";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";

/*CONSTANTS*/
import logo from "../../Home/img/logo.png";
import { properties, LOGIN_DATA_LS, ENCRYP_TYPE } from "../../../Constants";

/*ACTIONS*/
import { modalPedidosDetails } from "../../../Redux/Actions/_actionGussta";
import { DencryptData } from "../../../Tools/CryptoLocalStorage";
import { setLoader } from "../../../Redux/Actions/_actionLoader";
import { postInventarioReserva } from "../../../Redux/Actions/services/postInventarioReserva";

const useStyles = makeStyles((theme) => ({
    iconClose: {
        [theme.breakpoints.up("md")]: {
            position: "fixed",
            marginLeft: 425,
            marginTop: -20,
        },
        [theme.breakpoints.down("sm")]: {
            position: "fixed",
            marginTop: -20,
            marginLeft: 425,
        },

        [theme.breakpoints.down("xs")]: {
            position: "fixed",
            marginLeft: "74%",
            marginTop: -40,
        },
    },
    closeModal: {
        color: properties.white,
        cursor: "pointer",
    },
    titlePadding: {
        backgroundColor: properties.white,
    },
    logo: {
        width: "120px",
        marginTop: "5vh",
    },
    strong: {
        fontWeight: "bold",
        color: properties.colorTitle,
    },
    button: {
        color: properties.colorTitle,
        fontWeight: "bold",
    },
    buttonGroup: {
        backgroundColor: properties.white,
        overflow: "hidden",
        borderRadius: "0vh 0vh 0vh 0vh",
    },
    footerImage: {
        [theme.breakpoints.up("md")]: {
            position: "fixed",
            height: 150,
            width: 155,
            marginLeft: -250,
        },
        [theme.breakpoints.down("sm")]: {
            position: "fixed",
            height: 150,
            width: 155,
            marginLeft: -250,
        },

        [theme.breakpoints.down("xs")]: {
            position: "fixed",
            height: 100,
            width: 85,
            marginLeft: -170,
        },
    },
    gradient: {
        background: "linear-gradient(80deg, #cbe452 20%, #77c801 50%, #77c801 90%)",
        padding: 30,
    },

    box: {
        background: properties.grayLight,
        borderRadius: "0vh",
        boxSizing: "border-box",
        overflow: "auto",
        "&::-webkit-scrollbar": {
            padding: "5em",
            margin: "1em",
            width: "0.2em",
            background: properties.white,
            height: "50%",
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 100px white",
            webkitBoxShadow: "inset 0 0 6px black",
            height: "200px",
        },
        "&::-webkit-scrollbar-thumb": {
            // backgroundColor: "#aeb3ed",
            backgroundColor: properties.primary,
        },
        [theme.breakpoints.up("md")]: {
            padding: "5%",
            height: 100,
            width: 550,
        },
        [theme.breakpoints.down("sm")]: {
            padding: "3%",
            height: 170,
            width: 550,
        },

        [theme.breakpoints.down("xs")]: {
            padding: "2%",
            height: 350,
            width: 250,
        },
    },

    boxTrama: {
        background: properties.grayLight,
        borderRadius: "0vh",
        boxSizing: "border-box",
        overflow: "auto",
        "&::-webkit-scrollbar": {
            padding: "5em",
            margin: "1em",
            width: "0.2em",
            background: "white",
            height: "50%",
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 100px white",
            webkitBoxShadow: "inset 0 0 6px black",
            height: "200px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: properties.secondaryLight,
        },
        [theme.breakpoints.up("md")]: {
            padding: "5%",
            height: 100,
            width: 550,
        },
        [theme.breakpoints.down("sm")]: {
            padding: "3%",
            height: 170,
            width: 550,
        },

        [theme.breakpoints.down("xs")]: {
            padding: "2%",
            height: 350,
            width: 250,
        },
    },

    medio: {
        padding: "40%",
    },

    accordion: {
        padding: 2,
    },
    accordionColor: {
        background: properties.grayLight,
        borderRadius: "0vh",
        marginTop: 5,
    },
    subtitle: {
        marginLeft: "40vh",
    },

    card: {
        background: properties.white,
        "&::-webkit-scrollbar": {
            padding: "0.4em",
            margin: "1em",
            width: "0.2em",
            background: properties.white,
            height: "50%",
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 100px white",
            webkitBoxShadow: "inset 0 0 6px black",
            height: "200px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: properties.secondary,
        },
    },
}));

export default function PedidosDetails() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [totalAmount, settotalAmount] = useState(0);
    const openModal = useSelector((state) => state.gusstaReducer.showModalPedidosDetails);
    const idEstatusPedidos = useSelector((state) => state.gusstaReducer.idEstatusPedidos);
    const pedidoArray = useSelector((state) => state.gusstaReducer.orderSearch);
    const persona = useSelector((state) => state.gusstaReducer.personRequest);
    const direccion = useSelector((state) => state.gusstaReducer.requestAddress);
    const articulo = useSelector((state) => state.gusstaReducer.ordersArticle);

    /*FUNCTIONS*/

    useEffect(() => {
        var total = 0;
        if (articulo) {
            articulo.map((obj) => {
                total = total + (obj.precioUnitario * obj.cantidad);
            });
        }
        settotalAmount(total);
    }, [articulo]);

    const handleRight = () => {
        dispatch(setLoader(true));
        localStorage.removeItem("Order");
        localStorage.removeItem("Payment");
        const loginDataStorage = DencryptData(ENCRYP_TYPE.JSON, LOGIN_DATA_LS);
        const { usuario } = loginDataStorage;
        const { idUsuario } = usuario;

        let listaArticulos = [];
        articulo.forEach((data) => {
            data.colorTrama = data.idColor
            listaArticulos.push({
                v_IdArticulo: data.idArticulo,
                v_IdColor: data.colorTrama,
                v_Cantidad: data.cantidad,
            });
        });

        let body = {
            IdEmpresa: properties.companyID,
            IdUsuario: idUsuario,
            listArticulos: listaArticulos,
            Usuario: idUsuario,
        };

        dispatch(postInventarioReserva(body, articulo, totalAmount));
        dispatch(modalPedidosDetails(false));
    };

    const handleClose = () => {
        dispatch(modalPedidosDetails(false));
    };
    
    return (
        <Dialog
            open={openModal}
            onClose={handleClose}
            maxWidth={"xs"}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            PaperProps={{
                style: {
                    borderRadius: "0vh",
                },
            }}
        >
            <Grid container className={classes.iconClose}>
                <DialogTitle id="scroll-dialog-title" onClick={handleClose}>
                    <strong className={classes.closeModal}>
                        <ClearIcon></ClearIcon>
                    </strong>
                </DialogTitle>
            </Grid>

            <Grid container className={classes.titlePadding}>
                <Grid md={1} xs={12} sm={1} />
                <Grid md={10} xs={12} sm={10} style={{ marginTop: 10 }}></Grid>
                <Grid md={1} xs={12} sm={1} />
                <br />
                <br />
                <Grid md={12} xs={12} sm={12} container justify="center">
                    <Typography variant="h5" className={classes.strong}>
                        Resumen del pedido
                    </Typography>
                </Grid>
            </Grid>

            <DialogContent className={classes.card}>
                <Grid container style={{ padding: 20, marginTop: -20 }}>
                    {pedidoArray &&
                        pedidoArray.map((obj) => (
                            <Grid item xs={12} sm={12}>
                                <Typography variant="subtitle1">
                                    <strong>Número pedido: </strong>
                                    {obj.idPedido}{" "}
                                </Typography>
                                <Typography variant="subtitle1">
                                    <strong>Estatus: </strong>
                                    {obj.descripcionEstatus}
                                </Typography>
                                <Typography variant="subtitle1">
                                    <strong>Tipo: </strong>
                                    {obj.desTipoPedido}
                                </Typography>
                                <Typography variant="subtitle1">
                                    <strong>Monto total: </strong>
                                    {totalAmount + "$"}
                                </Typography>
                            </Grid>
                        ))}

                    <Grid item xs={12}>
                        <br />
                    </Grid>

                    <Grid
                        item
                        md={12}
                        xs={12}
                        sm={12}
                        container
                        className={classes.accordion}
                    >
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className={classes.accordionColor}
                            >
                                <Typography> Datos cliente</Typography>
                            </AccordionSummary>

                            {persona &&
                                persona.map((element) => (
                                    <AccordionDetails>
                                        <Paper
                                            elevation={0}
                                            style={{ background: "#f7f7f7", padding: 10 }}
                                        >
                                            <Grid container spacing={2}>
                                                {element.nombre === null ?
                                                    <>
                                                        <Grid item xs={12} md={12} sm={12}>
                                                            <TextField
                                                                label="Razón social"
                                                                value={element.razonSocial}
                                                                disabled
                                                            />
                                                        </Grid>
                                                    </>
                                                    :
                                                    <>
                                                        <Grid item xs={6} md={6} sm={6}>
                                                            <TextField
                                                                label="Nombre"
                                                                value={element.nombre}
                                                                disabled
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                label="Apellido"
                                                                value={element.apellido}
                                                                disabled
                                                            />
                                                        </Grid>
                                                    </>
                                                }
                                                <Grid item xs={6}>
                                                    <TextField
                                                        label="Documento"
                                                        value={element.idTipoIdExterno + "-" + element.documento}
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        label="correo"
                                                        value={element.emailPrincipal}
                                                        disabled
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </AccordionDetails>
                                ))}
                        </Accordion>
                    </Grid>

                    {direccion !== null ? (
                        <Grid
                            md={12}
                            xs={12}
                            sm={12}
                            container
                            className={classes.accordion}
                        >
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    className={classes.accordionColor}
                                >
                                    <Typography> Datos envío</Typography>
                                </AccordionSummary>
                                {direccion &&
                                    direccion.map((inf) => (
                                        <AccordionDetails>
                                            <Paper
                                                elevation={0}
                                                style={{ background: "#f7f7f7", padding: 10 }}
                                            >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label="Estado"
                                                            name="name"
                                                            value={inf.estado}
                                                            readOnly
                                                            disabled
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label="Ciudad"
                                                            name="name"
                                                            value={inf.ciudad}
                                                            readOnly
                                                            disabled
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label="Municipio"
                                                            value={inf.municipio}
                                                            readOnly
                                                            disabled
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label="Parroquia"
                                                            value={inf.parroquia}
                                                            readOnly
                                                            disabled
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            label="Direccion"
                                                            value={inf.direccion}
                                                            readOnly
                                                            disabled
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </AccordionDetails>
                                    ))}
                            </Accordion>
                        </Grid>
                    ) : (
                        <></>
                    )}

                    {/* {pago !== null ? (
                        <Grid
                            md={12}
                            xs={12}
                            sm={12}
                            container
                            className={classes.accordion}
                        >
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    className={classes.accordionColor}
                                >
                                    <Typography> Datos pago </Typography>
                                </AccordionSummary>

                                {payMethod &&
                                    payMethod.map((obj) => (
                                        <AccordionDetails>
                                            <Paper
                                                elevation={0}
                                                style={{ background: "#f7f7f7", padding: 10 }}
                                            >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={12} sm={12}>
                                                        <TextField
                                                            label="Método"
                                                            name="name"
                                                            fullWidth
                                                            value={obj.desMetodoPago}
                                                            readOnly
                                                            disabled
                                                        />
                                                    </Grid>
                                                    {obj.items.map((item) => (
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                label={item.label}
                                                                fullWidth
                                                                value={item.value}
                                                                readOnly
                                                                disabled
                                                            />
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </Paper>
                                        </AccordionDetails>
                                    ))}
                            </Accordion>
                        </Grid>
                    ) : (
                        <></>
                    )} */}

                    {articulo !== null ? (
                        <Grid
                            md={12}
                            xs={12}
                            sm={12}
                            container
                            className={classes.accordion}
                        >
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    className={classes.accordionColor}
                                >
                                    <Typography> Artículos </Typography>
                                </AccordionSummary>

                                {articulo &&
                                    articulo.map((obj) => (
                                        <AccordionDetails>
                                            <Paper
                                                elevation={0}
                                                style={{ background: "#f7f7f7", padding: 10 }}
                                            >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={12} sm={12}>
                                                        <TextField
                                                            label="Artículo"
                                                            name="name"
                                                            fullWidth
                                                            value={obj.desCortaArticulo}
                                                            readOnly
                                                            disabled
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography>Color</Typography>
                                                        {obj.hexadecimal !== null ? (
                                                            <Avatar
                                                                alt="."
                                                                style={{
                                                                    backgroundColor: obj.hexadecimal,
                                                                    color: obj.hexadecimal,
                                                                    width: 25,
                                                                    height: 25,
                                                                    border: "1px solid #00000047",
                                                                }}
                                                            ></Avatar>
                                                        ) : (
                                                            <Avatar
                                                                alt="."
                                                                src={obj.url}
                                                                style={{
                                                                    width: 25,
                                                                    height: 25,
                                                                    // margin: "auto",
                                                                    border: "1px solid #00000047",
                                                                }}
                                                            ></Avatar>
                                                        )}
                                                    </Grid>

                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label="Cantidad"
                                                            name="name"
                                                            fullWidth
                                                            value={obj.cantidad}
                                                            readOnly
                                                            disabled
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            label="Precio"
                                                            fullWidth
                                                            value={obj.precioUnitario + "$"}
                                                            readOnly
                                                            disabled
                                                        />
                                                    </Grid>



                                                    {/* <Grid item xs={6}>
                            <TextField
                              label="Temporada"
                              value={obj.desTemporada}
                             readOnly
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              label="Marca"
                              value={obj.desMarca}
                             readOnly
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              label="Modelo"
                              value={obj.desModelo}
                             readOnly
                            />
                          </Grid> */}
                                                </Grid>
                                            </Paper>
                                        </AccordionDetails>
                                    ))}
                            </Accordion>
                        </Grid>
                    ) : (
                        <Grid md={12} className={classes.accordion}>
                            <Typography variant="subtitle2">
                                No hay información de los artículos solicitados, ya no están registrados.
                            </Typography>
                        </Grid>
                    )}

                    <Grid md={12} xs={12} sm={12}>
                        <center>
                            <img src={logo} alt="_blank" className={classes.logo} />
                        </center>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Cerrar
                </Button>
                {articulo == null ?
                    <></>
                    :
                    pedidoArray[0].idTipoPedido === 'TP-001' ?
                        <></>
                        :
                        <Button onClick={handleRight} color="primary" disabled={(idEstatusPedidos !== '0') ? true : false} >
                            Procesar
                        </Button>
                }
            </DialogActions>
        </Dialog>
    );
}