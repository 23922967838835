import axios from "axios";
import { setLoader } from "../_actionLoader";
import { DencryptData } from "../../../Tools/CryptoLocalStorage";
import { properties, ENCRYP_TYPE, LOGIN_DATA_LS } from "../../../Constants";
import { reserva } from '../_actionShoppingCar';
import { setLoginRenew } from "../_actionLoginRenew";

export const postInventarioReserva = (body, items, sumatoria) => {
  return (dispatch) => {
    const loginDataStorage = DencryptData(ENCRYP_TYPE.JSON, LOGIN_DATA_LS);
    const { token } = loginDataStorage;
    axios({
      method: "POST",
      url: properties.endpoints.inventarioReserva,
      headers: {
        Authorization: "Bearer " + token,
      },
      data: body,
    })
      .then((response) => {
        dispatch(setLoader(false));
        dispatch(reserva(response.data.Articulos, items, sumatoria));
      })
      .catch((error) => {
        var response = { ...error.response };
        dispatch(setLoader(false));
        dispatch(setLoginRenew(response));
       
      });
  };
};
