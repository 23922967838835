import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Grid,
  withStyles,
  Paper,
  TextField,
  Button,
  Typography,
} from "@material-ui/core";
import {
  Instagram,
  LocationOnOutlined,
  WhatsApp,
  EmailOutlined,
  ScheduleOutlined,
} from "@material-ui/icons";
import ReCAPTCHA from "react-google-recaptcha";

/*Actions */
import { postContact } from "../../../Redux/Actions/services/postContact";
import { setLoader } from "../../../Redux/Actions/_actionLoader";
import { styles } from "../constants";
import { validateValues } from "../../../Tools/index";
import { properties } from "../../../Constants";
const labels = JSON.parse(localStorage.getItem("labels"));

const constTextField = ["email", "name", "comment"];

const boolTextField = ["emailBool", "nameBool"];

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      name: "",
      comment: "",
      commentError: false,
      formatEmail: labels.EMAIL_FORMAT,
      required: labels.REQUIRED,
      emailBool: false,
      nameBool: false,
      captcha: undefined,
    };
    this.captcha = React.createRef();
  }

  componentDidMount() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  handleEmail = () => {
    /* Validación Captcha */
    this.setState({ captcha: !this.captcha.current.getValue() ? false : true });

    /**Bucle para pintar alerta en inputs vacío**/
    constTextField.forEach((textField, i) => {
      textField = constTextField[i];
      this.validationForm(this.state[textField], "", boolTextField[i], true);
    });

    /**Bucle para validar cuántos inputs están llenos**/
    var cont = 0;
    var bool = true;


    //Valida estado por estado y si al menos uno esta encendido, frena el proceso. 
    boolTextField.forEach((item) => {
      if (this.state[item] === true) {
        bool = false;
      }
    });
    constTextField.forEach((textField1, i) => {
      textField1 = constTextField[i];
      if (this.state[textField1] !== "") {
        cont = cont + 1;
      }
    });

    /* Validación Campo Comentario */
    this.setState({ commentError: this.state.comment ? false : true })

    if (cont === constTextField.length && bool === true && this.state.captcha && !this.state.commentError) {
      this.props.setLoader(true);
      this.props.postContact(
        this.state.email,
        this.state.name,
        this.state.comment
      );
      if (!this.props.loader) {
        setTimeout(() => {
          this.captcha.current.reset();
          this.setState({
            captcha: undefined,
            comment: "",
            name: "",
            email: "",
          });
        }, 1000);
      }
    }
  };

  /**-Validación de inputs esta función se llama al momento de darle click a "continuar" y haya algún campo vacío (se pinta error)
   * -En caso de que sea tipeado algo, se borrará el error pintado**/
  validationForm = (state, value, boolean, valueState) => {
    if (state === value)
      this.setState({ [boolean]: valueState });
  };

  handleChange = (event) => {
    let { name, value, id } = event.target;

    let bool = validateValues(value, id);
    this.setState({ [name]: value, [name + "Bool"]: !bool });
  };

  handleComment = (event) => {
    let { name, value, } = event.target;
    this.setState({
      [name]: value,
      commentError: value === "" ? true : false
    });
  };

  onChangeCaptcha = () => {
    this.setState({ captcha: this.captcha.current.getValue() ? true : false });
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid container>
        {/* Dirección en Google Maps */}
        <Grid item xs={12}>
          <iframe
            className={classes.googleMap}
            src={properties.NEMESIS_MAP}
            aria-hidden="false"
            title="Guss Muebles"
            frameBorder="0"
            tabIndex="0"
          ></iframe>
        </Grid>
        {/* Fin dirección en Google Maps */}

        {/* Formulario + Información */}
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={false} md={3}></Grid>

            {/* Título y formulario */}
            <Grid item xs={12} md={4} className={classes.blueBox}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h5">Contáctanos</Typography>
                </Grid>
                <Grid item xs={12} className={classes.formulario}>
                  <TextField
                    required
                    id="email"
                    color="secondary"
                    fullWidth
                    label={labels.EMAIL_LB}
                    value={this.state.email}
                    onChange={this.handleChange}
                    name="email"
                    helperText={
                      this.state.emailBool ? this.state.formatEmail : ""
                    }
                    error={this.state.emailBool}
                    inputProps={{
                      maxLength: 70,
                    }}
                  />
                  <TextField
                    required
                    id="alphanumeric"
                    color="secondary"
                    fullWidth
                    label={labels.NAME_LASTNAME_LB}
                    value={this.state.name}
                    onChange={this.handleChange}
                    name="name"
                    helperText={this.state.nameBool ? this.state.required : ""}
                    error={this.state.nameBool}
                    inputProps={{
                      maxLength: 30,
                    }}
                  />
                  <TextField
                    required
                    error={this.state.commentError}
                    helperText={this.state.commentError ? this.state.required : ""}
                    rows={4}
                    onChange={this.handleComment}
                    color="secondary"
                    fullWidth
                    label={labels.COMMENT_LB}
                    value={this.state.comment}
                    name="comment"
                    multiline
                    inputProps={{ maxLength: 500 }}
                  />
                  <ReCAPTCHA ref={this.captcha} sitekey={properties.RECAPTCHA_SITE_KEY} onChange={this.onChangeCaptcha} />
                  {this.state.captcha === false && (
                    <Typography variant="subtitle2" className={classes.captcha}>
                      {"Debe validar que no es un robot"}
                    </Typography>
                  )}
                  <Button
                    disabled={this.props.loader}
                    onClick={this.handleEmail}
                    size="large"
                    variant="outlined"
                    color="secondary"
                    style={{ borderRadius: 0, marginTop: 20 }}
                  >
                    {labels.SEND_BUTTOM}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {/* Fin título y formulario */}
            

            {/* Cuadro de información */}
            <Grid item xs={false} md={4}>
              <Grid container>
                <Grid item xs={12}>
                  <Paper elevation={3} className={classes.grayBox}>
                    <Grid container spacing={2} className={classes.margenIcons}>
                      <Grid item xs={1}>
                        <Instagram />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography>@gussmuebles</Typography>
                      </Grid>

                      <Grid item xs={1}>
                        <WhatsApp />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography>+58 414-2187884</Typography>
                      </Grid>

                      <Grid item xs={1}>
                        <EmailOutlined />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography>administracion@gussmuebles.com</Typography>
                      </Grid>

                      <Grid item xs={1}>
                        <ScheduleOutlined />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography>
                          Lunes a Viernes de 8am a 5pm y Sábados 9am a 5pm.
                        </Typography>
                      </Grid>

                      <Grid item xs={1}>
                        <LocationOnOutlined />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography>
                          Av. Libertador, entre calle Negrín y El Empalme,
                          Edif. Majestic, PB primer estacionamiento.
                          Frente a la clínica Santiago de León.⁣
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            {/* Fin cuadro de información */}

            <Grid item xs={false} md={1}></Grid>
          </Grid>
        </Grid>
        {/* Fin formulario + Información */}
      </Grid>
    );
  }
}

const ContactUs = withStyles(styles, { withTheme: true })(Contact);

function mapStateToProps(state) {
  return {
    loader: state.loaderReducer.loader,
  };
}

export default connect(mapStateToProps, {
  postContact,
  setLoader,
})(ContactUs);