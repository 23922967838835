import axios from 'axios';
import { setLoader } from '../_actionLoader';
import { articlesPreferences } from '../_actionGussta';
import { properties } from '../../../Constants';
import { loginRenewModal } from '../_actionLoginRenew';
import { setAlert } from '../_actionAlerts';


var funcMessage = JSON.parse(localStorage.getItem("funcMessage"));

export const getPreferences = (headers, token, bool) => {
    return dispatch => {
        axios({
            method: "GET",
            url: properties.endpoints.preference,
            headers: headers,
        }).then((response) => {
            dispatch(setLoader(false))
            const { status, data } = response;
            if (status && status === 200) {
                dispatch(articlesPreferences(true, data));
            } else {
                if (status && status === 204) {
                    if (!bool) {

                        dispatch(setAlert(true, funcMessage.MSG_003, properties.severities.warning, properties.props.error));
                    }
                }
            }
        }).catch((error) => {
            var response = { ...error.response };
            const { status } = response;

            if (status && status === 401) {
                dispatch(loginRenewModal(true))
            }
            if (!bool) {
                dispatch(setAlert(true, funcMessage.MSG_003, properties.severities.warning, properties.props.error));
            }
        });
    }
}