// import { PermScanWifiTwoTone } from '@material-ui/icons';
import axios from 'axios';
import {setLoader} from '../_actionLoader'
import {setAlert} from '../_actionAlerts';
import { DencryptData } from '../../../Tools/CryptoLocalStorage';
import { properties, ENCRYP_TYPE, LOGIN_DATA_LS } from '../../../Constants';


var funcMessage = JSON.parse(localStorage.getItem("funcMessage"));


export const putCarrito = (body ) => {

    return(dispatch) => {
        const loginDataStorage = DencryptData(ENCRYP_TYPE.JSON, LOGIN_DATA_LS);
        const { token } = loginDataStorage

        dispatch(setLoader(true))
        axios({
            method: "PUT",
            url: properties.endpoints.putCarrito,
            headers:{ Authorization: 'Bearer ' + token},
            data: body,
        }).then((response) => {


            dispatch(setLoader(false))
            // dispatch(setAlert(true,  response.data.func_msg, properties.severities.success ))

        }).catch((error) => {
          
             var response = { ...error.response};
            dispatch(setLoader(false))
            try{
                dispatch(setAlert(true, response.data.tecn_msg, properties.severities.warning, properties.props.error));

            }catch(error){
                dispatch(setAlert(true, funcMessage.MSG_001, properties.severities.warning, properties.props.error));

            }

        })
    }

}

