import React from "react";
import whatsapp from './img/whatsapp.png';
import { makeStyles } from "@material-ui/core";
import { properties } from "../../../Constants";

const useStyles = makeStyles((theme) => ({
    boton: {
        width: 50,
        height: 50,
        zIndex: 100,
        position: 'fixed',
        bottom: 10,
        right: 10,
        [theme.breakpoints.down("sm")]: {
            width: 70,
            height: 70,
        },
    }
}));

export default function ButtonWhatsapp() {
    const classes = useStyles();

    const handleClick = () =>{
        const whatsappUrl = 'https://wa.me/' + properties.phonenumber;
        const textoWhatsapp = properties.textoWhatsapp.replace(new RegExp(" ","g"), '%20');
        const urlTotal = whatsappUrl + '?text=' + textoWhatsapp;
        window.open(urlTotal, '_blank')
    }

    return (
        <>
            <img src={whatsapp} 
            alt = ""
            className={classes.boton} 
            onClick={handleClick}/>
        </>
    );
}