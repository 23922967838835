import axios from 'axios';
import { setLoader } from '../_actionLoader';
import { getCarrito } from '../services/getCarrito';
import { DencryptData } from '../../../Tools/CryptoLocalStorage';
import { properties, ENCRYP_TYPE, LOGIN_DATA_LS } from '../../../Constants';
import { PREFERENCES } from '../../constants';
import { setLoginRenew } from '../_actionLoginRenew';


export const postActualizarPreferencias = (body, carritoIsOpen) => {
    return dispatch => {
        const loginDataStorage = DencryptData(ENCRYP_TYPE.JSON, LOGIN_DATA_LS);
        const { token } = loginDataStorage
        axios({
            method: "POST",
            url: properties.endpoints.preference+'/actualizar-preferencias',
            headers: {
                Authorization: 'Bearer ' + token
            },
            data: body
        }).then((response) => {
                dispatch(setLoader(false))
                dispatch({
                    type: PREFERENCES,
                    payload: response.data
                })
                //Si el estado de carrito está activo, recargamos carrito
                if(carritoIsOpen){
                    dispatch(getCarrito());
                }
        }).catch((error) => {
           

            var response = { ...error.response };
            dispatch(setLoader(false));
            dispatch(setLoginRenew(response));

        });
    }
}