import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root:{
        marginTop: '56px',
        zIndex: 1
    },
    imagen: {
        width: '100%',
        objectFit: 'fill',
        aspectRatio: '131/60'
    }
}))