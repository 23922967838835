import {
  OPEN_PUCHASE,
  STEP_PURCHASE,
  GET_STATES_LIST,
  GET_CITY_LIST,
  GET_MUNICIPALITIES_LIST,
  GET_PARISHES_LIST,
  GET_SHIPPING_LIST,
  GET_BANKS_LIST,
  GET_PAYMETHOD_LIST,
  GET_DOCUMENTSID_LIST,
  GET_CITY_LIST_BUYPROCESS,
  GET_STATES_LIST_BUYPROCESS,
  GET_MUNICIPALITIES_LIST_BUYPROCESS,
  GET_PARISHES_LIST_BUYPROCESS,
  OPEN_ADD_CAR_MODAL,
  SET_COTIZAR,
  OPEN_SHOPPINGCAR,
  GET_ACCOUNT_LIST,
  SET_MEGUSTA,
  SET_GUARDADOS,
  SET_CARRITO,
  RESERVA,
  SOLICITUD,
  SUMATORIA,
  SET_PDF
} from "../constants";

import { getEstados } from "./services/getEstados";
import { getEstadosBuyProcess } from "./services/getEstadosBuyProcess";
import { getShipping } from "./services/getShipping";
import { getBanks } from "./services/getBanks";
import { getPayMethod } from "./services/getPayMethod";
import { getDocumentsId } from "./services/getDocumentsId";
import { getAccounts } from "./services/getAccounts";

export const openPurchase = (payload, dataShoppingCar, sumatoria) => {
  return {
    type: OPEN_PUCHASE,
    payload: payload,
    dataShoppingCar: dataShoppingCar,
    dataSumatoriaPurchase: sumatoria,
  };
};

export const stepPurchase = (payload) => {
  return {
    type: STEP_PURCHASE,
    payload: payload,
  };
};

export const setStateList = (data) => {
  return {
    type: GET_STATES_LIST,
    data: data,
  };
};

export const setStateListBuyProcess = (data) => {
  return {
    type: GET_STATES_LIST_BUYPROCESS,
    data: data,
  };
};

export const setCityList = (data) => {
  return {
    type: GET_CITY_LIST,
    data: data,
  };
};

export const setCityListBuyProcess = (data) => {
  return {
    type: GET_CITY_LIST_BUYPROCESS,
    data: data,
  };
};

export const setMunicipalitiesList = (data) => {
  return {
    type: GET_MUNICIPALITIES_LIST,
    data: data,
  };
};

export const setMunicipalitiesListBuyProcess = (data) => {
  return {
    type: GET_MUNICIPALITIES_LIST_BUYPROCESS,
    data: data,
  };
};

export const setParishesList = (data) => {
  return {
    type: GET_PARISHES_LIST,
    data: data,
  };
};

export const setParishesListBuyProcess = (data) => {
  return {
    type: GET_PARISHES_LIST_BUYPROCESS,
    data: data,
  };
};

export const setShippingList = (data) => {
  return {
    type: GET_SHIPPING_LIST,
    data: data,
  };
};

export const setBanksList = (data) => {
  return {
    type: GET_BANKS_LIST,
    data: data,
  };
};

export const setPayMethodList = (data) => {
  return {
    type: GET_PAYMETHOD_LIST,
    data: data,
  };
};

export const setDocumentsIdList = (data) => {
  return {
    type: GET_DOCUMENTSID_LIST,
    data: data,
  };
};

export const openAddCarModal = (payload, data) => {
  return {
    type: OPEN_ADD_CAR_MODAL,
    payload: payload,
    data: data,
  };
};

export const setCotizar = (payload, data) => {
  return {
    type: SET_COTIZAR,
    payload: payload,
    data: data,
  };
};

export const openShoppingCar = (payload) => {
  return {
    type: OPEN_SHOPPINGCAR,
    payload: payload,
  };
};

export const setCarrito = (data) => {
  return {
    type: SET_CARRITO,
    data: data,
  };
};

export const setAccountList = (data) => {
  return {
    type: GET_ACCOUNT_LIST,
    data: data,
  };
};

export const setMeGusta = (data) => {
  return {
    type: SET_MEGUSTA,
    data: data,
  };
};

export const setGuardados = (data) => {
  return {
    type: SET_GUARDADOS,
    data: data,
  };
};
export const reserva = (payload, items, sumatoria) => {
  return (dispatch) => {
    var cont = false;
    var boolPurchase = false;
    var length = payload.length;
    var purchaseCont = 0;

    payload.forEach((data) => {
      if (data.reservaExitosa === "N") {
        cont = true;
        purchaseCont = purchaseCont + 1;
      }
      items.forEach((item) => {
        if (data.idArticulo === item.idArticulo) {
          item.reserva = data.reservaExitosa;
        }
      });
    });
    if (length === purchaseCont) {
      boolPurchase = true;
    }
    if (cont) {
      dispatch(solicitud(true, items, sumatoria));
      dispatch({
        type: RESERVA,
        payload: payload,
        data: items,
        bool: boolPurchase,
      });
    } else {
      dispatch(getEstados());
      dispatch(getEstadosBuyProcess());
      dispatch(getShipping());
      dispatch(getBanks());
      dispatch(getPayMethod());
      dispatch(getDocumentsId());
      dispatch(getAccounts());
      dispatch(openPurchase(true, items, sumatoria));
    }
  };
};

export const solicitud = (payload, items, sumatoria) => {

  let SUM = sumatoria;
  if (items !== undefined) {
    let totalAgotados = 0;
    items.forEach((item) => {
      if (item.reserva === "N") {
        if(item.valorPromo !== null){
          totalAgotados = item.cantidad * item.valorPromo + totalAgotados;
        }else{
          totalAgotados = item.cantidad * item.precio + totalAgotados;
        }
      }
    });
    SUM = sumatoria - totalAgotados;
  }
  return {
    type: SOLICITUD,
    payload: payload,
    itemsCompraAgotados: items,
    sumatoriaCompraAgotados: SUM,
  };
};

export const sumatoriaTotalCarrito = (data) => {
  let objeto = [...data];
  var total = 0;

  for (let i = 0; i < objeto.length; i++) {
    var valor = objeto[i].valorPromo || objeto[i].precio
    total = objeto[i].cantidad * valor + total;
  }

  return {
    type: SUMATORIA,
    total: total,

  };
};

export const setPdf = (payload) => {
  return {
    type: SET_PDF,
    payload: payload,
  }
}
