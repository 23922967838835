import { useDispatch } from "react-redux";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

/*CONSTANTS*/
import { DencryptData } from "../../../../Tools/CryptoLocalStorage";
import { properties, ENCRYP_TYPE, LOGIN_DATA_LS } from "../../../../Constants";
import formulario from '../../img/formulario.png';

/*ACTIONS*/
import { loginRenewModal } from '../../../../Redux/Actions/_actionLoginRenew';
import { postLoginRenew } from '../../../../Redux/Actions/services/postLoginRenew';
import { closeSesion } from "../../../../Tools";



const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputBase-input": {
      color: "white"
    },
  },
  textColor: {
    input: {
      color: "white",
    },
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
      color: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
        color: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
        color: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
        color: "white",
      },
    },
    color: "white",
    width: "80%",
  },
  letter: {
    color: "white",
    fontSize: 20,
  },
  Logo: {
    marginTop: "-5vh",
    marginLeft: 120,

    [theme.breakpoints.down("xs")]: {
      marginTop: "-15vh",
      marginLeft: "8vh",
    },
  },
  button: {
    padding: "3px 15px 3px 15px",
    color: `${properties.primary}`,
    borderRadius: "0px",
    marginTop: "5vh",
    marginRight: "-29vh",
    [theme.breakpoints.down("xs")]: {
      marginTop: "5vh",
      marginRight: "-20vh",
    },
  },
}));


export default function RenewLogin(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = DencryptData(ENCRYP_TYPE.JSON, LOGIN_DATA_LS);
  const labels = JSON.parse(localStorage.getItem("labels"));
  const [disable, setDisable] = useState(true)
  const [input, setInput] = useState({
    email: user.usuario.emailPrincipal,
    password: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInput({ ...input, [name]: value, })
    validateInput()
  };

  const validateInput = () => {
    setDisable(input.email !== '' && input.password !== '' ? false : true)
  }

  const handleClose = () => {
    dispatch(loginRenewModal(false))
  };

  /*Llamado al API POST Login*/
  const responseRenew = () => {
    let headers = {
      idEmpresa: properties.companyID,
      idCanal: properties.idCanal,
      correo: input.email,
      password: input.password,
    };
    dispatch(postLoginRenew(headers))
  };

  return (
    <>
      <Dialog
        open={true}
        maxWidth={"xs"}
        aria-labelledby="responsive-dialog-title"
      >
        <div
          style={{
            background: "linear-gradient(to top, #013348 0%, #0585ba 100%)",
            overflow: "hidden",
          }}>
          <DialogTitle id="customized-dialog-title">
            <Grid container style={{ color: `${properties.black}` }}>
              <Grid xs={10}>
                <Typography variant="h5">
                  Renovar Sesión
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Button onClick={handleClose}>
                  <i style={{ color: `${properties.black}` }} class="icon-guss_close" />
                </Button>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent style={{ overflow: "hidden" }}>
            <Grid container>
              <Grid item xs={10}>
                <Grid container spacing={2}>
                  <Grid item xs={12} >
                    <TextField
                      label={<span style={{ color: 'white' }}> {labels.EMAIL_LB} </span>}
                      name="email"
                      value={input.email}
                      onChange={handleChange}
                      autoFocus
                      className={classes.textColor}
                      disabled
                      inputProps={{
                        maxLength: 25,
                      }}
                    />
                  </Grid>
                  <Grid item md={12} xs={12} sm={12} >
                    <TextField
                      label={<span style={{ color: "white" }}>{labels.PASSWORD_LB}</span>}
                      name="password"
                      value={input.password}
                      onChange={handleChange}
                      autoFocus
                      className={classes.textColor}
                      type="password"
                      inputProps={{
                        maxLength: 25,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="medium"
                      disabled={disable}
                      className={classes.button}
                      onClick={responseRenew}
                    >
                      Renovar
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="medium"
                      className={classes.button}
                      onClick={() => { closeSesion() }}
                    >
                      Cerrar Sesión
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <img
                  src={formulario}
                  alt=""
                  width="80"
                  style={{ marginLeft: 10 }}
                />
              </Grid>
              <Grid item xs={12} className={classes.Logo}>
                <i
                  class="icon-guss_logo"
                  alt=""
                  style={{
                    color: `${properties.white}`,
                    fontSize: 20,
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
}