import { properties } from '../../Constants';
import fondo from '../Home/img/fondoProduct.jpg';
export const styles = (theme) => ({
    fondo: {
        backgroundImage: 'url(' + fondo + ')'
    },
    productImg: {
        height: "10vh",
        objectFit: 'cover',
        width: '100%'
    },
    menuProduct: {
        color: properties.secondary
    },
    paperMenu: {
        background: "linear-gradient(to bottom, #c7c7bf 0%,#919799 100%)",
        height: '810px',
    },
    contenedorCategorias: {
        height: '570px',
        overflowX: 'auto',
        '&::-webkit-scrollbar': {
            padding: '0.4em',
            margin: '0.4em',
            width: '0.4em',
            background: '#c0c0c1',
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 0 rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 0 rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#c7c7bf',
            background: '#c7c7bf',

        }
    },
    paperSearch: {
        backgroundColor: properties.transparent,
        height: 120,
        paddingTop: 45,
    },
    search: {
        padding: '0px 30px 0px 30px'
    },
    imgMenu: {
        marginLeft: "-2vh",
        position: "absolute",
    },
    heightProduct: {
        minHeight: 750,
        padding: 10,
        paddingTop: 0
    },
    imgHeight: {
        padding: 8,
    },
    pagination: {
        backgroundColor: properties.grayLight,
        padding: 5,
        marginBottom: 15
    },

    dividerProduct: {
        marginBottom: theme.spacing(13)
    },
    contenedorTitulo: {
        height: '60px'
    },
    contenedorPrecio: {
        height: '60px',
        display: 'inline-flex'
    },
    card: {
        width: '100%',
    },
    CardActionArea: {
        height: 220,
        backgroundColor: "white",
    },
    colorCard: {
        backgroundColor: properties.grayLight,
    },
    imgCard: {
        width: '100%',
        maxHeight: 200,
        maxWidth: 220,
        display: 'block',
        margin: 'auto'
    },
    title: {
        fontSize: 18
    },
    subtitle: {
        fontSize: 14
    },
    select: {
        backgroundColor: properties.primary,
        padding: 10,
        width: '100%',
        color: "white",
        textAlign: "center"
    },
    searchMobile: {
        padding: 20
    },
    modalSearch: {
        backgroundColor: properties.secondary
    },
    modalColor: {
        color: properties.white,
        fontSize: 20
    },
    iconClose: {
        float: "right",
        color: properties.white
    },
    containerSearch: {
        paddingBottom: 25
    },
    subprice: {
        fontSize: 12,
        float: 'right',
        marginTop: -45
    },
    [theme.breakpoints.up('md')]: {
        hiddenDesktop: {
            display: 'none'
        },
    },
    [theme.breakpoints.up('lg')]: {
        hiddenDesktop: {
            display: 'none'
        },
    },
    [theme.breakpoints.up('sm')]: {
        hiddenDesktop: {
            display: 'none'
        },
    },

    [theme.breakpoints.down('xs')]: {
        hiddenMobile: {
            display: 'none'
        },
    },
    fondoDetailProduct: {
        background: properties.gray,
    },
    fondoProduct: {
        background: properties.white,
    },
    fondoProductAnswer: {
        background: properties.grayLight,
    },
    marginTop: {
        marginTop: -55,
        [theme.breakpoints.down('sm')]: {
            marginTop: -20,
        },
    },
    paddingDetail: {
        padding: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
        },
    },
    divider: {
        marginBottom: 20,
    },
    margin: {
        marginLeft: 10,
        lineHeight: '20px',
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
        },
    },
    color: {
        marginBottom: 10
    },
    colors: {
        height: 35,
        width: 35,
    },
    imgDetail: {
        width: '100%',
        maxHeight: 400,
        maxWidth: 350,
        display: 'block',
        margin: 'auto',
        marginTop: -17,
        [theme.breakpoints.down('xs')]: {
            marginTop: -20,
        },
    },
    priceDetail: {
        background: 'linear-gradient(to right, #f8f7f2, #0d1544)',
        opacity: 0.7,
        padding: 20,
        fontSize: 30,
        color: 'white',
        [theme.breakpoints.down('xs')]: {
            fontSize: 20,
        },
    },
    Arrow: {
        color: properties.secondary,
        fontSize: 25,
        margin: 0,
        [theme.breakpoints.down('xs')]: {
            marginRight: 20,
        },
    },
    imgDescription: {
        width: '100%',
        maxHeight: 160,
        maxWidth: 160,
        display: 'block',
        margin: 'auto',
        border: "1px gray solid",
        backgroundColor: properties.gray,
        padding: 10
    },
    fondoDescription: {
        background: properties.grayLight,
        height: '100%',
        minHeight: 120,
    },
    fondoProductSimilares: {
        background: 'linear-gradient(to right, #f8f7f2, #0d1544)',
        height: '100%',
        minHeight: 120,
    },
    marginCategory: {
        marginLeft: 10,
        marginTop: 15,
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
        },
    },
    chip: {
        padding: 10,
        marginLeft: 10,
        marginTop: 5,
        border: '1px solid gray',
        [theme.breakpoints.down('xs')]: {
            marginLeft: 5,
        },
    },
    answer: {
        padding: '20px 0px 15px 20px',
        [theme.breakpoints.down('xs')]: {
            padding: '10px 0px 10px 10px'
        },
    },
    root: {
        marginLeft: theme.spacing(3),
        marginTop: theme.spacing(2),
        height: 40,
        display: 'flex',
        alignItems: 'center',
        borderRadius: 0,
        border: '1px solid gray',
        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    button: {
        backgroundColor: properties.white,
        padding: '7px 30px 7px 30px',
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(2),
        borderRadius: 0,
        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(1),
            marginTop: theme.spacing(1),
        },
    },

    mainData: {
        height: theme.spacing(32),
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            padding: '0.4em',
            margin: '0.4em',
            width: '0.4em',
            background: '#dfdee3',
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#aadd11',
            background: '#aadd11',
        }
    },

    questions: {
        marginLeft: theme.spacing(3),
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(3),
        marginBottom: theme.spacing(4),
        borderRadius: 0,
        border: '1px solid gray',
        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
    },

    marginQuestions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    marginRespuesta: {
        paddingRight: theme.spacing(3),
        marginTop: theme.spacing(1),
        textAlign: 'right',
        [theme.breakpoints.down('xs')]: {
            paddingRight: theme.spacing(1),
            marginTop: theme.spacing(-1),
            marginLeft: theme.spacing(1),
            textAlign: 'left',
        },
    },
    captcha: {
        color: "#f44336",
        margin: 0,
        fontSize: "0.75rem",
        marginTop: "3px",
        textAlign: "left",
        fontFamily: "Roboto, Helvetica, Arial",
        fontWeight: "400",
        lineHeight: "1.66",
        letterSpacing: "0.03333em"
    },
    contenedorOverflow: {
        height: '185px',
        overflowY: 'auto'
    },
    contenedorColores: {
        height: '100px',
        overflowY: 'auto'
    },
    contenedorColoresItems: {
        display: 'inline-flex'
    },
    IconCopy: {
        '&:hover': {
            color: properties.primary
        },
        '&:active': {
            color: properties.gray
        }
    },
    rootMobile: {
        padding: '0px 15px'
    },
    fondoMobile: {
        background: properties.gray,
        height: '90vh'
    },
    imagenPrincipalMobile: {
        textAlign: 'center',
        aspectRatio: '1/1',
        position: 'relative'
    },
    chipMobile: {
        padding: 10,
        marginRight: 5,
        marginTop: 0,
        background: properties.gray
    },
    precio: {
        background: '#f8f7f2',
        padding: '15px',
        minHeight: 250
    },
    fichaClass: {
        background: '#dfdee3',
        padding: '30px 15px',
        minHeight: 'fit-content',
        textAlign: 'justify'
    },
    question: {
        background: '#f8f7f2',
        padding: '15px',
    },
    colorClass: {
        padding: '0px 15px',
        minHeight: '55px',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        marginTop: '10px',
        '& > .MuiGrid-root': {
            display: 'inline-flex',
            flexShrink: 0,
        },
    },
    similares: {
        marginTop: 50,
        padding: '15px'
    },
    coloresDesign: {
        height: 40,
        width: 40,
        borderRadius: '100%',
        border: '1px solid gray'
    },
    recaptcha: {
        marginLeft: '24px',
        [theme.breakpoints.down('sm')]: {
            marginTop: 10,
            marginBottom: 10,
            marginLeft: '10px !important',
        },
    },
    iconoMovil: {
        backgroundColor: properties.primary,
        borderRadius: 0,
        marginBottom: 15,
        float: 'right',
        borderRadius: '30px',
        padding: '5px 40px'
    },
    menu: {
        padding: '0px 0px 0px 35px',
        '& > div': {
            minWidth: 20, 
            marginBottom: 5,
            '& > svg':{
                fontSize: 15
            }
        }
    }
})