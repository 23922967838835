import React, { Component } from 'react';
import { connect } from "react-redux";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import { Dialog, Grid, DialogTitle } from '@material-ui/core';
import StepConnector from '@material-ui/core/StepConnector';
import ClearIcon from '@material-ui/icons/Clear';
import clsx from 'clsx';
import TimerIcon from '@material-ui/icons/Timer';
import Box from "@material-ui/core/Box";


/*Actions */
import { openPurchase, stepPurchase } from '../../../Redux/Actions/_actionShoppingCar';
import { setAlert } from '../../../Redux/Actions/_actionAlerts';
import { postReleaseInventory } from '../../../Redux/Actions/services/postReleaseInventory';

/*Constants */
import { styles } from "./constants.js";
import { properties, ENCRYP_TYPE, LOGIN_DATA_LS } from '../../../Constants';
import { DencryptData } from "../../../Tools/CryptoLocalStorage";

/*Components */
import BillingInformation from './BillingInformation';
import Order from './Order';
import Payment from './Payment';
import Summary from './Summary';
import { toMoneyFormat } from '../../../Tools';

const ColorlibConnector = withStyles({
    line: {
        border: 0,
    },
})(StepConnector);

function getSteps() {
    return ['1', '2', '3', '4'];
}

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#e8f4d9',
        zIndex: 1,
        color: '#fff',
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(140,198,63) 0%, rgb(140,198,63) 50%, rgb(140,198,63) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(232,244,217) 0%, rgb(232,244,217) 50%, rgb(232,244,217) 100%)',
    },
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

function Title(step) {
    switch (step) {
        case 0:
            return 'Datos de facturación'
        case 1:
            return 'Datos del pedido'
        case 2:
            return 'Datos de pago'
        case 3:
            return 'Resumen de pago'
        default:
            break;
    }
}

class PurchaseProcess extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cronometro: 55,
            time: 0,
            start: '30:00'
        }
        this.startTimer = this.startTimer.bind(this)
    }

    componentDidMount() {
        this.props.stepPurchase(0);
        this.startTimer()
        this.setState({ dataSumatoriaPurchase: this.props.dataSumatoriaPurchase })
    }

    closeModal = () => {
        const loginDataStorage = DencryptData(ENCRYP_TYPE.JSON, LOGIN_DATA_LS);

        let obj = {
            "IdEmpresa": properties.companyID,
            "IdUsuario": loginDataStorage.usuario.idUsuario,
            "usuario": loginDataStorage.usuario.idUsuario,
            "ArticuloInventario": []
        }

        this.props.dataShoppingCar.forEach((element) => {
            obj.ArticuloInventario.push({
                "IdArticulo": element.idArticulo,
                "IdColor": element.colorTrama,
                "Cantidad": parseInt(element.cantidad)
            })
        })
        this.props.postReleaseInventory(obj, false);
    }

    getStepContent = (step) => {
        switch (step) {
            case 0:
                return <BillingInformation />
            case 1:
                return <Order />
            case 2:
                return <Payment calculateTotalAmount={(payament) => this.calculateTotal(payament)} dataSumatoriaPurchase={this.state.dataSumatoriaPurchase} amountTotal={this.props.dataSumatoriaPurchase} />
            case 3:
                return <Summary />
            default:
                break;
        }
    }

    calculateTotal = (payament) => {
        this.setState({ dataSumatoriaPurchase: payament })
    }

    componentWillUnmount = () => {
        clearInterval(this.state.timer);
    }

    startTimer() {

        this.setState({
            time: this.state.start,
            start: Date.now() + 1800000
        })

        let timer = setInterval(() => {
            let time = Date.now() - this.state.start

            var ms = time % 1;
            time = (ms - time) / 1000;
            var secs = time % 60;
            time = (time - secs) / 60;
            var mins = time % 60;

            this.setState({
                time: mins.toFixed(0) + ':' + secs.toFixed(0)
            })

            if (mins.toFixed(0) === "0" && secs.toFixed(0) === "-0") {
                const loginDataStorage = DencryptData(ENCRYP_TYPE.JSON, LOGIN_DATA_LS);

                let obj = {
                    "IdEmpresa": properties.companyID,
                    "IdUsuario": loginDataStorage.usuario.idUsuario,
                    "usuario": loginDataStorage.usuario.idUsuario,
                    "ArticuloInventario": []
                }

                this.props.dataShoppingCar.forEach((element) => {
                    obj.ArticuloInventario.push({
                        "IdArticulo": element.idArticulo,
                        "IdColor": element.colorTrama,
                        "Cantidad": element.cantidad
                    })
                })
                this.props.postReleaseInventory(obj, true);
            }

        }, 1000)

        this.setState({
            timer: timer
        })

    }

    render() {
        const { classes, step } = this.props;
        const steps = getSteps();
        return (
            <Dialog
                open={this.props.purchaseOpen}
                fullWidth={true}
                maxWidth={'md'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                PaperProps={{
                    style: {
                        padding: 0
                    },
                }}
            >
                <DialogTitle id="scroll-dialog-title" >
                    <Grid container >
                        <Grid md={3} xs={1} sm={3} />
                        <Grid xs={9} md={6} sm={6} container justify='center'>
                            <Typography variant='h4' className={classes.title}>Proceso de compra</Typography>
                        </Grid>
                        <Grid md={2} xs={1} sm={2} />
                        <Grid xs={1} md={1} sm={1} container justify='flex-end'>
                            <Typography onClick={this.closeModal}><strong className={classes.closeModal}><ClearIcon ></ClearIcon></strong></Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <Grid container >
                    <Grid md={3} xs={12} sm={2} />
                    <Grid md={6} xs={12} sm={7} style={{ marginTop: -20 }} container justify='center'>
                        <Stepper activeStep={step} alternativeLabel connector={<ColorlibConnector />}>
                            {steps.map((label, index) => (
                                <Step key={label} >
                                    <StepLabel StepIconComponent={ColorlibStepIcon} ></StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Grid>

                    <Grid md={3} xs={12} sm={3} container style={{ marginTop: -10 }} justify='center'>
                        <Grid md={12} xs={3} sm={12} />
                        <Grid md={2} xs={2} sm={2} container justify='flex-end' style={{ marginTop: -8 }}>
                            <TimerIcon />
                        </Grid>
                        <Grid md={10} xs={7} sm={10}>
                            <Typography variant='h6' style={{ marginTop: -10 }}> {this.state.time}</Typography>
                        </Grid>
                        <Grid md={12} xs={7} sm={12} style={{ marginBottom: 15 }}>
                            <Typography variant="h5">
                                <Box fontWeight={400}>
                                    Total: {this.state.dataSumatoriaPurchase ? toMoneyFormat(this.state.dataSumatoriaPurchase.toFixed(2)) : 0}$
                                </Box>
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid md={1} xs={1} sm={1} />
                    <Grid md={10} xs={10} sm={10} container justify='center' style={{ marginTop: -15 }}>
                        <Typography variant="h6"  >{Title(step)}</Typography>
                    </Grid>
                    <Grid md={1} xs={1} sm={1} />
                </Grid>
                <Grid md={12} xs={12} sm={12} container >
                    <Grid md={12} xs={12} sm={12} >
                        <Typography className={classes.instructions}>{this.getStepContent(step)}</Typography>
                    </Grid>
                </Grid>
            </Dialog >
        );
    }
}

const purchase = withStyles(styles, { withTheme: true })(PurchaseProcess)
function mapStateToProps(state) {
    return {
        purchaseOpen: state.purchase.purchaseOpen,
        step: state.purchase.step,
        dataSumatoriaPurchase: state.purchase.dataSumatoriaPurchase,
        dataShoppingCar: state.purchase.dataShoppingCar,
    }
}

export default connect(
    mapStateToProps, {
    openPurchase,
    stepPurchase,
    setAlert,
    postReleaseInventory,
})(purchase);