import axios from 'axios';
import { CATEGORY_LS, ENCRYP_TYPE, properties } from '../../../Constants';
import { openProduct } from '../../Actions/_actionProducts';
import { setHome, searchCategories, setData, setArbolCategorias, seeMoreCategories } from '../../Actions/_actionHome';
import { setHeaderProps } from '../../Actions/_actionHeader';
import { setLoader } from '../../Actions/_actionLoader';
import { setLoginRenew } from '../_actionLoginRenew';
import { loadingSearchMobile } from '../../Actions/_actionHome';
import { EncryptData } from '../../../Tools/CryptoLocalStorage';
import { setBreadcrumbs } from '../_actionBreadcrumbs';

export const getSearchByCategories = (idCategoria, nombre, categoria) => {

    return (dispatch) => {
        EncryptData(idCategoria, ENCRYP_TYPE.STRING, CATEGORY_LS)
        dispatch(getArbolCategorias(idCategoria, categoria));
        axios({
            method: "GET",
            url: properties.endpoints.busqueda,
            headers: {
                idCategoria: idCategoria === undefined || idCategoria === "" ? "CAT-000" : idCategoria,
                nombre: nombre === undefined ? "" : nombre
            }
        }).then((response) => {
            dispatch(setHome(false));
            dispatch(setLoader(false))
            dispatch(searchCategories(true, response.data))
            dispatch(setHeaderProps(properties.gray, 'black'));
            dispatch(setBreadcrumbs(response.data.Breadcrumb));
            dispatch(openProduct(true));
        }).catch(error => {
            var response = { ...error.response };
            dispatch(setLoader(false));
            dispatch(setLoginRenew(response));
        })
    }
}

export const getProducts = (idCategoria, nombre) => {
    return (dispatch) => {
        dispatch(setData([]));
        dispatch(loadingSearchMobile(true));
        axios({
            method: "GET",
            url: properties.endpoints.busqueda,
            headers: {
                idCategoria: idCategoria === undefined ? "CAT-000" : idCategoria,
                nombre: nombre === undefined ? '' : nombre
            }
        }).then((response) => {
            dispatch(loadingSearchMobile(false));
            dispatch(setData(response.data.Respuesta));
        }).catch(() => {
            dispatch(setData([]));
            dispatch(loadingSearchMobile(false));
        })
    }
}

export const getCategoriasPadreHijo = (idCategoria) => {
    return (dispatch) => {
        dispatch(setLoader(true))
        axios({
            method: "GET",
            url: properties.endpoints.categorias,
            headers: {
                idCategoria: idCategoria === null || idCategoria === undefined ? "" : idCategoria
            }
        }).then((response) => {
            dispatch(setArbolCategorias(response.data));
            dispatch(seeMoreCategories(true));
            dispatch(setLoader(false))
        }).catch(() => {
            dispatch(setLoader(false))
            dispatch(setData([]));
        })
    }
}

export const getArbolCategorias = (idCategoria, categoria) => {
    return (dispatch) => {
        axios({
            method: "GET",
            url: properties.endpoints.catFatherAndSon,
            headers: {
                idCategoria: idCategoria === null || idCategoria === undefined || idCategoria === 'CAT-000' ? "" : idCategoria
            }
        }).then((response) => {       
            if(JSON.parse(response.data.Categorias).length !== 0){
                dispatch(setArbolCategorias(response.data));
            }
        })
    }
}