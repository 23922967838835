import {
  ARTICLES_PREFERENCES,
  MODAL_CHANGEPASSWORD,
  PREFERENCES_GUSSTA,
  PEDIDO_BY_USER,
  ORDER_SEARCH,
  PERSON_REQUEST,
  REQUEST_ADDRESS,
  ARTICLE_ORDERS,
  PAYMENT_ORDERS,
  MODAL_PEDIDOS_DETAILS_PAYLOAD, OPEN_GUSTA
} from "../constants";
import { toMoneyFormat, dateFormat } from "../../Tools";

export const articlesPreferences = (payload, data) => {
  if (payload) {
    if (data.articulos) {
      data.articulos.forEach((article) => {
        article.precio = toMoneyFormat(article.precio.toFixed(2));
        article.precioEspecial = toMoneyFormat(
          article.precioEspecial.toFixed(2)
        );
      });
    }
  }

  return {
    type: ARTICLES_PREFERENCES,
    articlesPreferencesPaylod: payload,
    articlesPreferencesData: data,
  };
};

export const modalChangePassword = (payload) => {
  return {
    type: MODAL_CHANGEPASSWORD,
    payload: payload,
  };
};

export const preferencesGussta = (data) => {
  return {
    type: PREFERENCES_GUSSTA,
    preferencesGussta: data,
  };
};

export const pedidoByUser = (payload, data) => {
  if (payload) {
    if (data) {
      data.forEach((pedido) => {
        pedido.fechaCrea = dateFormat(pedido.fechaCrea);
      });
    }
  }

  return {
    type: PEDIDO_BY_USER,
    pedidoByUserPaylod: payload,
    pedidoByUserData: data,
  };
};

export const modalPedidosDetails = (payload, idEstatusPedidos) => {
  return {
    type: MODAL_PEDIDOS_DETAILS_PAYLOAD,
    payload: payload,
    idEstatusPedidos: idEstatusPedidos
  }
}

export const setOrderSearch = (data) => {
  return {
    type: ORDER_SEARCH,
    data: data
  }
}

export const setPersonRequest = (data) => {
  return {
    type: PERSON_REQUEST,
    data: data
  }
}

export const setRequestAddress = (data) => {
  return {
    type: REQUEST_ADDRESS,
    data: data

  }
}

export const setOrdersArticle = (data) => {
  return {
    type: ARTICLE_ORDERS,
    data: data,
  }
}

export const setOrdersPayment = (data) => {
  return {
    type: PAYMENT_ORDERS,
    data: data,
  }
}

export const gustaOpen = (payload) => {
  return {
    type: OPEN_GUSTA,
    payload: payload,
  }
}
