import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import { makeStyles, MenuItem } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ClearIcon from "@material-ui/icons/Clear";
import Button from "@material-ui/core/Button";

/*CONSTANTS*/
import { properties, ENCRYP_TYPE, LOGIN_DATA_LS } from "../../../Constants";
import factura from "../PurchaseProcess/img/billing.png";
import { DencryptData } from "../../../Tools/CryptoLocalStorage";
import { validateValues } from "../../../Tools/index";

/*ACTIONS*/
import { setCotizar } from "../../../Redux/Actions/_actionShoppingCar";
import { postInventarioCotizaciones } from "../../../Redux/Actions/services/postInventoryQuote";
import { getCity } from "../../../Redux/Actions/services/getCity";
import { getMunicipalities } from "../../../Redux/Actions/services/getMunicipalities";
import { getParishes } from "../../../Redux/Actions/services/getParishes";
import { postReleaseInventory } from "../../../Redux/Actions/services/postReleaseInventory";

const labels = JSON.parse(localStorage.getItem("labels"));
const useStyles = makeStyles((theme) => ({
  hiddenMobile: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  title: {
    color: properties.secondary,
    fontWeight: "bold",
  },
  closeModal: {
    cursor: "pointer",
  },
  btn: {
    marginTop: "-6vh",
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(1),
    },
  },

  height: {
    minHeight: 600,
    [theme.breakpoints.down("sm")]: {
      minHeight: 450,
    },
  },
  contenedor:{
    paddingLeft: 70,
    [theme.breakpoints.down("sm")]: {
      padding: '0px 30px',
    },
  },
  next:{
    marginTop: 30
  }
}));

export default function QuotationProcess(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const estado = useSelector((state) => state.purchase.statesList);
  const ciudad = useSelector((state) => state.purchase.cityList);
  const parroquia = useSelector((state) => state.purchase.parishesList);
  const municipio = useSelector((state) => state.purchase.municipalitiesList);
  const documentoId = useSelector((state) => state.purchase.documentsIdList);
  const listaArticulo = useSelector((state) => state.purchase.listaArticulos);
  const isMobile = useSelector((state) => state.homeProps.isMobile);
  const dataUser = DencryptData(ENCRYP_TYPE.JSON, LOGIN_DATA_LS);
  const [state, setstate] = useState(true);
  const [tipoDocArray, settipoDocArray] = useState([]);
  const [input, setInput] = useState({
    tipoDoc: "",
    idDoc: "",
    Doc: "",
    name: "",
    lastName: "",
    BusinessName: "",
    email: "",
    estado: "",
    ciudad: "",
    parroquia: "",
    municipio: "",
    Phone: "",
    zonaPostal: "",
    Address: "",
  });
  const [boolInputs, setBoolInputs] = useState({
    tipoDoc: false,
    idDoc: false,
    Doc: false,
    name: false,
    lastName: false,
    BusinessName: false,
    email: false,
    estado: false,
    ciudad: false,
    parroquia: false,
    municipio: false,
    Phone: false,
    zonaPostal: false,
    Address: false,
  });
  const formInputs = [
    "tipoDoc",
    "idDoc",
    "Doc",
    "name",
    "lastName",
    "BusinessName",
    "email",
    "estado",
    "ciudad",
    "parroquia",
    "municipio",
    "Phone",
    "zonaPostal",
    "Address",
  ];
  const typePerson = [
    {
      id: 1,
      label: "Natural",
    },
    {
      id: 0,
      label: "Jurídico",
    },
  ];

  /*FUNCTIONS*/
  const handleChange = (event) => {
    var value = event.target.value;
    var name = event.target.name;
    let type = event.target.id;
    let bool = validateValues(value, type)
    //no permite escribir los numeros o letras, depende del campo
    if (bool) {
      setInput({ ...input, [name]: value });
      setBoolInputs({ ...boolInputs, [name]: !bool });
    }
  };

  const handleChangeEmail = (event) => {
    var value = event.target.value;
    var name = event.target.name;
    let type = event.target.id;
    let bool = validateValues(value, type)

    setInput({ ...input, [name]: value });
    setBoolInputs({ ...boolInputs, [name]: !bool });
  };

  const handleValidateForm = () => {
    let length = 0;
    let inputs = boolInputs;
    formInputs.forEach((name) => {
      if (input[name] !== "" && !boolInputs[name]) {
        setBoolInputs({ ...inputs, [name]: false });
        inputs[name] = false;
        length++;
      } else {
        setBoolInputs({ ...inputs, [name]: true });
        inputs[name] = true;
      }
    });
    return length;
  };

  const handleChangeCombos = (event) => {
    var value = event.target.value;
    var name = event.target.name;
    var docArray = [...documentoId];

    setInput({ ...input, [name]: value });
    formInputs.forEach((valor, i) => {
      validationForm(name, formInputs[i], formInputs[i], false);
    });


    if (name === "tipoDoc") {
      if (value === 1) { //Natural 
        var x = docArray.filter((item2) => item2.idTipoDocumento === 'V' || item2.idTipoDocumento === 'E')
        settipoDocArray(x);
        setstate(true);
        setInput({
          ...input,
          BusinessName: "",
          tipoDoc: 1,
        });
      } else { //Jurídico
        var y = docArray.filter((item) => item.idTipoDocumento !== 'V' && item.idTipoDocumento !== 'E');
        settipoDocArray(y);
        setstate(false);
        setInput({
          ...input,
          name: "",
          lastName: "",
          tipoDoc: 0,
        });
      }
    }

    if (name === "estado") {
      dispatch(getCity(value));
    }

    if (name === "ciudad") {
      dispatch(getMunicipalities(input.estado, value));
    }

    if (name === "municipio") {
      dispatch(getParishes(input.estado, input.ciudad, value));
    }

    if (name === 'typeClient') {
      if (value === 0)
        this.setState({ typeClienteOption: true, BusinessName: '' })
      if (value === 1)
        this.setState({ typeClienteOption: false, Name: '', Lastname: '' })
    }
  };

  const validationForm = (state, value, boolean, valueState,) => {
    if (state === value) {
      setBoolInputs({ ...boolInputs, [boolean]: valueState });
    }
  }

  /**
   * @param {*}
   *
   * maxLengthCheck se encarga de limitar los caracteres que entrar por el input phone.
   */
  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  const postQuotation = () => {
    let length = handleValidateForm();
    var cont = 0;
    if (input.tipoDoc === 1) {
      cont = 1;
    } else {
      cont = 2;
    }
    if (length === formInputs.length - cont) {
      let body = {
        p_idEmpresa: properties.companyID,
        p_UserCompra: dataUser.usuario.idUsuario,
        p_IdTipoPedido: listaArticulo.solicitud,
        v_Nombre: input.name,
        v_Apellido: input.lastName,
        v_RazonSocial: input.BusinessName,
        v_EmailPrincipal: input.email,
        v_IdPais: properties.idPais,
        v_IdEstado: input.estado,
        v_IdCiudad: input.ciudad,
        v_IdTipoPersona: input.tipoDoc,
        v_Parroquia: input.parroquia,
        v_Municipio: input.municipio,
        v_Direccion: input.Address,
        v_ZonaPostal: input.zonaPostal,
        v_IdTipoIdExterno: input.idDoc,
        v_Documento: input.Doc,
        v_Envio: true,
        listArticulos: listaArticulo.listArticulos,
        v_telefono: input.Phone
      };
      if (listaArticulo.solicitud === "TP-002") {
        var bodyLiberar = {
          IdEmpresa: properties.companyID,
          IdUsuario: dataUser.usuario.idUsuario,
          usuario: dataUser.usuario.idUsuario,
          ArticuloInventario: listaArticulo.artLib,
        };
        dispatch(postReleaseInventory(bodyLiberar, false, body));
      } else {
        dispatch(postInventarioCotizaciones(body));
      }
    }
  };

  const handleClose = () => {
    dispatch(setCotizar(false));
  };

  return (
    <Dialog maxWidth={"md"} aria-labelledby="simple-dialog-title" open={true}>
      <DialogTitle>
        <Grid container>
          <Grid xs={1} md={3} sm={3} />
          <Grid xs={9} md={6} sm={6} container justify="center">
            <Typography variant="h4" className={classes.title}>
              Datos del cliente
            </Typography>
          </Grid>
          <Grid md={2} xs={1} sm={2} />
          <Grid xs={1} md={1} sm={1} container justify="flex-end">
            <Typography onClick={handleClose}>
              <strong className={classes.closeModal}>
                <ClearIcon></ClearIcon>
              </strong>
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>

      <Grid container className={classes.contenedor}>
        <Grid xs={12} md={6} container>
          <Grid container spacing={2}>

            <Grid item xs={12} md={12} sm={12}>
              <TextField
                select
                fullWidth
                error={boolInputs.tipoDoc}
                helperText={boolInputs.tipoDoc && "Campo requerido"}
                style={{ marginTop: "5vh" }}
                label={labels.CUSTOMER_TYPE}
                name="tipoDoc"
                value={input.tipoDoc}
                onChange={handleChangeCombos}
              >
                {typePerson.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={3} md={2} sm={2}>
              <TextField
                select
                margin="normal"
                fullWidth
                error={boolInputs.idDoc}
                helperText={boolInputs.idDoc && "Campo requerido"}
                value={input.idDoc}
                name="idDoc"
                onChange={handleChangeCombos}
              >
                {tipoDocArray &&
                  tipoDocArray.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.idTipoDocumento}
                    >
                      {option.idTipoDocumento}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>


            <Grid item xs={9} md={10} sm={10}>
              <TextField
                fullWidth
                label={isMobile ? 'DNI' : labels.DOC_TYPE}
                error={boolInputs.Doc}
                helperText={boolInputs.Doc && "Campo requerido"}
                name="Doc"
                id='numeric'
                value={input.Doc}
                onChange={handleChange}
                onInput={maxLengthCheck}
                inputProps={{
                  maxLength: 10,
                }}
              />
            </Grid>

            {state ? (
              <>
                <Grid item xs={12} md={12} sm={12}>
                  <TextField
                    fullWidth
                    label="Nombre"
                    error={boolInputs.name}
                    helperText={boolInputs.name && "Campo requerido"}
                    id="textWithSpace"
                    onChange={handleChange}
                    name="name"
                    value={input.name}
                    inputProps={{
                      maxLength: 40,
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={12} sm={12}>
                  <TextField
                    fullWidth
                    name="lastName"
                    value={input.lastName}
                    error={boolInputs.lastName}
                    helperText={boolInputs.lastName && "Campo requerido"}
                    label="Apellido"
                    id="textWithSpace"
                    onChange={handleChange}
                    inputProps={{
                      maxLength: 40,
                    }}
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={12} md={12} sm={12}>
                <TextField
                  fullWidth
                  error={boolInputs.BusinessName}
                  helperText={boolInputs.BusinessName && "Campo requerido"}
                  label={labels.BUSINESSNAME_LB}
                  id="alphanumeric"
                  name="BusinessName"
                  value={input.BusinessName}
                  onChange={handleChange}
                  inputProps={{
                    maxLength: 60,
                  }}
                />
              </Grid>
            )}

            <Grid item xs={12} md={12} sm={12}>
              <TextField
                fullWidth
                error={boolInputs.email}
                helperText={boolInputs.email && "Campo requerido o formato erróneo, Ej: example@dominio.com"}
                label={labels.USER_LB}
                name="email"
                id="email"
                value={input.email}
                onChange={handleChangeEmail}
                inputProps={{
                  maxLength: 40,
                }}
              />
            </Grid>

            <Grid item xs={12} md={6} sm={6}>
              <TextField
                select
                fullWidth
                error={boolInputs.estado}
                helperText={boolInputs.estado && "Campo requerido"}
                label={labels.STATE}
                value={input.estado}
                name="estado"
                onChange={handleChangeCombos}
              >
                {estado &&
                  estado.map((option) => (
                    <MenuItem key={option.idEstado} value={option.idEstado}>
                      {option.estado1}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>


            <Grid item xs={12} md={6} sm={6}>
              <TextField
                select
                fullWidth
                error={boolInputs.ciudad}
                helperText={boolInputs.ciudad && "Campo requerido"}
                label={labels.CITY}
                value={input.ciudad}
                name="ciudad"
                onChange={handleChangeCombos}
              >
                {ciudad &&
                  ciudad.map((option) => (
                    <MenuItem key={option.idCiudad} value={option.idCiudad}>
                      {option.ciudad1}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>


            <Grid item xs={12} md={6} sm={6}>
              <TextField
                select
                fullWidth
                error={boolInputs.municipio}
                helperText={boolInputs.municipio && "Campo requerido"}
                label={labels.MUNICIPIO}
                value={input.municipio}
                name="municipio"
                onChange={handleChangeCombos}
              >
                {municipio &&
                  municipio.map((option) => (
                    <MenuItem
                      key={option.idMunicipio}
                      value={option.idMunicipio}
                    >
                      {option.municipio}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>


            <Grid item xs={12} md={6} sm={6}>
              <TextField
                select
                fullWidth
                error={boolInputs.parroquia}
                helperText={boolInputs.parroquia && "Campo requerido"}
                label={labels.PARROQUIA}
                value={input.parroquia}
                name="parroquia"

                onChange={handleChangeCombos}
              >
                {parroquia &&
                  parroquia.map((option) => (
                    <MenuItem
                      key={option.idParroquia}
                      value={option.idParroquia}
                    >
                      {option.parroquia}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>


            <Grid item xs={12} md={6} sm={6}>
              <TextField
                fullWidth
                error={boolInputs.Phone}
                helperText={boolInputs.Phone && "Campo requerido"}
                label="Teléfono"
                name="Phone"
                id="numeric"
                value={input.Phone}
                onChange={handleChange}
                onInput={maxLengthCheck}
                inputProps={{
                  maxLength: 15,
                }}
              />
            </Grid>


            <Grid item xs={12} md={6} sm={6}>
              <TextField
                fullWidth
                error={boolInputs.zonaPostal}
                helperText={boolInputs.zonaPostal && "Campo requerido"}
                label="Zona Postal"
                name="zonaPostal"
                id="numeric"
                value={input.zonaPostal}
                onChange={handleChange}
                onInput={maxLengthCheck}
                inputProps={{
                  maxLength: 5,
                }}
              />
            </Grid>

            <Grid xs={12}>
              <TextField
                fullWidth
                error={boolInputs.Address}
                helperText={boolInputs.Address && "Campo requerido"}
                label={labels.ADDRESS}
                name="Address"
                value={input.Address}
                id='alphanumeric'
                onChange={handleChange}
                inputProps={{
                  maxLength: 100,
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid xs={12} md={6} style={{ marginTop: 20 }} className={classes.hiddenMobile}>
          <img
            src={factura}
            alt="img"
            style={{ width: "90%", objectFit: "contain" }}
          />
        </Grid>

        <Grid xs={7} md={9}/>
        <Grid xs={5} md={3}container className={classes.next}>
          <Button
            variant="outlined"
            color="primary"
            onClick={postQuotation}
            style={{ borderRadius: 0 }}
          >
            {labels.NEXT_BUTTOM}
          </Button>
        </Grid>
      </Grid>
      <br />
      <br />
    </Dialog>
  );
}
