import React, { Component } from "react";
import { Grid, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";

import FACEBOOK from "./img/facebook.png";
import INSTA from "./img/instagram.png";
import TWITTER from "./img/twitter.png";
import LINKEDIN from './img/linkedin.png';
import logo from "../Home/img/logo.png";

/*Constants */
import { stylesFooter } from "./constants.js";
import { properties } from "../../Constants";

/*Actions*/
import { setContactUs } from "../../Redux/Actions/_actionFooter";
import { openUs } from "../../Redux/Actions/_actionFooter";
import { setResetAll, catchHomeApi } from "../../Redux/Actions/_actionHome";
import { setHeaderProps } from "../../Redux/Actions/_actionHeader";
import { setHome } from "../../Redux/Actions/_actionHome";
import { openProduct } from "../../Redux/Actions/_actionProducts";
import { gustaOpen } from "../../Redux/Actions/_actionGussta";

class Footer extends Component {
  
  handleOptionFooter = (id) => {
    switch (id) {
      case 1:
        this.props.setHeaderProps(properties.gray, "black");
        this.props.setHome(false);
        this.props.openProduct(false);
        this.props.setContactUs(false);
        this.props.openUs(true);
        this.props.gustaOpen(false);
        this.props.catchHomeApi(true);
        break;
      case 2:
        this.props.setHeaderProps(properties.gray, "black");
        this.props.setHome(false);
        this.props.openProduct(false);
        this.props.setContactUs(true);
        this.props.openUs(false);
        this.props.gustaOpen(false);
        this.props.catchHomeApi(true);
        break;

      default:
        break;
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid container className={classes.root}>
        {/* Sección del logo e iconos */}
        <>
          <Grid item xs={false} md={4}></Grid>
          <Grid item xs={12} md={4}>
            <Grid container>
              <Grid item xs={12} md={6} className={classes.alignCenter}>
                <img
                  alt="mueble"
                  src={logo}
                  className={classes.logo}
                />
              </Grid>
              <Grid item xs={12} md={6} className={classes.alignCenter}>
                <Grid container>
                  <Grid item xs={3} md={3}>
                    <a
                      href={properties.networkLinks.facebook}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img width={35} alt="logo" src={FACEBOOK} />
                    </a>
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <a
                      href={properties.networkLinks.instagram}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img width={35} alt="logo" src={INSTA} />
                    </a>
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <a
                      href={properties.networkLinks.twitter}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img width={35} alt="logo" src={TWITTER} />
                    </a>
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <a
                      href={properties.networkLinks.linkedin}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img width={35} alt="logo" src={LINKEDIN} />
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={false} md={4}></Grid>
        </>

        {/* Sección de links */}
        <>
          <Grid item xs={false} md={4}></Grid>
          <Grid item xs={12} md={4}>
            <Grid container>
              {properties.footerOptions.map((element) => (
                <Grid
                  xs={6}
                  className={classes.alignCenter}
                  onClick={() => this.handleOptionFooter(element.id)}
                >
                  <Button className={classes.links}>{element.name}</Button>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={false} md={4}></Grid>
        </>
      </Grid >
    );
  }
}

const footers = withStyles(stylesFooter, { withTheme: true })(Footer);

function mapStateToProps(state) {
  return {
    getHomeData: state.homeProps.getHomeData,
    getUs: state.footerProps.getUs,
  };
}

export default connect(mapStateToProps, {
  setContactUs,
  setResetAll,
  setHeaderProps,
  setHome,
  openProduct,
  openUs,
  gustaOpen,
  catchHomeApi,
})(footers);
