import { properties } from "../../../Constants";

export const styles = (theme) => ({
  root: {
    height: 224,
    flexGrow: 1,
    display: "flex",
    float: "right",
    zIndex: 4,
    "& .MuiInputBase-input": {
      color: "white"
    },
  },
  tabs: {
    height: "30em",
    width: 60,
    backgroundColor: properties.gray,
  },

  paper: {
    borderRadius: "0px",
    height: "30em",
    width: 300,
    background: "linear-gradient(to top, #013348 0%, #0585ba 100%)",
    [theme.breakpoints.down("xs")]: {

      width: "270px",
    },

  },

  logo: {
    fontSize: 50,
    color: `${properties.grayLight}`,
  },

  button: {
    borderRadius: "0px",
    marginTop: "0vh",
    marginLeft: 5,
    width: "30%",
    padding: 5,
  },

  transform: {
    transform: "rotate(-90deg)",
    marginTop: "32vh",
    marginLeft: -65,
    paddingTop: 10,
    width: 190,
  },
  title: {
    color: `${properties.grayLight}`,
    position: "relative",
    left: 30,
    top: 30,
    borderBottom: "1px solid white",
  },
  textColor: {
    width: "180px",
    input: {
      color: "white",
    },
    "& label.Mui-focused": {
      color: "white",

    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
      color: "white",

    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
        color: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
        color: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
        color: "white",
      },
    },
    color: "white",
  },

  check: {
    marginTop: 10,
  },

  textButton: {
    padding: "3px 25px 3px 25px",
    fontSize: 10,
  },

  paperRegister: {
    borderRadius: "0px",
    height: "35em",
    width: 300,
    marginTop: "-66vh",
    marginLeft: "8vh",
    background: "linear-gradient(to top, #013348 0%, #0585ba 100%)",
    [theme.breakpoints.down("xs")]: {
      marginTop: "-60vh",
      marginLeft: "7vh",
      width: "310px",
    },
  },
  textFieldRegister: {
    width: "200px",
    marginTop: "-50vh",
    marginLeft: "2vh",
    [theme.breakpoints.down("xs")]: {
      marginTop: "-45vh",
    },
  },

  buttonRegister: {
    borderRadius: "0px",
    marginTop: "0vh",
    marginLeft: "7vh",
    width: "30%",
    padding: 5,
  },
  captcha: {
    color: "#f44336",
    margin: 0,
    fontSize: "0.75rem",
    textAlign: "left",
    fontFamily: "Roboto, Helvetica, Arial",
    fontWeight: "400",
    lineHeight: "1.66",
    letterSpacing: "0.03333em"
  }


});


