import axios from 'axios';
import { setLoader } from '../_actionLoader';
import { setAlert } from '../_actionAlerts';
import { openAddCarModal } from '../_actionShoppingCar';
import { getCarrito } from '../services/getCarrito';
import { DencryptData } from '../../../Tools/CryptoLocalStorage';
import { properties, ENCRYP_TYPE, LOGIN_DATA_LS } from '../../../Constants';
import { setLoginRenew } from '../_actionLoginRenew';
import { getPreferencesGussta } from "../services/getPreferencesGussta";

export const postCarrito = (body, carritoIsOpen) => {
    return dispatch => {

        dispatch(setLoader(true));
        const loginDataStorage = DencryptData(ENCRYP_TYPE.JSON, LOGIN_DATA_LS);
        const { token } = loginDataStorage

        let _headers = {
            IdUsuario: loginDataStorage.usuario.idUsuario,
            Authorization: 'Bearer ' + token
        }

        axios({
            method: "POST",
            url: properties.endpoints.getCarrito,
            headers: _headers,
            data: body
        }).then((response) => {
            const { status, data } = response;
            if (status && status === 200) {
                dispatch(setAlert(true, data.func_msg, properties.severities.success));
                dispatch(setLoader(false));
                dispatch(openAddCarModal(false));
                dispatch(getPreferencesGussta());
                //Si el estado de carrito está activo, recargamos carrito
                if(carritoIsOpen){
                    dispatch(getCarrito());
                }
            }

        }).catch((error) => {

            var response = { ...error.response };
            dispatch(setLoader(false));
            dispatch(setLoginRenew(response));
        });
    }
}