import { properties } from "../../../Constants";
export const styles = (theme) => ({

  logo: {
    [theme.breakpoints.down("xs")]: {
      width: "240px",
      marginLeft: '10vh',
    },
    [theme.breakpoints.down('sm')]: {
      width: "250px",
      marginLeft: '15vh',
    },
    [theme.breakpoints.up('md')]: {
      width: "450px",
      marginLeft: '4vh',
    }
  },

  mueble: {
    [theme.breakpoints.down('sm')]: {
      width: "290px",
      marginTop: "15vh",
      marginLeft: "12vh",
    },
    [theme.breakpoints.up('md')]: {
      width: "680px",
      marginLeft: '35vh',
    }
  },

  boxGrey: {
    backgroundColor: `${properties.gray}`,
    [theme.breakpoints.down('sm')]: {
      marginTop: "-3vh",
      width: "200px",
      height: "330px",
    },
    [theme.breakpoints.up("md")]: {
      width: "450px",
      height: "550px",
      marginTop: "-1vh",
    },
  },

  img2: {
    [theme.breakpoints.down("xs")]: {
      objectFit: "cover",
      width: "100%",
    },
    [theme.breakpoints.down('sm')]: {
      objectFit: "cover",
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      objectFit: "cover",
      width: "100%",
    },
  },
  img3: {
    [theme.breakpoints.down("xs")]: {
      objectFit: "cover",
      width: "100%",
    },
    [theme.breakpoints.down('sm')]: {
      objectFit: "cover",
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      objectFit: "cover",
      width: "100%",
    },
  },
  img4: {
    [theme.breakpoints.down("xs")]: {
      objectFit: "cover",
      width: "100%",
    },
    [theme.breakpoints.down('sm')]: {
      objectFit: "cover",
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      objectFit: "cover",
      width: "100%",
    },

  },

  text: {
    [theme.breakpoints.down("xs")]: {
      padding: 40,
      marginLeft: "-40vh",
      marginTop: '40vh',
      width: 250,
      height: 250,
    },
    [theme.breakpoints.down('sm')]: {
      padding: 40,
      marginLeft: "-30vh",
      marginTop: '-5vh',
      width: 250,
      height: 250,
    },
    [theme.breakpoints.up("md")]: {
      padding: 40,
      marginLeft: "0vh",
      marginTop: '-35vh',
      width: 250,
      height: 250,
    },
  },
  box: {
    backgroundColor: `${properties.gray}`,
    [theme.breakpoints.down('sm')]: {
      padding: 40,
      marginLeft: 0,
      marginTop: '10vh',
      textAlign: "center"
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: 75,
      paddingBottom: 40,
      width: 250,
      height: 250,
      marginLeft: "30vh",
    }
  },
  rectangle: {
    background: "linear-gradient(to right, #ffffff 10%, #dfdee3 150%)",
    [theme.breakpoints.down('sm')]: {
      padding: 40,
      marginLeft: 0,
      marginTop: 0,
      textAlign: "center"
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: 50,
      paddingLeft: 30,
      paddingBottom: 40,
      width: 400,
      height: 250,
      marginLeft: "-10vh",
    }

  },
  rectangle1: {
    [theme.breakpoints.down('sm')]: {
      padding: 40,
      marginLeft: 0,
      marginTop: 0,
      textAlign: "center"
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: 50,
      paddingLeft: 30,
      paddingBottom: 40,
      width: 400,
      height: 250,
      marginLeft: "-10vh",
    }

  },
  box1: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: 60,
      paddingBottom: 60,
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: 75,
      paddingBottom: 40,
      width: 250,
      height: 250,
      marginLeft: "30vh",
    }
  },

  img: {
    height: 255,
    maxWidth: 400,
    overflow: 'hidden',
    display: 'block',
    width: '100%',
    marginLeft: "50vh",
  },
  hiddenMobile: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  carusel:{
    padding: '5% 15%' ,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  }
});
