import { SET_OFFERT, SET_HOME, RESET_ALL, DATA_APIHOME, 
    DATA_APISEARCH, MONEDA, CHECK_DEVICE,
    OPEN_SEARCH, LOAD, SEARCH_MOBILE, 
    SEE_MORE_CATEGORIES, GET_CATEGORIES_LIST
} from '../constants';


export const setOffertProps = (payload) => {
    return {
        type: SET_OFFERT,
        payload: payload
    }
}

export const setHome = (payload) => {
    return {
        type: SET_HOME,
        payload: payload
    }
}

export const setResetAll = () => {
    return {
        type: RESET_ALL,
    }
}

export const catchHomeApi = (getHomeData) => {
    return {
        type: DATA_APIHOME,
        getHomeData: getHomeData,
    }
}

export const setSearchCategories = () => {
    return {
        type: DATA_APISEARCH,
        payload: true,
        data: {
            products: [],
            size: 0,
        },
    }
}

export const searchCategories = (payload, dataSearchCategories, idCategoria) => {

    var arrayCopy = [...dataSearchCategories.Respuesta]
    var t2 = [];
    var size = 10;
    var products = [];

    if (arrayCopy.length > 0) {
        products = arrayCopy;
    }
    if (window.innerWidth > 959) {
        while (products.length > 0) {
            t2.push(products.splice(0, 8));
        }
    } else if (window.innerWidth < 959 && window.innerWidth > 425) {
        while (products.length > 0) {
            t2.push(products.splice(0, 6));
        }
    } else if (window.innerWidth <= 425) {
        size = 12;
        while (products.length > 0) {
            t2.push(products.splice(0, 3));
        }
    }
    var data = {
        products: t2,
        size: size,
    }

    return {
        type: DATA_APISEARCH,
        payload: payload,
        data: data,
    }

}

export const moneda = (data) => {
    return {
        type: MONEDA,
        moneda: data,
    }
}


export const checkDevice = (payload) => {
    return {
        type: CHECK_DEVICE,
        payload: payload,
    }
}

export const openBarraSearch = (payload) => {
    return {
        type: OPEN_SEARCH,
        payload: payload,
    }
}

export const loadingSearchMobile = (payload) => {
    return {
        type: LOAD,
        payload: payload,
    }
}

export const setData = (data) => {
    return {
        type: SEARCH_MOBILE,
        data: data,
    }
}


export const seeMoreCategories = (payload) => {
    return {
        type: SEE_MORE_CATEGORIES,
        payload: payload,
    }
}

export const setArbolCategorias = (data) => {
    data = data === undefined ? [] : JSON.parse(data.Categorias);
    return {
        type: GET_CATEGORIES_LIST,
        data: data,
    }
}