import { properties } from "../../../Constants";

export const styles = (theme) => ({
    contenedor:{
        height: '20vh',
        width: '100%'
    },
    contenedorImagenes:{
        textAlign: "center"
    },
    arrowLeft: {
        color: properties.primary,
        fontSize: 20,
        margin: 0,
    },
    arrowRight: {
        color: properties.primary,
        fontSize: 20,
        margin: 0,
    },
    float:{
        float: "right"
    }
});
