import { properties } from "../../../../Constants";

export const styles = (theme) => ({

    root: {
        "& .MuiInputBase-input": {
          color: "white"
        },
      },
    
      textColor: {
        input: {
          color: "white",
        },
        "& label.Mui-focused": {
          color: "white",
    
        },
        "& .MuiInput-underline:after": {
          borderBottomColor: "white",
          color: "white",
    
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "white",
            color: "white",
          },
          "&:hover fieldset": {
            borderColor: "white",
            color: "white",
          },
          "&.Mui-focused fieldset": {
            borderColor: "white",
            color: "white",
          },
        },
        color: "white",
    
        width: "80%",
      },
    
      letter: {
        color: "white",
        fontSize: 20,
      },
    
      Logo: {
        marginTop: "-5vh",
        marginLeft: 120,
    
        [theme.breakpoints.down("xs")]: {
          marginTop: "-15vh",
          marginLeft: "8vh",
        },
      },
    
      button: {
        padding: "3px 15px 3px 15px",
        color: `${properties.primary}`,
        borderRadius: "0px",
        marginTop: "5vh",
        marginRight: "-29vh",
        [theme.breakpoints.down("xs")]: {
          marginTop: "5vh",
          marginRight: "-20vh",
        },
      },





})