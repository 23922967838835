import {
  ARTICLES_PREFERENCES,
  MODAL_CHANGEPASSWORD,
  PREFERENCES_GUSSTA,
  PEDIDO_BY_USER,
  ORDER_SEARCH,
  PERSON_REQUEST,
  REQUEST_ADDRESS,
  ARTICLE_ORDERS,
  PAYMENT_ORDERS,
  MODAL_PEDIDOS_DETAILS_PAYLOAD,
  OPEN_GUSTA,
} from "../constants";

export const gusstaReducer = (state = {}, action) => {
  switch (action.type) {
    case ARTICLES_PREFERENCES:
      return {
        ...state,
        articlesPreferencesPaylod: action.articlesPreferencesPaylod,
        articlesPreferencesData: action.articlesPreferencesData,
      };

    case MODAL_CHANGEPASSWORD:
      return {
        ...state,
        showModalChangePass: action.payload,
      };

    case PREFERENCES_GUSSTA:
      return {
        ...state,
        preferencesGussta: action.preferencesGussta,
      };

    case PEDIDO_BY_USER:
      return {
        ...state,
        pedidoByUserPaylod: action.pedidoByUserPaylod,
        pedidoByUserData: action.pedidoByUserData,
      };
    case MODAL_PEDIDOS_DETAILS_PAYLOAD:
      return {
        ...state,
        showModalPedidosDetails: action.payload,
        idEstatusPedidos: action.idEstatusPedidos,
      };
    case ORDER_SEARCH:
      return {
        ...state,
        orderSearch: action.data,
      };
    case PERSON_REQUEST:
      return {
        ...state,
        personRequest: action.data,
      };
    case REQUEST_ADDRESS:
      return {
        ...state,
        requestAddress: action.data,
      };
    case ARTICLE_ORDERS:
      return {
        ...state,
        ordersArticle: action.data,
      };
    case PAYMENT_ORDERS:
      return {
        ...state,
        ordersPayment: action.data,
      };
    case OPEN_GUSTA:
      return {
        ...state,
        openGusta: action.payload,
      };
    default:
      return state;
  }
};
