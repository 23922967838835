import axios from 'axios';
import { setAlert } from '../_actionAlerts';
import { openPurchase } from '../_actionShoppingCar';
import { setLoader } from '../../Actions/_actionLoader';
import { getCarrito } from '../services/getCarrito';
import { DencryptData } from '../../../Tools/CryptoLocalStorage';
import { properties, ENCRYP_TYPE, LOGIN_DATA_LS } from '../../../Constants';
import { setLoginRenew } from '../_actionLoginRenew';

export const postInventario = (body) => {
    return dispatch => {

        dispatch(setLoader(true));
        const loginDataStorage = DencryptData(ENCRYP_TYPE.JSON, LOGIN_DATA_LS);
        const { token } = loginDataStorage
        let _headers = {
            Authorization: 'Bearer ' + token
        }
        axios({
            method: "POST",
            url: properties.endpoints.postInventario,
            headers: _headers,
            data: body
            // {
            //     "p_IdEmpresa": "EMP-001",
            //     "p_UserCompra": "USER-001",
            //     "v_Nombre": "Moi",
            //     "v_Apellido": "Graterol",
            //     "v_RazonSocial": "Company 1",
            //     "v_EmailPrincipal": "moisegrat12@gmail.com",
            //     "v_IdPais": null,
            //     "v_IdEstado": null,
            //     "v_IdCiudad": null,
            //     "v_Parroquia": null,
            //     "v_Municipio": null,
            //     "v_Direccion": null,
            //     "v_ZonaPostal": null,
            //     "v_IdTipoPersona": 1,
            //     "v_IdTipoIdExterno": "V",
            //     "v_Documento": "12345698",
            //     "v_Envio": true,
            //     "v_IdEstadoPedido": "VG",
            //     "v_IdCiudadPedido": "GUA",
            //     "v_IdMunicipioPedido": "SUC",
            //     "v_IdParroquiaPedido": "MQ",
            //     "v_DireccionPedido": "Direccion5",
            //     "listArticulos": [
            //       {
            //         "v_IdArticulo": "AR0000AD590117246950",
            //         "v_IdColor": "COLOR-003",
            //         "v_Cantidad": "4"
            //         "v_PrecioUnitario": "100"
            //       },
            //       {
            //         "v_IdArticulo": "AR0000AD590115FB7148",
            //         "v_IdColor": "COLOR-003",
            //         "v_Cantidad": "5"
            //       }
            //     ],
            //     "listMetodoPagos": [
            //       {
            //         "v_IdPagoItems": "PI-002",
            //         "v_IdMetodoPago": "MP-003",
            //         "v_Valor": "1"
            //       }
            //     ]
            //   }

        }).then((response) => {
            dispatch(setLoader(false))
            dispatch(openPurchase(false))
            dispatch(setAlert(true, response.data.func_msg, properties.severities.success, properties.props.success));
            dispatch(getCarrito());
        }).catch((error) => {
            var response = { ...error.response };
            dispatch(setLoader(false));
            dispatch(setLoginRenew(response));
        })
    }
}