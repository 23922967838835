import axios from 'axios';
import { setLoader } from '../_actionLoader';
import { detailProduct } from '../_actionProducts';
import { DencryptData, EncryptData } from '../../../Tools/CryptoLocalStorage';
import { properties, ENCRYP_TYPE, LOGIN_DATA_LS, ART_LS } from '../../../Constants';
import { setLoginRenew } from '../_actionLoginRenew';


export const getDetailProduct = (product) => {
    return dispatch => {
        EncryptData(product, ENCRYP_TYPE.STRING, ART_LS)
        let _headers = {
            idEmpresa: properties.companyID,
        }
        if (localStorage.getItem('loginBool')) {
            const loginDataStorage = DencryptData(ENCRYP_TYPE.JSON, LOGIN_DATA_LS);
            const { usuario } = loginDataStorage
            _headers.idUsuario = usuario.idUsuario
        }
        axios({
            method: "GET",
            url: `${properties.endpoints.article}${properties.idCanal}/id/${product}`,
            headers: _headers
        }).then((response) => {
            const { status, data } = response;
            if (status && status === 200) {
                dispatch(detailProduct(data));
                dispatch(setLoader(false));
            }
        }).catch((error) => {
            var response = { ...error.response };
            dispatch(setLoader(false));
            dispatch(setLoginRenew(response));
        });
    }
}