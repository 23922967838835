import axios from 'axios';
import { setLoader } from '../_actionLoader';
import { getDetailProduct } from './getDetailProduct';
import { properties } from '../../../Constants';
import { QUESTIONSANDANSWERS } from '../../constants';
import { setLoginRenew } from '../_actionLoginRenew';

export const getQuestionsAndAnswers = (idArticulo) => {
    return dispatch => {
        axios({
            method: "GET",
            url: properties.endpoints.QuestionsAndAnswersIdArticulo,
            headers: {
                idArticulo: idArticulo,
            }
        }).then((response) => {
            const { status, data } = response;
            if (status && status === 200) {
                const { Comentarios } = data
                var preguntas = QuestionsFormat(Comentarios);
                dispatch(getDetailProduct(idArticulo));
                dispatch({
                    type: QUESTIONSANDANSWERS,
                    payload: preguntas
                })
            }
        }).catch((error) => {

            var response = { ...error.response };
            dispatch(setLoader(false));
            dispatch(setLoginRenew(response));
        });
    }
}

export const dateFormat = (date) => {
    var newDate = new Date(date);
    var year = newDate.getFullYear();
    var month = newDate.getMonth() + 1;
    var day = newDate.getDate();
    var dateFormat = day + "/" + (month < 10 ? "0" + month : month) + "/" + year;

    return dateFormat;
}

export const QuestionsFormat = (Comentarios) => {
    var questions = [];
    Comentarios.forEach(com => {
        com.date = dateFormat(com.fechaCrea);
        if (com.idComentarioPadre === null) {
            questions.push(com);

        }
    })
    questions.forEach(quest => {
        var answers = [];
        Comentarios.forEach(com => {
            if (quest.idComentario === com.idComentarioPadre) {
                answers.push(com);
            }
        })
        var respuestas = answers.sort(function (a, b) { a = new Date(a.fechaCrea); b = new Date(b.fechaCrea); return a > b ? -1 : a < b ? 1 : 0; });
        quest.respuesta = respuestas;
    })
    var preguntas = questions.sort(function (a, b) { a = new Date(a.fechaCrea); b = new Date(b.fechaCrea); return a > b ? -1 : a < b ? 1 : 0; });
    return preguntas;
}