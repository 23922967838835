import { properties } from "../../../Constants";

export const styles = (theme) => ({
    principal: {
        overflowY: "auto",
        height: "50vh !important",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        "&::-webkit-scrollbar": {
            padding: "0.4em",
            margin: "0.4em",
            width: "0.4em",
            background: "#dfdee3",
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: properties.primary,
            background: properties.primary,
        },
    },
    contenedor: {
        padding: theme.spacing(2),
        borderBottom: '1px solid' + properties.gray
    },
    imagen: {
        textAlign: 'center'
    },
    texto: {
        display: 'flex',
        alignItems: 'center',
    },
    saveIcon: {
        color: properties.darkGray,
        fontSize: theme.spacing(2),
    },
    tipografia: {
        color: properties.darkGray,
    },
    xs:{
        [theme.breakpoints.up('xs')]: {
            marginBottom: 'auto',
            marginTop: 'auto'
        },
    }
});
