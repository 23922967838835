import axios from "axios";
import { setLoader } from "../_actionLoader";
import { DencryptData } from "../../../Tools/CryptoLocalStorage";
import { properties, ENCRYP_TYPE, LOGIN_DATA_LS } from "../../../Constants";
import { setAlert } from '../_actionAlerts';
import { openPurchase } from '../_actionShoppingCar';
import { setLoginRenew } from "../_actionLoginRenew";
import { postInventarioCotizaciones } from "./postInventoryQuote";
const funcMessage = JSON.parse(localStorage.getItem("funcMessage"));


export const postReleaseInventory = (body, option, solicitud) => {
    return (dispatch) => {

        dispatch(setLoader(true));
        const loginDataStorage = DencryptData(ENCRYP_TYPE.JSON, LOGIN_DATA_LS);

        axios({
            method: "POST",
            url: properties.endpoints.postReleaseInventory,
            headers: {
                Authorization: "Bearer " + loginDataStorage.token,
            },
            data: body,
        })
            .then((response) => {
                dispatch(setLoader(false));
                dispatch(openPurchase(false));
                if (solicitud){
                    dispatch(postInventarioCotizaciones(solicitud));
                }
                if(option){
                    dispatch(setAlert(true, 'Finalizó el tiempo para realizar la compra', properties.severities.warning, properties.props.error))

                }
                if(!option){
                  dispatch(setAlert(true, response.data.func_msg, properties.severities.success, properties.props.success));

                }
            })
            .catch((error) => {
                var response = { ...error.response };
                dispatch(setLoader(false));
                dispatch(setLoginRenew(response));
                dispatch(setLoader(false))
                try {
                    dispatch(setAlert(true, response.data.tecn_msg, properties.severities.warning, properties.props.error));

                } catch (error) {
                    dispatch(setAlert(true, funcMessage.MSG_001, properties.severities.warning, properties.props.error))

                }
            });
    };
};
