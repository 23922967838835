import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, withStyles, Typography } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";

//Componentes
import { styles } from "./constants.js";
import eliminar from "./img/papelera.svg";
import { toMoneyFormat } from "../../../Tools";

/*Actions */
import { openPurchase, setCotizar } from "../../../Redux/Actions/_actionShoppingCar";
import { getEstados } from "../../../Redux/Actions/services/getEstados";
import { getEstadosBuyProcess } from "../../../Redux/Actions/services/getEstadosBuyProcess";
import { getShipping } from "../../../Redux/Actions/services/getShipping";
import { getBanks } from "../../../Redux/Actions/services/getBanks";
import { getPayMethod } from "../../../Redux/Actions/services/getPayMethod";
import { getDocumentsId } from "../../../Redux/Actions/services/getDocumentsId";
import { deleteCarrito } from "../../../Redux/Actions/services/deleteCarrito";
import { sumatoriaTotalCarrito } from "../../../Redux/Actions/_actionShoppingCar";
import { setCarrito } from "../../../Redux/Actions/_actionShoppingCar";
import { postInventarioReserva } from "../../../Redux/Actions/services/postInventarioReserva";
import { DencryptData } from "../../../Tools/CryptoLocalStorage";
import { getParishes } from "../../../Redux/Actions/services/getParishes";
import { setLoader } from "../../../Redux/Actions/_actionLoader.js";
import { putCarrito } from "../../../Redux/Actions/services/putCarrito.js";

/*Icons*/
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import { properties, ENCRYP_TYPE, LOGIN_DATA_LS } from "../../../Constants";

class CarritoProductos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      change: false,
      articles: []
    };
  }

  componentDidMount() {
    this.setState({ items: this.props.datos });
  }

  componentDidUpdate() {
    const { items, change } = this.state;
    if (change) {
      const loginDataStorage = DencryptData(ENCRYP_TYPE.JSON, LOGIN_DATA_LS);
      items.map(item => {
        if (item.change) {
          let body = {
            "IdUserCompra": loginDataStorage.usuario.idUsuario,
            "IdCarrito": item.idCarritoCompra,
            "IdArticulo": item.idArticulo,
            "CantidadModificar": parseInt(item.cantidad)
          }
          this.props.putCarrito(body);
        }
      })
    }
  }

  calcularCantidad = (i, tipo) => {
    let items = [...this.props.datos];
    let item = { ...items[i] };
    let cantidadActual = item.cantidad;
    let calculo = 0;

    switch (tipo) {
      case 1:
        calculo = parseInt(cantidadActual) - 1;

        if (cantidadActual <= 1) calculo = 0;
        break;

      default:
        calculo = parseInt(cantidadActual) + 1;
        break;
    }

    let cant_art = calculo - item.cantidad;
    item.cantidad = calculo;
    item.cant_unit = cant_art;
    item.change = true;
    items[i] = item;
    this.props.setCarrito(items);
    this.setState({ items }, () => this.props.sumatoriaTotalCarrito(items));
    this.setState({ change: true });
  };

  deleteItem = (idArticulo, idCarritoCompra) => {
    this.props.deleteCarrito(idArticulo, idCarritoCompra);
  };

  purchaseProcess = () => {
    this.props.setLoader(true);
    const { items } = this.state;
    var listaArticulos = [];
    localStorage.removeItem("BillingInformation");
    localStorage.removeItem("Order");
    localStorage.removeItem("Payment");
    const loginDataStorage = DencryptData(ENCRYP_TYPE.JSON, LOGIN_DATA_LS);
    const { usuario } = loginDataStorage;
    const { idUsuario } = usuario;
    items.forEach((data) => {
      listaArticulos.push({
        v_IdArticulo: data.idArticulo,
        v_IdColor: data.colorTrama,
        v_Cantidad: data.cantidad,
      });
    });
    var body = {
      IdEmpresa: properties.companyID,
      IdUsuario: idUsuario,
      listArticulos: listaArticulos,
      Usuario: idUsuario,
    };
    this.props.postInventarioReserva(
      body,
      this.state.items,
      this.props.sumatoria
    );
  };

  quotationProcess = () => {
    const { items } = this.state;
    var listaArticulos = [];
    this.props.getEstados();
    this.props.getDocumentsId();
    items.forEach((data) => {
      listaArticulos.push({
        v_IdArticulo: data.idArticulo,
        v_IdColor: data.colorTrama,
        v_Cantidad: data.cantidad,
        v_PrecioUnitario: data.precio,
      });
    });
    var solicitud = {
      solicitud: properties.cotizacion,
      listArticulos: listaArticulos,
    };
    this.props.setCotizar(true, solicitud);
  };

  validarCampo = (i, event) => {
    let valor = event.target.value;
    let items = [...this.props.datos];
    let item = { ...items[i] };
    let pattern = /^[0-9\b]+$/;

    if (valor === null || valor === "") {
      valor = 0;
    } else if (valor > 0) {
      valor = valor.replace(/^(0+)/g, "");
    }

    if (pattern.test(valor)) {
      item.cantidad = valor;
      item.change = true;
      items[i] = item;
      this.props.setCarrito(items);
      this.setState({ items }, () => this.props.sumatoriaTotalCarrito(items));
      this.setState({ change: true });
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Grid container className={classes.contenedor}>
          <Grid xs={12} className={classes.table}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                {!this.props.isMobile ?
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="subtitle2"
                        className={classes.tipografia}
                      >
                        Cantidad
                      </Typography>
                    </TableCell>
                    <TableCell align="center">Color</TableCell>
                    <TableCell align="center">Total USD</TableCell>
                    {this.props.moneda && (
                      <TableCell align="center">
                        Total {this.props.moneda.siglas}
                      </TableCell>
                    )}
                    <TableCell></TableCell>
                  </TableRow>
                  :
                  <></>
                }
              </TableHead>
              <TableBody>
                {this.props.datos &&
                  this.props.datos.map((row, i) => (
                    <>
                      {!this.props.isMobile ?
                        <TableRow key={i}>
                          <TableCell align="center">
                            <img src={row.url} alt="" width="80" />
                          </TableCell>
                          <TableCell>
                            <Grid container>
                              <Grid xs={12}>
                                <Typography
                                  variant="subtitle1"
                                  className={classes.tipografia}
                                >
                                  <Box fontWeight={700}>{row.descripcionCorta}</Box>
                                </Typography>
                              </Grid>
                              <Grid xs={12}>
                                <Typography
                                  className={classes.tipografia}
                                  variant="subtitle2"
                                >
                                  Precio Unitario: <b>{row.valorPromo || row.precio} USD</b>
                                </Typography>
                              </Grid>
                            </Grid>
                          </TableCell>
                          <TableCell align="center">
                            <div id="iconoMas">
                              <KeyboardArrowUpIcon
                                className={classes.iconArrow}
                                onClick={() => this.calcularCantidad(i, 0)}
                              />
                            </div>
                            <TextField
                              className={classes.input}
                              name="cantidad"
                              type="text"
                              variant="outlined"
                              square={true}
                              inputProps={{ maxLength: 4, class: "colorInput" }}
                              size="small"
                              value={row.cantidad}
                              onChange={(event) => this.validarCampo(i, event)}
                            />
                            <div id="iconoMenos">
                              <KeyboardArrowDownIcon
                                className={classes.iconArrow}
                                onClick={() => this.calcularCantidad(i, 1)}
                              />
                            </div>
                          </TableCell>
                          <TableCell align="center">
                            <Box display="flex" justifyContent="center">
                              {row.isColor ? (
                                <Box
                                  borderRadius="50%"
                                  style={{
                                    border: "1px solid black",
                                    width: "30px",
                                    height: "30px",
                                    backgroundColor: row.color,
                                  }}
                                />
                              ) : (
                                <Box
                                  borderRadius="50%"
                                  style={{
                                    border: "1px solid black",
                                    width: "30px",
                                    height: "30px",
                                    backgroundImage: "url(" + row.trama + ")",
                                  }}
                                />
                              )}
                            </Box>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              variant="subtitle1"
                              className={classes.tipografia}
                            >
                              <Box fontWeight={800}>
                                {toMoneyFormat(
                                  (row.cantidad * (row.valorPromo || row.precio)).toFixed(2)
                                )}
                                
                              </Box>
                            </Typography>
                          </TableCell>
                          {this.props.moneda && (
                            <TableCell align="center">
                              <Typography
                                variant="subtitle1"
                                className={classes.tipografia}
                              >
                                <Box fontWeight={800}>
                                  {toMoneyFormat(
                                    (
                                      parseFloat(row.cantidad * (row.valorPromo || row.precio)) *
                                      this.props.moneda.tasa
                                    ).toFixed(2)
                                  )}
                                </Box>
                              </Typography>
                            </TableCell>
                          )}
                          <TableCell align="center">
                            <IconButton
                              aria-label="Delete"
                              onClick={() =>
                                this.deleteItem(row.idArticulo, row.idCarritoCompra)
                              }
                            >
                              <img
                                src={eliminar}
                                alt=""
                                className={classes.iconTrash}
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        :
                        <TableRow key={i} style={{ borderBottom: '1px solid #8080805c' }}>
                          <Grid container style={{ marginTop: 20, marginBottom: 20 }}>
                            <Grid xs={12}>
                              <Typography
                                variant="subtitle1"
                                className={classes.tipografia}
                              >
                                <Box fontWeight={700}>{row.descripcionCorta}</Box>
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container>
                                <Grid item xs={5}>
                                  <img src={row.url} alt="" width="100%" />
                                </Grid>
                                <Grid item xs={7}>
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <Grid container>
                                        <Grid item xs={6} style={{ display: 'flex', alignItems: 'center', paddingTop: 5 }}>
                                          <div id="iconoMas">
                                            <RemoveIcon
                                              className={classes.iconArrow}
                                              onClick={() => this.calcularCantidad(i, 1)}
                                            />
                                          </div>
                                          <TextField
                                            className={classes.input}
                                            name="cantidad"
                                            type="text"
                                            variant="outlined"
                                            square={true}
                                            inputProps={{ maxLength: 4, class: "colorInput" }}
                                            size="small"
                                            value={row.cantidad}
                                            onChange={(event) => this.validarCampo(i, event)}
                                          />
                                          <div id="iconoMenos">
                                            <AddIcon
                                              className={classes.iconArrow}
                                              onClick={() => this.calcularCantidad(i, 0)}
                                            />
                                          </div>
                                        </Grid>
                                        <Grid item xs={6} style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                                          {row.isColor ? (
                                            <Box
                                              style={{
                                                border: "1px solid gray",
                                                width: "30px",
                                                height: "30px",
                                                backgroundColor: row.color,
                                                float: 'right',
                                                marginRight: '25px'
                                              }}
                                            />
                                          ) : (
                                            <Box
                                              style={{
                                                border: "1px solid gray",
                                                width: "30px",
                                                height: "30px",
                                                backgroundImage: "url(" + row.trama + ")",
                                                float: 'right',
                                                marginRight: '25px'
                                              }}
                                            />
                                          )}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item xs={12} style={{ paddingTop: 5 }}>
                                      <Typography
                                        className={classes.tipografia}
                                        variant="subtitle2"
                                      >
                                        Precio Unitario: <b>{row.valorPromo || row.precio}  USD</b>
                                      </Typography>
                                      <Typography
                                        variant="subtitle2"
                                        className={classes.tipografia}
                                      >
                                        <Box fontWeight={700}>
                                          Total:&#10240;
                                          {toMoneyFormat(
                                            (row.cantidad * (row.valorPromo || row.precio)).toFixed(2) + " "
                                          )}
                                          USD
                                        </Box>
                                      </Typography>
                                      {this.props.moneda && (
                                        <TableCell align="center">
                                          <Typography
                                            variant="subtitle2"
                                            className={classes.tipografia}
                                          >
                                            <Box fontWeight={800}>
                                              {toMoneyFormat(
                                                (
                                                  parseFloat(row.cantidad * (row.valorPromo || row.precio)) *
                                                  this.props.moneda.tasa
                                                ).toFixed(2)
                                              )}
                                            </Box>
                                          </Typography>
                                        </TableCell>
                                      )}
                                    </Grid>
                                    <Grid item xs={12}>
                                      <IconButton
                                        style={{ float: 'right', marginRight: 20 }}
                                        aria-label="Delete"
                                        onClick={() =>
                                          this.deleteItem(row.idArticulo, row.idCarritoCompra)
                                        }
                                      >
                                        <img
                                          src={eliminar}
                                          alt=""
                                          className={classes.iconTrash}
                                        />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </TableRow>
                      }
                    </>
                  ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid xs={12} md={12} className={classes.tableFoot}>
            <Grid container>
              <Grid xs={12} md={6}></Grid>
              <Grid xs={12} md={6}>
                <Grid container>
                  <Grid xs={3}>
                    <Typography variant="h5">Total</Typography>
                  </Grid>
                  <Grid xs={5}>
                    <Typography variant="h5">
                      <Box fontWeight={800}>
                        {toMoneyFormat(
                          (this.props.sumatoria
                            ? this.props.sumatoria
                            : 0
                          ).toFixed(2) + " "
                        )}
                        USD
                      </Box>
                    </Typography>
                  </Grid>
                  {this.props.datos.length > 0 && (
                    <Grid xs={4}>
                      <Button
                        style={{ borderRadius: 0, width: 85 }}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={this.purchaseProcess}
                      >
                        COMPRAR
                      </Button>
                      <Button
                        style={{ borderRadius: 0, marginTop: 5, width: 85 }}
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={this.quotationProcess}                      >
                        Cotizar
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

const carritoProductos = withStyles(styles, { withTheme: true })(
  CarritoProductos
);

function mapStateToProps(state) {
  return {
    datos: state.purchase.data,
    sumatoria: state.purchase.totalSumaCarrito,
    moneda: state.homeProps.moneda,
    isMobile: state.homeProps.isMobile
  };
}

export default connect(mapStateToProps, {
  openPurchase,
  getEstados,
  getEstadosBuyProcess,
  getShipping,
  getBanks,
  getPayMethod,
  getDocumentsId,
  setCotizar,
  deleteCarrito,
  sumatoriaTotalCarrito,
  setCarrito,
  setLoader,
  postInventarioReserva,
  getParishes,
  putCarrito
})(carritoProductos);
