import CryptoJS from "crypto-js";
import { SECRET_KEY } from '../Constants';

export const EncryptData = (data, type, key) => {
    var result = "";
    switch (type) {
        case 'JSON':
            result = CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
            localStorage.setItem(key, result)

            break;
        case 'String':
            result = CryptoJS.AES.encrypt(data, SECRET_KEY).toString();
            localStorage.setItem(key, result)

            break;

        default:
            break;
    }
}

export const DencryptData = (type, key) => {
    var originalData = "";
    var storageData = localStorage.getItem(key);
    var decrypt = CryptoJS.AES.decrypt(storageData, SECRET_KEY);
    decrypt.toString(CryptoJS.enc.Utf8)
    switch (type) {
        case 'JSON':
            originalData = JSON.parse(decrypt.toString(CryptoJS.enc.Utf8))

            return originalData;
        case 'String':
            originalData = decrypt.toString(CryptoJS.enc.Utf8)

            return originalData;

        default:
            break;
    }

}