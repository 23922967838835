import { properties } from "../../../Constants";

export const styles = (theme) => ({
  root: {
    "& .MuiInputBase-input": {
      color: "white"
    },
  },
  textColor: {
    input: { color: "white" },
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
      color: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
        color: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
        color: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
        color: "white",
      },
    },
    color: "white",
    width: "80%",
  },

  letter: {
    color: "white",
    fontSize: 15,
  },

  button: {
    padding: "3px 15px 3px 15px",
    color: `${properties.primary}`,
    borderRadius: "0px",
    marginTop: "15vh",
    marginRight: "-29vh",
    [theme.breakpoints.down("xs")]: {
      marginTop: "5vh",
      marginRight: "-20vh",
    },
  },
  Logo: {
    marginTop: "-5vh",
    marginLeft: 120,

    [theme.breakpoints.down("xs")]: {
      marginTop: "-15vh",
      marginLeft: "8vh",
    },
  },

  parraf: {
    padding: "0px 60px 0px 0px",
    color: "white",
  },

  title: {
    textAlign: "left",
    color: "white",
    padding: "0px 0px 0px 35px",
  },

});