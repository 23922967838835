import React, { Component } from 'react';
import { connect } from 'react-redux';
import Carousel from 'react-bootstrap/Carousel';
import "bootstrap/dist/css/bootstrap.min.css"
import { LazyLoadImage } from 'react-lazy-load-image-component';

class CarouselData extends Component {
    constructor(props) {
        super(props);
        this.state = ({
            index: 0
        })
    }

    render() {
        return (
            <Carousel nextIcon="" prevIcon="" indicators={false} controls={false} style={{ zIndex: 1 }}>
                {this.props.getHomeData.carousel.length > 0 ?
                    this.props.getHomeData.carousel.map(element => (
                        <Carousel.Item>
                            <LazyLoadImage
                                style={{ width: '100%', aspectRatio: '16/6' }}
                                alt={element.descripcion}
                                effect="blur"
                                visibleByDefault={true}
                                src={element.imagen}
                            />
                        </Carousel.Item>
                    ))
                    :
                    <>
                        <LazyLoadImage
                            style={{ width: '100%', aspectRatio: '16/6' }}
                            alt='f'
                            effect="blur"
                            visibleByDefault={true}
                            src={'https://firebasestorage.googleapis.com/v0/b/guss-muebles-web.appspot.com/o/carrusel%2Fcarrusel%201.jpg?alt=media&token=a1a9d653-a064-45b4-8c00-a8dc21868803'}
                        />
                    </>
                }
            </Carousel>
        );
    }
}

function mapStateToProps(state) {
    return {
        getHomeData: state.homeProps.getHomeData,
    };
}

export default connect(
    mapStateToProps, {
})(CarouselData);

