import axios from 'axios';
import { setLoader } from '../_actionLoader';
import { DencryptData } from '../../../Tools/CryptoLocalStorage';
import { properties, ENCRYP_TYPE, LOGIN_DATA_LS } from '../../../Constants';
import { setLoginRenew } from '../_actionLoginRenew';
import { setAlert } from '../_actionAlerts';




export const postCreateQuestions = (body) => {
    return dispatch => {
        const loginDataStorage = DencryptData(ENCRYP_TYPE.JSON, LOGIN_DATA_LS);
        const { token } = loginDataStorage
        axios({
            method: "POST",
            url: properties.endpoints.QuestionsCreate,
            headers: {
                Authorization: 'Bearer ' + token
            },
            data: body

        }).then((response) => {
            // dispatch(setLoader(false));
            dispatch(setAlert(true, response.data.func_msg, properties.severities.success, properties.props.success));

            // const { CreateCommentsResponse } = response.data;
            // var questions = QuestionsFormat(CreateCommentsResponse);
            dispatch(setLoader(false))
            // dispatch({
            //     type: QUESTIONSANDANSWERS,
            //     payload: questions
            // })
            // }

        }).catch((error) => {


            var response = { ...error.response };
            dispatch(setLoader(false));
            dispatch(setLoginRenew(response));


            // var response = { ...error.response };
            // dispatch(setLoader(false))
            // try {
            //     dispatch(setAlert(true, response.data.func_msg, properties.severities.warning));
            // } catch (error) {
            //     dispatch(setAlert(true, funcMessage.MSG_001, properties.severities.warning));
            // }
        });
    }
}