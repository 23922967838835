/* DEPENDENCIAS */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Typography, withStyles } from "@material-ui/core";
import Carousel from 'react-bootstrap/Carousel';
import "bootstrap/dist/css/bootstrap.min.css"
import { Grid } from "@material-ui/core";
import maqueta1 from "../img/maqueta1.jpg";
import maqueta2 from "../img/maqueta2.jpg";
import maqueta3 from "../img/maqueta3.jpg";
import maqueta4 from "../img/maqueta4.jpg";
import logo from "../../Home/img/logo.png";
import furniture from "../img/MuebleNosotros.png";

/*CONSTANTS*/
import { styles } from "./constants";
import { properties } from "../../../Constants";
import Fade from 'react-reveal/Fade';

import { setHeaderProps } from "../../../Redux/Actions/_actionHeader";

class US extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
    this.props.setHeaderProps(properties.gray, "black");
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  render() {

    const { classes } = this.props;
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <br />
          <br />
          <br />
          <Grid container spacing={0} >
            <Grid item xs={false} sm={12} md={12} lg={12}>
              <Grid container spacing={0} >
                {/* //LOGO */}
                <Grid item xs={6} >
                  <div className={classes.boxGrey}  >
                  </div>
                </Grid>
                <Grid item xs={false} md={6} style={{ marginTop: "15vh", marginLeft: "-34vh" }}>
                  <img alt="" src={logo} className={classes.logo} />
                </Grid>
                {/* MUEBLE */}
                <Grid item xs={false} md={10} style={{ marginTop: "-35vh", marginLeft: "-10vh" }}>
                  <img alt="" src={furniture} className={classes.mueble} />
                </Grid>
                {/* PARRAFO */}
                <Grid item xs={false} md={2} className={classes.hiddenMobile}>
                  <div className={classes.text}   >
                    <Typography style={{ textAlign: "left", fontSize: 26 }}  >
                      <strong> 2018 </strong>
                    </Typography>
                    <Typography style={{ textAlign: "left", fontSize: 16 }}   >
                      Guss Muebles te ayuda a convertir tus ideas en Diseños
                      Elegantes y Funcionales, creando hermosas composiciones
                      que armonizan con tu personalidad.
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container   >
            <Grid item xs={12} sm={12} md={6} lg={6}   >
              <div className={classes.box1}>
                <Typography style={{ textAlign: "center", fontSize: 35, color: properties.primary }}  >
                  Nuestra
                </Typography>
                <Typography style={{ textAlign: "center", fontSize: 35, color: properties.secondary }}>
                  Misión
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}  >
              <div className={classes.rectangle}>
                <Typography style={{ textAlign: "left", fontSize: 16, }}    >
                  Ofrecer a nuestros Clientes Productos que hagan juego con sus Ideas de
                  Diseño para ambientar el espacio deseado, dándole personalidad,
                  estilo y funcionalidad.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ marginTop: '30px' }}>
          <Grid container  >
            <Grid item xs={12} sm={12} md={6} lg={6} >
              <div className={classes.box}  >
                <Typography style={{ textAlign: "center", fontSize: 35 }}  >
                  Nuestra
                </Typography>
                <Typography style={{ textAlign: "center", fontSize: 35, color: `${properties.secondary}` }}>
                  Visión
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} >
              <div className={classes.rectangle1}>
                <Typography style={{ textAlign: "left", fontSize: 16, }}  >
                  Ser el aliado en la transformación de los espacios de
                  preferencia de nuestros Clientes, logrando
                  crear experiencias inolvidables a través de nuestros productos y servicios.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid container direction="row" className={classes.carusel}>
          <Grid item xs={1}></Grid>
          <Grid item xs={10} style={{ height: '60vh' }}>
            <Fade bottom >
              <Carousel>
                <Carousel.Item interval={3000}>
                  <img
                    className="d-block w-100"
                    src={maqueta1}
                    alt="First slide"
                    style={{
                      height: "50vh", objectFit: 'cover',
                    }}
                  />
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                  <img
                    className="d-block w-100"
                    src={maqueta2}
                    alt="Second slide"
                    style={{ height: "50vh", objectFit: 'cover', }}
                  />
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                  <img
                    className="d-block w-100"
                    src={maqueta3}
                    alt="Third slide"
                    style={{ height: "50vh", objectFit: 'cover', }}
                  />
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                  <img
                    className="d-block w-100"
                    src={maqueta4}
                    alt="Third slide"
                    style={{ height: "50vh", objectFit: 'cover', }}
                  />
                </Carousel.Item>
              </Carousel>
            </Fade>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const us = withStyles(styles, { withTheme: true })(US);

function mapStateToProps(state) {
  return {
    getUs: state.footerProps.getUs,
  };
}

export default connect(mapStateToProps, {
  setHeaderProps
})(us);