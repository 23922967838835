import {
  OPEN_LOGIN,
  BUTTOM_FORM,
  LOGIN_SUCCESSFUL,
  KEY_RECOVERY,
  UNLOCKING
} from "../constants";

export const openLogin = (payload) => {
  return {
    type: OPEN_LOGIN,
    payload: payload,
  };
};

export const buttomForm = (payload) => {
  return {
    type: BUTTOM_FORM,
    payload: payload,
  };
};

export const loginSuccessful = (payload, loginData) => {
  return {
    type: LOGIN_SUCCESSFUL,
    payload: payload,
    loginData: loginData,
  };
};

export const setKeyRecovery = (payload) => {
  return {
    type: KEY_RECOVERY,
    payload: payload,
  };
};

export const setUnlocking = (payload) => {
  return {
    type: UNLOCKING,
    payload: payload,
  };
};
