import React, { Component } from "react";
import { connect } from "react-redux";
import {
  InputLabel,
  MenuItem,
  Select,
  withStyles,
} from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { Grid, Typography } from "@material-ui/core";
import formulario from "../../Home/img/formulario.png";

/*TOOLS*/
import ReCAPTCHA from "react-google-recaptcha";

/*ACCIONES*/
import { openLogin } from "../../../Redux/Actions/_actionLogin";
import { buttomForm } from "../../../Redux/Actions/_actionLogin";
import { postLogin } from "../../../Redux/Actions/services/postLogin";
import { setLoader } from "../../../Redux/Actions/_actionLoader";
import { postUser } from "../../../Redux/Actions/services/postUser";
import { setAlert } from "../../../Redux/Actions/_actionAlerts";
import { setKeyRecovery } from "../../../Redux/Actions/_actionLogin";
import { setUnlocking } from '../../../Redux/Actions/_actionLogin';
import { validateValues } from '../../../Tools/index';

/*CONSTANTES*/
import { properties } from "../../../Constants";
import { styles } from "./constants";
import logo from "../img/logo.png";

const labels = JSON.parse(localStorage.getItem("labels"));

const constTextField = [
  "name",
  "surName",
  "razonSocial",
  "email",
  "password",
  "confirmPassword",
];

const boolTextField = [
  "nameBool",
  "surNameBool",
  "razonSocialBool",
  "emailBool",
  "passwordBool",
  "confirmPasswordBool",
  "correoBool",
  "claveBool",
  "confirmacionBool",
];

class login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      open: true,
      data: "Natural",
      formatEmail: labels.EMAIL_FORMAT,
      userFormat: labels.USER_FORMAT,
      required: labels.REQUIRED,
      passwordMessage: labels.PASSWORD_MESSAGE,
      passwordConfirmation: labels.PASSWORD_CONFIRMATION,
      name: "",
      surName: "",
      email: "",
      razonSocial: "",
      password: "",
      confirmPassword: "",
      emailLogin: '',
      passwordLogin: '',
      boolPasswLogin: "",
      boolEmailLogin: "",
      nameBool: false,
      surNameBool: false,
      razonSocialBool: false,
      emailBool: false,
      passwordBool: false,
      confirmPasswordBool: false,
      correoBool: false,
      claveBool: false,
      confirmacionBool: false,
      type: 0,
      minHeight: "80vh",
      captcha: undefined
    };
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.captcha = React.createRef();
  }

  handleChangeSelect = (event) => {
    this.setState({ data: event.target.value });
    this.setState({
      name: "",
      surName: "",
      razonSocial: "",
      email: "",
      businessName: "",
      password: "",
      confirmPassword: "",
      captcha: undefined,
      nameBool: false,
      surNameBool: false,
      razonSocialBool: false,
      emailBool: false,
      passwordBool: false,
      confirmPasswordBool: false,
      correoBool: false,
      claveBool: false,
      confirmacionBool: false,
    });
  };

  /*Cerrar el modal de Login y Registro*/
  handleClose = () => {
    this.props.openLogin(false);
  };

  /*functionUnlocking hace el llamado al modal de desbloqueo de usuario*/
  functionUnlocking = () => {
    this.props.setUnlocking(true)
  };

  handleCloseAlert = () => {
    this.props.setAlert(false);
    this.setState({ open: false });
  };

  /*Valida que los campos email y password del formulario "Login" no estén vacíos*/
  validateInputsLogin = () => {
    if (this.state.emailLogin !== "" && this.state.passwordLogin !== "") {
      this.setState({ buttonLoginDisabled: false });
    } else {
      this.setState({ buttonLoginDisabled: true });
    }
  };

  handleTab = (type, minHeight) => {
    this.setState({ type: type, minHeight: minHeight, });
  };

  /* Detecta un cambio en el reCaptcha */
  onChange = () => {
    if (this.captcha.current.getValue()) {
      this.setState({ captcha: true })
    }
  };

  /*Integración de Inicio sesión*/
  login = () => {
    this.props.setLoader(true);
    let headers = {
      idCanal: properties.idCanal,
      idEmpresa: properties.companyID,
      correo: this.state.emailLogin,
      password: this.state.passwordLogin,
    };
    this.props.postLogin(headers);
  };

  /*VALIDACION DE CAMPOS*/
  onChangeValues = (event) => {
    const { name, value, id } = event.target;
    let boolValue = validateValues(value, id)
    if (boolValue && id !== "email") {
      this.setState({ [name]: value, [name + 'Bool']: false });
      if (id === 'password' && this.state.confirmPassword !== '') {
        if (value === this.state.confirmPassword) {
          this.setState({ confirmacionBool: false });
        } else {
          this.setState({ confirmacionBool: true });
        }
      }
    }
    if (id === "email") {
      if (boolValue) {
        this.setState({ [name]: value, [name + 'Bool']: false, correoBool: false });
      } else {
        this.setState({ [name]: value, [name + 'Bool']: true, correoBool: true });
      }
    }
    if (id === "password") {
      if (boolValue) {
        this.setState({ [name]: value, [name + 'Bool']: false, passwordBool: false });
      } else {
        this.setState({ [name]: value, passwordBool: true })
      }
    }
  };


  handlePasswordConfirm = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
    this.setState({ [name + 'Bool']: false });
    if (value === this.state.password) {
      this.setState({
        confirmacionBool: false,
        confirmPasswordBool: false,
      });
    } else {
      this.setState({
        confirmacionBool: true,
        confirmPasswordBool: true,
      });
    }
  };

  /* integrationRegister: llamado al API Registar usuario y despues de ejecutar el API, limpia los campos*/
  integrationRegister = () => {
    /**Bucle para pintar alerta en inputs vacío**/
    constTextField.forEach((textField, i) => {
      this.validationForm(this.state[textField], "", boolTextField[i], true);
    })
    /* Validación Captcha */
    if (!this.captcha.current.getValue()) {
      this.setState({ captcha: false })
      return;
    }
    /**Bucle para validar cuántos inputs están llenos**/
    var cont = 0;
    var inputs = this.state.data === "Natural" ? 5 : 4;
    constTextField.forEach((textField1, i) => {
      //  incrementa el contador cuando un campo ya fue llenado
      if (this.state[textField1] !== "" && !this.state[boolTextField[i]]) {
        if ((this.state.data === "Natural" && textField1 !== "razonSocial") || this.state.data === "juridico")
          cont = cont + 1;
      }
    })

    if (cont === inputs) {
      let body = {
        idUsuario: "null",
        password: this.state.password,
        idEmpresa: properties.companyID,
        idPersona: "null",
        idPerfil: "null",
        idRol: "ROL-003",
        nombre: this.state.name === undefined ? "null" : this.state.name,
        apellido: this.state.surName === undefined ? "null" : this.state.surName,
        emailPrincipal: this.state.email,
        displayName: "null",
        tipoPersona: this.state.data === "Natural" ? 1 : 0,
        razonSocial: this.state.razonSocial === undefined ? "null" : this.state.razonSocial,
      };
      this.props.postUser(body);
      this.captcha.current.reset();
      this.setState({
        name: "", surName: "", razonSocial: "", email: "",
        businessName: "", password: "", confirmPassword: "", captcha: undefined
      });
    }
  };

  /**-Validación de inputs esta función se llama al momento de darle click a "continuar" y haya algún campo vacío (se pinta error)
  * -En caso de que sea tipeado algo, se borrará el error pintado**/
  validationForm = (state, value, boolean, valueState) => {
    if (state === value) {
      this.setState({
        [boolean]: valueState,
      });
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid item xs={12}>
        <Slide
          direction="left"
          timeout={500}
          in={this.props.loginPayload}
          mountOnEnter
          unmountOnExit
        >
          <div
            className={classes.root}
            style={{ position: "absolute", top: 60, right: 0 }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={this.state.value}
                aria-label="Vertical tabs example"
                style={{
                  width: 55,
                  backgroundColor: properties.gray,
                  minHeight: this.state.minHeight,
                }}
              >
                <Button onClick={this.handleClose}>
                  <i style={{ color: "black" }} class="icon-guss_close" />
                </Button>

                <Tab
                  label={labels.LOGIN_BUTTOM}
                  className={classes.transform}
                  onClick={() => this.handleTab(0, "80vh")}
                />
              </Tabs>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} style={{ background: "linear-gradient(to top, #013348 0%, #0585ba 100%)", minHeight: this.state.minHeight, }}>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Typography variant="h5" className={classes.title}>
                  {labels.WELCOME_LB}
                </Typography>
              </Grid>

              <Grid item xs={6} sm={6} md={6} lg={6}>
                <img
                  src={formulario}
                  alt=""
                  width={85}
                  style={{ marginLeft: 220 }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div>
                  <form
                    id="publicForm"
                    style={{
                      position: "absolute",
                      marginLeft: 30,
                      top: 150,
                    }}
                  >
                    {this.state.type === 0 ? (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container
                          spacing={3}  >
                          <Grid item xs={12}>
                            <TextField
                              error={this.state.boolEmailLogin}
                              helperText={
                                this.state.boolEmailLogin
                                  ? this.state.formatEmail
                                  : ""
                              }
                              className={classes.textColor}
                              label={<span style={{ color: 'white' }}> {labels.USER_LB} </span>}
                              name="emailLogin"
                              id="email"
                              type="text"
                              value={this.state.emailLogin}
                              onChange={(event) => {
                                this.onChangeValues(event);
                              }}
                              inputProps={{
                                maxLength: 40,
                                class: "colorInput"
                              }}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              error={this.state.boolPasswLogin}
                              helperText={
                                this.state.boolPasswLogin
                                  ? this.state.passwordMessage
                                  : ""
                              }
                              label={<span style={{ color: 'white' }}>{labels.PASSWORD_LB}  </span>}
                              className={classes.textColor}
                              name="passwordLogin"
                              value={this.state.passwordLogin}
                              type="password"
                              id="password"
                              onChange={this.onChangeValues}
                              inputProps={{
                                maxLength: 30,
                              }}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <Typography
                              variant="body2"
                              style={{ fontSize: "0.60rem", marginLeft: "5vh", width: "120px", }} >
                              <a href="#" onClick={this.functionUnlocking}  >
                                <strong style={{ color: `${properties.grayLight}` }} >
                                  {labels.IFORGET_LB}
                                </strong>
                              </a>

                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <Button
                              size="small"
                              variant="outlined"
                              color="primary"
                              className={classes.button}
                              disabled={this.state.buttonLoginDisabled}
                              onClick={() => this.login()}
                            >
                              <strong className={classes.textButton}>
                                {labels.LOG_IN_BUTTOM}
                              </strong>
                            </Button>
                            <Button
                              size="small"
                              variant="outlined"
                              color="primary"
                              className={classes.button}
                              disabled={this.state.buttonLoginDisabled}
                              onClick={() => this.handleTab(1, "820px")}
                            >
                              <strong className={classes.textButton}>
                                {labels.REGISTER_BUTTOM}
                              </strong>
                            </Button>
                          </Grid>
                          <Grid item xs={12}>
                            <img src={logo} alt="" width={80} style={{marginLeft: 54}} />
                            {/* <i
                                class="icon-guss_logo"
                                alt=""
                                style={{
                                  position: "absolute",
                                  marginLeft: "5vh",
                                  marginTop: "0vh",
                                  fontSize: 20,
                                  color: `${properties.grayLight}`,
                                }}
                              /> */}
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container spacing={1} style={{ marginTop: -100 }}  >
                          <Grid item xs={12}>
                            <FormControl
                              style={{ marginTop: "5vh" }}
                              fullWidth
                            >
                              <InputLabel htmlFor="age-native-simple">
                                {<span style={{ color: 'white' }}> {labels.CUSTOMER_TYPE}</span>}
                              </InputLabel>
                              <Select
                                color="white"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.state.data}
                                onChange={this.handleChangeSelect}
                                className={classes.textColor}
                              >
                                <MenuItem value="Natural"> Natural </MenuItem>
                                <MenuItem value="juridico">Jurídico</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>

                          {this.state.data === "Natural" ? (
                            <>
                              <Grid item xs={12}>
                                <TextField
                                  label={<span style={{ color: 'white' }}> {labels.NAME_LB} </span>}
                                  name="name"
                                  value={this.state.name}
                                  id="textWithSpace"
                                  error={this.state.nameBool}
                                  helperText={this.state.nameBool && "Campo requerido"}
                                  onChange={(event) => {
                                    this.onChangeValues(event);
                                  }}
                                  className={classes.textColor}
                                  inputProps={{
                                    maxLength: 20,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  label={<span style={{ color: 'white' }}>{labels.LASTNAME_LB}</span>}
                                  name="surName"
                                  value={this.state.surName}
                                  id="textWithSpace"
                                  error={this.state.surNameBool}
                                  helperText={this.state.surNameBool && "Campo requerido"}
                                  className={classes.textColor}
                                  onChange={(event) => {
                                    this.onChangeValues(event);
                                  }}
                                  inputProps={{
                                    maxLength: 20,
                                  }}
                                />
                              </Grid>
                            </>
                          ) : (
                            <Grid item xs={12}>
                              <TextField
                                id="text"
                                name="razonSocial"
                                value={this.state.razonSocial}
                                error={this.state.razonSocialBool}
                                helperText={this.state.razonSocialBool && "Campo requerido"}
                                label={<span style={{ color: 'white' }}>{labels.BUSINESSNAME_LB}</span>}
                                type="alphanumeric"
                                onChange={(event) => { this.setState({ [event.target.name]: event.target.value, [event.target.name + 'Bool']: false }) }}
                                // onChange={(event) => {
                                //   this.onChangeValues(event);
                                // }}
                                className={classes.textColor}
                                inputProps={{
                                  maxLength: 40,
                                }}
                              />
                            </Grid>
                          )}

                          <Grid item xs={12}>
                            <TextField
                              id="email"
                              name="email"
                              value={this.state.email}
                              error={this.state.correoBool || this.state.emailBool}
                              helperText={(this.state.correoBool && "Formato de correo no válido, ej. ejemplo@dominio.com") || (this.state.emailBool && "Campo requerido")}
                              label={<span style={{ color: 'white' }}>{labels.USER_LB}</span>}
                              className={classes.textColor}
                              onChange={this.onChangeValues}
                              inputProps={{
                                maxLength: 40,
                              }}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              id="password"
                              name="password"
                              type="password"
                              value={this.state.password}
                              error={this.state.passwordBool || this.state.claveBool}
                              helperText={(this.state.passwordBool && this.state.passwordMessage) || (this.state.claveBool && "Campo requerido")}
                              label={<span style={{ color: 'white' }}>{labels.PASSWORD_LB}</span>}
                              className={classes.textColor}
                              onChange={this.onChangeValues}
                              inputProps={{
                                maxLength: 30,
                              }}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              name="confirmPassword"
                              value={this.state.confirmPassword}
                              type="password"
                              error={this.state.confirmacionBool || this.state.confirmPasswordBool}
                              helperText={(this.state.confirmacionBool && "No existe coincidencia.") || (this.state.confirmPasswordBool && "Campo requerido")}
                              label={<span style={{ color: 'white' }}>{labels.CONFIRMPASSWORD_LB}</span>}
                              className={classes.textColor}
                              onChange={this.handlePasswordConfirm}
                              inputProps={{
                                maxLength: 30,
                              }}
                            />
                          </Grid>

                          <ReCAPTCHA
                            ref={this.captcha}
                            sitekey={properties.RECAPTCHA_SITE_KEY}
                            onChange={this.onChange}
                            style={{ transform: "scale(0.6)", "-webkit-transform": "scale(0.6)", "transform-origin": "0 0", "-webkit-transform-origin": "0 0", marginTop: 40, }}
                          />
                          {this.state.captcha === false &&
                            <Typography variant="subtitle2" className={classes.captcha}>{"Debe validar que no es un robot"}</Typography>
                          }
                          <Grid item xs={12}>
                            <Button
                              size="small"
                              variant="outlined"
                              color="primary"
                              className={classes.buttonRegister}
                              onClick={this.integrationRegister}
                            >
                              <strong className={classes.textButton}>
                                {labels.REGISTER_BUTTOM}
                              </strong>
                            </Button>
                          </Grid>
                          <Grid item xs={12}>
                            <img src={logo} alt="" width={80} style={{ marginTop: 15, marginLeft: 54 }} />
                            {/* <i
                                class="icon-guss_logo"
                                alt=""
                                style={{
                                  position: "absolute",
                                  marginLeft: "5vh",
                                  marginTop: "0vh",
                                  fontSize: 20,
                                  color: `${properties.grayLight}`,
                                }}
                              /> */}
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </form>
                </div>
              </Grid>
            </Grid>
          </div>
        </Slide>
      </Grid>
    );
  }
}

const Login = withStyles(styles, { withTheme: true })(login);

function mapStateToProps(state) {
  return {
    loginPayload: state.loginReducer.loginPayload,
    buttomPayload: state.loginReducer.buttomPayload,
    alert: state.alertReducer.alert,
    keyRecovery: state.loginReducer.keyRecovery,
  };
}

export default connect(mapStateToProps, {
  openLogin,
  buttomForm,
  postLogin,
  setLoader,
  postUser,
  setAlert,
  setKeyRecovery,
  setUnlocking
})(Login);
