/* CONTANTES DE EJEMPLO */
export const SET_ALERT = "SET_ALERT";
export const RESET_ALL = "RESET_ALL";
export const OPEN_PRODUCTS = "OPEN_PRODUCTS";
export const MODAL_SEARCH = "MODAL_SEARCH";
export const SET_HEADER_PROPS = "SET_HEADER_PROPS";
export const DETAIL_PRODUCT = "DETAIL_PRODUCT";
export const SET_OFFERT = "SET_OFFERT";
export const OPEN_LOGIN = "OPEN_LOGIN";
export const SET_CONTACT = "SET_CONTACT";
export const SET_HOME = "SET_HOME";
export const DATA_APIHOME = "DATA_APIHOME";
export const DATA_APISEARCH = "DATA_APIARTICLE";
export const SET_LOADER = "SET_LOADER";
export const MENU_OPTION_SELECTED = "MENU_OPTION_SELECTED";
export const OPEN_US = "OPEN_US";
export const BUTTOM_FORM = "BUTTOM_FORM";
export const LOGIN_SUCCESSFUL = "LOGIN_SUCCESSFUL";
export const PREFERENCES = "PREFERENCES";
export const ARTICLES_PREFERENCES = "ARTICLES_PREFERENCES";
export const QUESTIONSANDANSWERS = "QUESTIONSANDANSWERS";
export const KEY_RECOVERY = "KEY_RECOVERY";
export const UNLOCKING = "UNLOCKING";
export const MODAL_CHANGEPASSWORD = "MODAL_CHANGEPASSWORD";
export const OPEN_PUCHASE = "OPEN_PUCHASE";
export const STEP_PURCHASE = "STEP_PURCHASE";
export const GET_STATES_LIST = "GET_STATES_LIST";
export const GET_CITY_LIST = "GET_CITY_LIST";
export const GET_MUNICIPALITIES_LIST = "GET_MUNICIPALITIES_LIST";
export const GET_PARISHES_LIST = "GET_PARISHES_LIST";
export const GET_SHIPPING_LIST = "GET_SHIPPING_LIST";
export const GET_BANKS_LIST = "GET_BANKS_LIST";
export const GET_PAYMETHOD_LIST = "GET_PAYMETHOD_LIST";
export const GET_DOCUMENTSID_LIST = "GET_DOCUMENTSID_LIST";
export const GET_CITY_LIST_BUYPROCESS = "GET_CITY_LIST_BUYPROCESS";
export const GET_STATES_LIST_BUYPROCESS = "GET_STATES_LIST_BUYPROCESS";
export const GET_MUNICIPALITIES_LIST_BUYPROCESS =
  "GET_MUNICIPALITIES_LIST_BUYPROCESS";
export const GET_PARISHES_LIST_BUYPROCESS = "GET_PARISHES_LIST_BUYPROCESS";
export const OPEN_ADD_CAR_MODAL = "OPEN_ADD_CAR_MODAL";
export const SET_COTIZAR = "SET_COTIZAR";
export const OPEN_SHOPPINGCAR = "OPEN_SHOPPINGCAR";
export const GET_ACCOUNT_LIST = "GET_ACCOUNT_LIST";
export const SET_CARRITO = "SET_CARRITO";
export const SET_MEGUSTA = "SET_MEGUSTA";
export const SET_GUARDADOS = "SET_GUARDADOS";
export const PREFERENCES_GUSSTA = "PREFERENCES_GUSSTA";
export const RESERVA = "RESERVA";
export const SOLICITUD = "SOLICITUD";
export const LOGIN_RENEW = "LOGIN_RENEW";
export const SUMATORIA = "SUMATORIA";
export const SET_PDF = "SET_PDF";
export const PEDIDO_BY_USER = "PEDIDO_BY_USER";
export const MODAL_PEDIDOS_DETAILS_PAYLOAD = "MODAL_PEDIDOS_DETAILS_PAYLOAD";
export const ORDER_SEARCH = "ORDER_SEARCH";
export const PERSON_REQUEST = "PERSON_REQUEST";
export const REQUEST_ADDRESS = "REQUEST_ADDRESS";
export const ARTICLE_ORDERS = "ARTICLE_ORDERS";
export const PAYMENT_ORDERS = "PAYMENT_ORDERS";
export const MONEDA = "MONEDA";
export const OPEN_GUSTA = "OPEN_GUSTA";
export const CHECK_DEVICE = "CHECK_DEVICE";
export const OPEN_SEARCH = "OPEN_SEARCH";
export const SEARCH_PRODUCTS_MOBILE = "SEARCH_PRODUCTS_MOBILE";
export const LOAD = "LOAD";
export const SEARCH_MOBILE = "SEARCH_MOBILE";
export const SEE_MORE_CATEGORIES = "SEE_MORE_CATEGORIES";
export const GET_CATEGORIES_LIST = "GET_CATEGORIES_LIST";
export const IMG_DETAIL_PRODUCT = "IMG_DETAIL_PRODUCT";
export const ACTIVE_ARRAY = "ACTIVE_ARRAY";
export const BREADCRUMBS = "BREADCRUMBS";