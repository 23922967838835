import { SET_CONTACT, OPEN_US } from '../constants';

export const setContactUs = (payload) => {
    return {
        type: SET_CONTACT,
        payload: payload,
    }
}

export const openUs = (payload) => {
    return{
        type: OPEN_US,
        payload: payload,
    }
}