import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, withStyles } from "@material-ui/core";
import Header from "../Header";
import JUMBOTRON from "./img/jumbotron.png";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ButtonBase from "@material-ui/core/ButtonBase";
import ListItem from "@material-ui/core/ListItem";
import ListIcon from "@material-ui/icons/List";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { properties } from "../../Constants";

/**CONTANTS */
import { styles } from "./constants";
import { LOGIN_DATA_LS, ENCRYP_TYPE, TOKEN } from "../../Constants";

/** ACTIONS */
import { setHeaderProps } from "../../Redux/Actions/_actionHeader";
import { getPreferences } from "../../Redux/Actions/services/getPreferences";
import { setLoader } from "../../Redux/Actions/_actionLoader";
import { modalChangePassword, articlesPreferences, pedidoByUser } from "../../Redux/Actions/_actionGussta";
import { setAlert } from "../../Redux/Actions/_actionAlerts";
import { getPreferencesGussta } from "../../Redux/Actions/services/getPreferencesGussta";
import { getPedidoByUser } from "../../Redux/Actions/services/getPedidoByUser";
import { menuOptionSelected } from "../../Redux/Actions/_actionProducts";
import { getOrderById } from "../../Redux/Actions/services/getOrderById";
import { getDetailProduct } from "../../Redux/Actions/services/getDetailProduct";

//TOOLS
import { DencryptData } from "../../Tools/CryptoLocalStorage";
import { closeSesion } from "../../Tools";

//COMPONENTS
import ChangePassword from "./ChangePassword/index";
import PedidosDetails from "./PedidosDetails";
import PurchaseProcess from "../ShoppingCar/PurchaseProcess";
import QuotationProcess from "../ShoppingCar/QuotationProcess";

const labels = JSON.parse(localStorage.getItem("labels"));

class Gussta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarStyleDynamic: "",
      showSide: null,
      loginDataStorage: "",
      iconOption: true,
      titulo: labels.LIKE_LB
    };
  }

  componentDidMount = () => {
    this.props.pedidoByUser(false, null);
    this.props.setHeaderProps("transparent", "black");
    let loginData = DencryptData(ENCRYP_TYPE.JSON, LOGIN_DATA_LS);

    if (localStorage.getItem("loginBool")) {
      this.setState({
        loginDataStorage: loginData,
      });
    }

    if (window.innerWidth >= 600) {
      this.setState(
        { showSide: true, mainStyleDynamic: "mainWithSidebar" },
        () => {
          this.displaySidebar(this.state.showSide);
        }
      );
    } else {
      this.setState(
        { showSide: true, mainStyleDynamic: "mainWithoutSidebar" },
        () => {
          this.displaySidebar(this.state.showSide);
        }
      );
    }

    let token = DencryptData(ENCRYP_TYPE.STRING, TOKEN);
    let headers = {
      idUsuario: loginData.usuario.idUsuario,
      idPreferencia: "DESC-205",
      idIdioma: "ES",
      Authorization: "Bearer " + token,
    };
    this.props.getPreferences(headers, token, true);
    this.props.setLoader(true);
  };

  handleClose = () => {
    this.props.setAlert(false);
    this.setState({
      open: false,
    });
  };

  displaySidebar = (option) => {
    if (option)
      this.setState({
        sidebarStyleDynamic: "sidebarHideToXS",
        showSide: option,
        mainStyleDynamic: "mainWithSidebar",
      });
    else
      this.setState({
        sidebarStyleDynamic: "sidebarShowToXS",
        showSide: option,
        mainStyleDynamic: "mainWithoutSidebar",
      });
  };

  handleMenu = (idOption) => {
    this.props.menuOptionSelected(idOption);
    let token = DencryptData(ENCRYP_TYPE.STRING, TOKEN);
    switch (idOption) {
      case "MEN-015":
        this.setState({ titulo: 'Me gusta'})
        this.props.setLoader(true);
        this.setState({ iconOption: true });
        let headersMegusta = {
          idUsuario: this.state.loginDataStorage.usuario.idUsuario,
          idPreferencia: "DESC-205",
          idIdioma: "ES",
          Authorization: "Bearer " + token,
        };
        this.props.pedidoByUser(false, null);
        this.props.getPreferences(headersMegusta, token);
        break;
      case "MEN-016":
        this.setState({ titulo: 'Guardados'})
        this.props.setLoader(true);
        this.setState({ iconOption: false });
        let headersGuardados = {
          idUsuario: this.state.loginDataStorage.usuario.idUsuario,
          idPreferencia: "DESC-206",
          idIdioma: "ES",
          Authorization: "Bearer " + token,
        };
        this.props.pedidoByUser(false, null);
        this.props.getPreferences(headersGuardados, token);
        break;
      case "MEN-002":
        this.props.modalChangePassword(true);
        break;

      case "MEN-017":
        closeSesion();
        break;

      case "MEN-028":
        //   Cotizacion
        this.setState({ titulo: 'Pedidos de Tipo Cotización'})
        this.props.setLoader(true);
        let headersCotizacion = {
          idUsuario: this.state.loginDataStorage.usuario.idUsuario,
          idTipoPedido: properties.cotizacion,
          Authorization: "Bearer " + token,
        };
        this.props.articlesPreferences(false, null);
        this.props.getPedidoByUser(headersCotizacion);
        break;

      case "MEN-029":
        //   Compra
        this.setState({ titulo: 'Pedidos de Tipo Compra'})
        this.props.setLoader(true);
        let headersCompra = {
          idUsuario: this.state.loginDataStorage.usuario.idUsuario,
          idTipoPedido: properties.compra,
          Authorization: "Bearer " + token,
        };
        this.props.articlesPreferences(false, null);
        this.props.getPedidoByUser(headersCompra);
        break;

      case "MEN-030":
        //   Solicitud
        this.setState({ titulo: 'Pedidos de Tipo Solicitud'})
        this.props.setLoader(true);
        let headersSolicitud = {
          idUsuario: this.state.loginDataStorage.usuario.idUsuario,
          idTipoPedido: properties.solicitud,
          Authorization: "Bearer " + token,
        };
        this.props.articlesPreferences(false, null);
        this.props.getPedidoByUser(headersSolicitud);
        break;

      default:
        break;
    }
    this.displaySidebar(!this.state.showSide);
  };

  processPedido = (tipoPedido, idEstatus, idPedido) => {
    let token = DencryptData(ENCRYP_TYPE.STRING, TOKEN);
    switch (tipoPedido) {
      case "TP-001": //Pedidos de compra
        this.props.getOrderById({
          idPedido: idPedido,
          Authorization: "Bearer " + token,
        }, idEstatus);
        break;
      case "TP-002": //Pedidos de solicitud
        //solicitud completada
        this.props.getOrderById({
          idPedido: idPedido,
          Authorization: "Bearer " + token,
        }, idEstatus);
        break;
      case "TP-003": //Pedidos de cotización
        this.props.getOrderById({
          idPedido: idPedido,
          Authorization: "Bearer " + token,
        }, idEstatus);
        break;

      default:
        break;
    }
  };

  showDetailProduct = (idProduct) => {
    this.props.setLoader(true);
    this.props.getDetailProduct(idProduct);
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        <Header />
        <Grid container direction="row">
          <Grid item xs={12}>
            <img
              src={JUMBOTRON}
              alt="First slide"
              className={classes.jumbotron}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            <div className={classes.paperJumbo}>
              <Paper elevation={3}>
                <Grid container direction="row">
                  <Grid container sm={4} md={3} lg={3} xl={3}>
                    <span className={classes.personJumbo} id="something">
                      <i class="demo-icon icon-guss_user"></i>
                    </span>
                  </Grid>
                  <Grid container xs={12} sm={8} md={9} lg={9} xl={9}>
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="baseline"
                      className={classes.typographyJumbo}
                    >
                      <Grid item>
                        <Typography variant="h4" component="h3">
                          {this.state.loginDataStorage &&
                            this.state.loginDataStorage.usuario.nombre}
                          &#10240;
                          {this.state.loginDataStorage &&
                            this.state.loginDataStorage.usuario.apellido}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography gutterBottom>
                          {this.state.loginDataStorage &&
                            this.state.loginDataStorage.usuario.emailPrincipal}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justify="space-around"
                      alignItems="center"
                      className={classes.typographyJumbo}
                    >
                      <Grid item className={classes.iconsData}>
                        <div className={classes.favoriteIconOutline}>
                          <span>
                            <i class="demo-icon icon-guss_like"></i>
                          </span>
                        </div>
                        <Typography gutterBottom className={classes.cantText}>
                          {this.props.preferencesGussta &&
                            this.props.preferencesGussta.cantidadMegusta}
                        </Typography>
                      </Grid>
                      <Divider
                        orientation="vertical"
                        flexItem
                        className={classes.divider}
                      />
                      <Grid item className={classes.iconsData}>
                        <div className={classes.saveIcon}>
                          <span>
                            <i class="demo-icon icon-guss_save"></i>
                          </span>
                        </div>
                        <Typography gutterBottom className={classes.cantText}>
                          {this.props.preferencesGussta &&
                            this.props.preferencesGussta.cantidadGuardados}
                        </Typography>
                      </Grid>
                      <Divider
                        orientation="vertical"
                        flexItem
                        className={classes.divider}
                      />
                      <Grid item className={classes.iconsData}>
                        <div className={classes.shoppingCarIcon}>
                          <span>
                            <i class="demo-icon icon-guss_shop"></i>
                          </span>
                        </div>
                        <Typography gutterBottom className={classes.cantText}>
                          {this.props.preferencesGussta &&
                            this.props.preferencesGussta.candidadCarrito}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </div>
          </Grid>

          {/* SIDEBAR */}
          <Grid item xs={6} sm={3} md={3} lg={3} xl={3} className={classes[this.state.sidebarStyleDynamic]}>
            {this.state.loginDataStorage &&
              this.state.loginDataStorage.menu.map((element, i) => (
                <>
                  <Grid
                    container
                    direction="row"
                    xs={12}
                    justify="space-between"
                  >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      {element.idMenu === this.props.menuOptionProducts ? (
                        <ListItem
                          selected={true}
                          button
                          onClick={() => this.handleMenu(element.idMenu)}
                        >
                          <Typography className={classes.sideOptions}>
                            <strong>{element.nombreMenu}</strong>
                          </Typography>
                        </ListItem>
                      ) : (
                        <ListItem
                          button
                          onClick={() => this.handleMenu(element.idMenu)}
                        >
                          <Typography className={classes.sideOptions}>
                            <strong>{element.nombreMenu}</strong>
                          </Typography>
                        </ListItem>
                      )}
                    </Grid>
                    {i === 0 && (
                      <ListIcon
                        className={classes.menuIconClose}
                        onClick={() =>
                          this.displaySidebar(!this.state.showSide)
                        }
                      />
                    )}
                  </Grid>
                </>
              ))}
            <ListItem
              button
              onClick={() => this.handleMenu("MEN-017")}
            >
              <Typography className={classes.sideOptions}>
                <strong>Cerrar Sesión</strong>
              </Typography>
            </ListItem>
          </Grid>
          {/* SIDEBAR */}

          {/* MAIN */}
          <Grid item xs={12} sm={9} md={9} lg={9} xl={9} className={classes[this.state.mainStyleDynamic]}>
            <Grid container direction="row" xs={12} alignItems="stretch">
              <Grid container>
                <Grid item xs={11} sm={11}>
                  <Typography variant="h6" component="h3" className={classes.title}>
                    <strong>{this.state.titulo}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1} xl={1} className={classes.menuIcon}>
                  <ListIcon onClick={() => this.displaySidebar(!this.state.showSide)}>
                    {" "}
                  </ListIcon>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.scroll}>
                <div className={classes.mainData}>
                  {/* MAIN CARDS */}
                  <Grid container direction="row" spaing={6}>
                    {this.props.articlesPreferencesPaylod && (
                      <>
                        {this.props.articlesPreferencesData &&
                          this.props.articlesPreferencesData.articulos.map(
                            (element) => (
                              <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                                {!this.props.isMobile ?
                                  <div className={classes.root}>
                                    <Paper
                                      className={classes.cardsMain}
                                      style={{ background: "#f2f2f2", cursor: 'pointer' }}
                                      onClick={() => this.showDetailProduct(element.idArticulo)}
                                    >
                                      <Grid container>
                                        <Grid item xs={6} style={{ background: "white" }}>
                                          <ButtonBase className={classes.image}>
                                            <img
                                              className={classes.img}
                                              alt="complex"
                                              src={element.url}
                                            />
                                          </ButtonBase>
                                        </Grid>
                                        <Grid item xs={6} sm container>
                                          <Grid
                                            item
                                            xs
                                            container
                                            direction="column"
                                            spacing={2}
                                            justify="space-evenly"
                                            alignItems="flex-end"
                                          >
                                            <Grid item style={{ width: '100%' }}>
                                              <Typography
                                                gutterBottom
                                                variant="subtitle1"
                                                style={{
                                                  color: "#8cc320", display: '-webkit-box',
                                                  overflow: 'hidden', '&-webkit-line-clamp': 3,
                                                  '&-webkit-box-orient': 'vertical', height: '83px', padding: 5
                                                }}
                                              >
                                                <strong>
                                                  {element.descripcionCorta}
                                                </strong>
                                              </Typography>
                                              <Typography variant="subtitle1" style={{ color: "#000000", padding: 5 }}>
                                                {"$." + element.precio}
                                              </Typography>
                                            </Grid>
                                            <Grid item>
                                              {this.props
                                                .articlesPreferencesData &&
                                                this.state.iconOption ? (
                                                <Typography className={classes.favoriteIcon}>
                                                  <i class="demo-icon icon-guss_likeit"></i>
                                                </Typography>
                                              ) : (
                                                <Typography className={classes.saveIconCard}>
                                                  <span>
                                                    <i class="demo-icon icon-guss_save"></i>
                                                  </span>
                                                </Typography>
                                              )}
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Paper>
                                  </div>
                                  :
                                  <Card>
                                    <CardActionArea onClick={() => this.showDetailProduct(element.idArticulo)}>
                                      <LazyLoadImage
                                        className={classes.imagen}
                                        effect="blur"
                                        visibleByDefault={true}
                                        src={element.url}
                                        alt={element.descripcionCorta}
                                      />
                                      <CardContent>
                                        <Typography variant="caption" style={{ color: '#bdbbbb' }}>
                                          {element.categoria}
                                        </Typography>
                                        <Typography variant="subtitle" component="h6">
                                          {element.descripcionCorta}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                          {element.precio}$
                                        </Typography>
                                      </CardContent>
                                    </CardActionArea>
                                  </Card>
                                }
                              </Grid>
                            )
                          )}
                      </>
                    )}

                    {this.props.pedidoByUserPaylod && (
                      <>
                        {this.props.pedidoByUserData &&
                          this.props.pedidoByUserData.map((element) => (
                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                              <div className={classes.root}>
                                <Paper
                                  className={classes.cardsMain}
                                  style={{
                                    background: "#f2f2f2",
                                    cursor: "pointer"
                                  }}
                                  elevation={4}
                                  onClick={() =>
                                    this.processPedido(
                                      element.idTipoPedido,
                                      element.idEstatus,
                                      element.idPedido
                                    )
                                  }
                                >
                                  <Grid item xs={12} sm container>
                                    <Grid
                                      item
                                      xs={12}
                                      container
                                      direction="column"
                                      spacing={2}
                                      justify="space-evenly"
                                    >
                                      <Grid item style={{ padding: 20 }}>
                                        <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                                          <strong>
                                            {element.idTipoIdExterno + "-" + element.documento}
                                          </strong>
                                        </Typography>
                                        <Typography variant="subtitle1" style={{ color: "#000000" }}>
                                          {"Pedido: " + element.idPedido}
                                        </Typography>
                                        <Typography variant="subtitle1" style={{ color: "#000000" }}>
                                          {"Fecha de creación: " +
                                            element.fechaCrea}
                                        </Typography>
                                        <Typography variant="subtitle1" style={{ color: "#000000" }}>
                                          Estatus:
                                          {element.idEstatus === "3" ? (
                                            <span style={{ color: "red" }}>
                                              Cancelado
                                            </span>
                                          ) : element.idEstatus === "2" ? (
                                            <span style={{ color: "#B7BD0C" }}>
                                              Por validar
                                            </span>
                                          ) : (
                                            <span style={{ color: "green" }}>
                                              Completado
                                            </span>
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Paper>
                              </div>
                            </Grid>
                          ))}
                      </>
                    )}
                  </Grid>
                  {/* MAIN CARDS */}
                </div>
              </Grid>
            </Grid>
          </Grid>
          {/* MAIN */}
        </Grid>

        {this.props.showModalChangePass && <ChangePassword />}
        {this.props.showModalPedidosDetails && <PedidosDetails />}
        {this.props.purchaseOpen && <PurchaseProcess />}
        {this.props.quotationModal && <QuotationProcess />}
      </>
    );
  }
}

const gussta = withStyles(styles, { withTheme: true })(Gussta);

function mapStateToProps(state) {
  return {
    loginData: state.loginReducer.loginData,
    articlesPreferencesData: state.gusstaReducer.articlesPreferencesData,
    loader: state.loaderReducer.loader,
    showModalChangePass: state.gusstaReducer.showModalChangePass,
    alert: state.alertReducer.alert,
    preferencesGussta: state.gusstaReducer.preferencesGussta,
    articlesPreferencesPaylod: state.gusstaReducer.articlesPreferencesPaylod,
    pedidoByUserData: state.gusstaReducer.pedidoByUserData,
    pedidoByUserPaylod: state.gusstaReducer.pedidoByUserPaylod,
    menuOptionProducts: state.products.menuOptionProducts,
    showModalPedidosDetails: state.gusstaReducer.showModalPedidosDetails,
    purchaseOpen: state.purchase.purchaseOpen,
    quotationModal: state.purchase.quotationModal,
    isMobile: state.homeProps.isMobile
  };
}

export default connect(mapStateToProps, {
  setHeaderProps,
  getPreferences,
  setLoader,
  modalChangePassword,
  setAlert,
  getPreferencesGussta,
  getPedidoByUser,
  articlesPreferences,
  pedidoByUser,
  menuOptionSelected,
  getOrderById,
  getDetailProduct,
})(gussta);
