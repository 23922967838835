import { makeStyles } from "@material-ui/core";
import { properties } from "../../../../Constants";

export const useStyles = makeStyles((theme) => ({
    root: {
        '& > .MuiDialog-container > .MuiDialog-paperFullScreen  ': {
            top: '45px',
            '& header': {
                '& > div': {
                    minHeight: theme.spacing(6),
                    height: theme.spacing(6),
                    background: properties.secondary,
                    color: properties.white,
                    padding: 0,
                    paddingLeft: '16px',
                    paddingRight: '5px',
                }
            }
        }
    },
    noHayBusqueda: {
        textAlign: 'center',
        padding: 20,
        color: properties.primary
    },
    loader: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '20px'
    },
    precio: {
        color: properties.secondary,
        fontWeight: '800',
        fontSize: '1.03rem'
    },
    precioOferta: {
        color: properties.darkgray,
        paddingLeft: 5, 
        textDecoration: 'line-through',
        fontWeight: '700',
    },
    flotante:{
        textAlign: 'end'
    }
}))