import { makeStyles } from '@material-ui/core/styles';
import { properties } from '../../../Constants';


export const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        borderRadius: 0
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        marginRight: 15,
        padding:0
    },
    divider: {
        height: 28,
        margin: 4,
    },
    marginTop: {
        marginTop: 25,
    },
    select: {
        backgroundColor: properties.primary,
        padding: 5,
        width: 180,
        color: "white",
        textAlign: "center"
    },
    selectMobile:{        
        backgroundColor: properties.primary,
        padding: 10,
        width: '100%',
        color: "white",
        textAlign: "center"
    },
    rootMobile:{
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        borderRadius: 0,    
        marginTop: 0,
        height: 52    
    },

    paddingFooter: {
        paddingLeft: 20
    },

    [theme.breakpoints.up('md')]: {
        hiddenDesktop: {
            display: 'none'
        },
    },
    [theme.breakpoints.up('lg')]: {
        hiddenDesktop: {
            display: 'none'
        },
    },
    [theme.breakpoints.up('sm')]: {
        hiddenDesktop: {
            display: 'none'
        },
    },

    [theme.breakpoints.down('xs')]: {
        hiddenMobile: {
            display: 'none'
        },
    },

}));