import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dialog, Slide, Grid, IconButton } from '@material-ui/core';


/*Actions*/
import { setOffertProps } from '../../Redux/Actions/_actionHome';
import { getQuestionsAndAnswers } from '../../Redux/Actions/services/getQuestionsAndAnswers';
import { setLoader } from '../../Redux/Actions/_actionLoader';


function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class Offert extends Component {

    handleClose = () => {
        this.props.setOffertProps(false);
    }

    searchArticleOffert = (value) => {
        this.props.setLoader(true);
        this.props.setOffertProps(false);
        this.props.getQuestionsAndAnswers(value);
    }

    componentDidMount = () => {
    }

    render() {
        return (
            <>
                {this.props.getHomeData.promo !== null &&
                    < Dialog
                        open={this.props.getOffert}
                        onClose={this.handleClose}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-slide-offert"
                        aria-describedby="alert-dialog-slide-offert"

                    >
                        {this.props.getHomeData.promo &&
                            <Grid container onClick={() => this.searchArticleOffert(this.props.getHomeData.promo.urlTop)} style={{ cursor: 'pointer' }}>
                                <img alt="close" src={this.props.getHomeData.promo.imagen} style={{ backgroundSize: "cover" }} />
                            </Grid>
                        }
                        <IconButton size="small" aria-label="close" component="span" style={{ color: "white", position: "absolute", right: 0, padding: 10 }}>
                            <i className="icon-guss_close" onClick={this.handleClose} ></i>
                        </IconButton>
                    </Dialog>
                }
            </>
        );
    }
}


function mapStateToProps(state) {
    return {
        getOffert: state.homeProps.getOffert,
        getHomeData: state.homeProps.getHomeData,
    };
}

export default connect(
    mapStateToProps, {
    setOffertProps,
    getQuestionsAndAnswers,
    setLoader,
})(Offert);