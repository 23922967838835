import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, withStyles, Typography, Tooltip, IconButton } from "@material-ui/core";
import Box from '@material-ui/core/Box';

//Componentes
import { styles } from "./constants.js";
import { properties } from '../../../Constants';
import { toMoneyFormat } from '../../../Tools';

/*Actions */
import { setLoader } from '../../../Redux/Actions/_actionLoader';
import { DencryptData } from '../../../Tools/CryptoLocalStorage';
import { postActualizarPreferencias } from '../../../Redux/Actions/services/postActualizarPreferencias';
import { setMeGusta } from '../../../Redux/Actions/_actionShoppingCar';

/*Services */
import { getQuestionsAndAnswers } from '../../../Redux/Actions/services/getQuestionsAndAnswers';

//Constantes
import { LOGIN_DATA_LS, ENCRYP_TYPE } from '../../../Constants';

const labels = JSON.parse(localStorage.getItem("labels"));

class ArticulosGuardados extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleImage = (IdAticulo) => {
        this.props.setLoader(true);
        this.props.getQuestionsAndAnswers(IdAticulo);
    }

    icons = (idArticulo, tipo, preferencia) => {
        const loginDataStorage = DencryptData(ENCRYP_TYPE.JSON, LOGIN_DATA_LS);
        const { usuario } = loginDataStorage
        var idDescrip = preferencia;
        var typeAction = tipo;
        const carritoIsOpen = this.props.openShoppingCar;

        if (preferencia === properties.meGusta) {
            tipo !== 1 ? typeAction = 1 : typeAction = 0;
        }

        this.props.setLoader(true);
        this.props.setMeGusta(undefined)
        this.props.postActualizarPreferencias({
            "idArticulo": idArticulo,
            "idUsuario": usuario.idUsuario,
            "idDescr": idDescrip,
            "tipoAccion": typeAction,
            "usuario": usuario.displayName
        }, carritoIsOpen);
    }

    render() {
        const { classes } = this.props;

        return (
            <>
                <Grid container className={classes.principal}>
                    <Grid xs={12}>
                        {this.props.guardadosList && this.props.guardadosList.map((row, i) => (
                            <Grid key={i} container className={classes.contenedor}>
                                <Grid xs={4} className={classes.imagen} onClick={() => { this.handleImage(row.idArticulo) }}>
                                    <img src={row.url} alt="" width="80" />
                                </Grid>
                                <Grid xs={8} className={classes.texto}>
                                    <Grid container>
                                        <Grid xs={12} className={classes.xs}>
                                            <Typography variant="subtitle1" className={classes.tipografia}>
                                                <Box fontWeight={100}>
                                                    {row.descripcionCorta}
                                                </Box>
                                            </Typography>
                                        </Grid>
                                        <Grid xs={12} className={classes.xs}>
                                            <Typography variant="subtitle2" className={classes.tipografia}>
                                                <Box fontWeight={800}>
                                                    {toMoneyFormat((row.precio).toFixed(2))}$
                                                </Box>
                                            </Typography>
                                        </Grid>
                                        <Grid xs={12}>
                                            <Grid container>
                                                <Grid item xs={3} sm={2}>
                                                    <Tooltip title={row.idStatus ? '' : labels.LIKE_PR} placement="top">
                                                        <IconButton position="static" onClick={() => this.icons(row.idArticulo, row.idStatus, properties.meGusta)}>
                                                            <i style={{ fontSize: 15, color: row.idStatus ? properties.red : properties.black }} class="icon-guss_like"></i>
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={3} sm={2}>
                                                    <IconButton position="static" onClick={() => { this.handleImage(row.idArticulo) }}>
                                                        <i style={{ fontSize: 15, color: properties.secondary }} class="icon-guss_shop"></i>
                                                    </IconButton>
                                                </Grid>
                                                <Grid item xs={3} sm={2}>
                                                    <Tooltip title={labels.SAVED} placement="top">
                                                        <IconButton position="static" onClick={() => this.icons(row.idArticulo, 0, properties.guardado)}>
                                                            <i style={{ fontSize: 15, color: properties.blue }} class="icon-guss_save"></i>
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </>
        );
    }
}

const articulosGuardados = withStyles(styles, { withTheme: true })(ArticulosGuardados);

function mapStateToProps(state) {
    return {
        guardadosList: state.purchase.guardadosList,
        openShoppingCar: state.purchase.openShoppingCar
    };
}

export default connect(mapStateToProps, {
    getQuestionsAndAnswers,
    setLoader,
    postActualizarPreferencias,
    setMeGusta
})(articulosGuardados);