import { properties } from "../../../Constants";
import fondo from "../../Home/img/fondoQuote.png";


export const styles = (theme) => ({

    logo: {
        [theme.breakpoints.up('md')]: {
            objectFit: "cover",
            width: '90%',
        },
        [theme.breakpoints.down("sm")]: {
            objectFit: "cover",
            width: '90%',
        },
        [theme.breakpoints.down('xs')]: {
            objectFit: "cover",
            width: '90%',
        },

    },
    bloque1: {
        [theme.breakpoints.up('md')]: {
            fontSize: 13

        },
        [theme.breakpoints.down("sm")]: {
            fontSize: 13
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 11
        },
    },
    bloque2: {
        [theme.breakpoints.up('md')]: {
            fontSize: 33

        },
        [theme.breakpoints.down("sm")]: {
            fontSize: 33
        },
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
            fontSize: 28
        },

    },
    bloque3: {
        fontSize: 11,
        [theme.breakpoints.down("sm")]: {
            fontSize: 12
        },
    },
    bloque4: {
        [theme.breakpoints.up('md')]: {
            fontSize: 10
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: 11
        },
    },
    celdas: {
        fontSize: 13,
    },
    closeModal: {
        cursor: "pointer",
    },
    iconClose: {
        [theme.breakpoints.up("md")]: {
            position: "fixed",
            marginLeft: 790,
            marginTop: -20,
        },
        [theme.breakpoints.down("sm")]: {
            position: "fixed",
            marginTop: -20,
            marginLeft: 650,
        },
        [theme.breakpoints.down("xs")]: {
            position: "fixed",
            marginLeft: "74%",
            marginTop: -20,
        },
    },
    fondo: {
        width: '820px',
        objectFit: "cover",
        backgroundImage: `url(${fondo})`,
        [theme.breakpoints.down("sm")]: {
            width: '100%',
        },
    },
    pie: {
        objectFit: "cover",
        width: '100%',
        height: '15vh',
        display: 'inline-Block',
        [theme.breakpoints.down('sm')]: {
            objectFit: "fill",
        },
    },
    cajaPie: {
        width: "100%",
        position: 'relative',

    },
    text: {
        position: 'absolute',
        top: '0vh',
        left: '65%',
        color: properties.white,
        fontSize: 10,
        textAlign: 'right',
        marginTop: 20,

        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            top: 0,
            left: '50%',
            color: properties.white,
            fontSize: 9,
            textAlign: 'right',
        },
    },
    table: {
        background: properties.gray,
        borderRadius: "0",
    }

})